import React from "react";
import Layout from "../../components/layout/Layout";
import TabComponent from "../../components/tab/TabComponent";
import SubmissionTab from "../submission/SubmissionTab";
import ListOfTopics from "../submission/ListOfTopics";
import { useSelector } from "react-redux";
import { translation, submission_page } from "../../helpers/translation";

const ListSubmission = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Layout>
      <TabComponent
        labels={[
          translation(submission_page, languages, "submission"),
          translation(submission_page, languages, "list_topic"),
        ]}
        tabs={[<SubmissionTab />, <ListOfTopics />]}
      />
    </Layout>
  );
};

export default ListSubmission;
