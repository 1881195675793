import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { Box } from "@chakra-ui/react";
import { getAssetURL } from "../../helpers/getAssetUrl";

const CarouselComponent = ({ Data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTablet, setIsTablet] = React.useState(false);
  // const Data = [
  //   {
  //     id: 1,
  //     image: "https://picsum.photos/200/100",
  //   },
  //   {
  //     id: 2,
  //     image: "https://picsum.photos/210/130",
  //   },
  //   {
  //     id: 3,
  //     image: "https://picsum.photos/190/290",
  //   },
  //   {
  //     id: 4,
  //     image: "https://picsum.photos/220/300",
  //   },
  //   {
  //     id: 5,
  //     image: "https://picsum.photos/170/210",
  //   },
  // ]

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setIsTablet(false);
    } else if (window.innerWidth < 1024) {
      setIsTablet(true);
      setIsMobile(false);
    } else {
      setIsMobile(false);
      setIsTablet(false);
    }
  };

  React.useEffect(() => {
    handleResize();
    document.addEventListener("scroll", () => {
      handleResize();
    });

    document.querySelectorAll(".swiper-wrapper").forEach((slide) => {
      slide.classList.add("align-items-center");
    });

    return () => {
      document.removeEventListener("scroll", () => {
        handleResize();
      });
    };
  }, []);

  return (
    <Box style={style.box}>
      <Swiper
        style={style.swiper}
        slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
        spaceBetween={0}
        navigation={true}
        loop={true}
        autoplay={{ delay: 5000 }}
        modules={[Navigation, Autoplay]}
        className="mySwiper">
        {Data?.map((item) => (
          <SwiperSlide
            key={item.id}
            className="align-items-center p-1 mb-2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <a href={item?.link_website_brand} target="_blank">
              <img
                src={getAssetURL(item?.gambar_brand?.id)}
                alt="carousel"
                style={{
                  // width: "100%",
                  maxHeight: "200px",
                  display: "block",
                }}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default CarouselComponent;

const style = {
  box: {},
  swiper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    width: "80%",
    height: "100%",
  },
};
