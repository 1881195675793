import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const getMember = createAsyncThunk(
    "organization/getMember",
    async () => {
        const res = await directus.items("org_committee").readByQuery({
            fields: [
                "*",
                "member.org_member_id.nama_lengkap",
                "member.org_member_id.jabatan.org_role_id.name",
                "member.org_member_id.jabatan.org_role_id.nama",
            ],
        });
        return res.data;
    }
)