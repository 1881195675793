import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

const ModalCancel = (props) => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        height: "100vh",
      }}>
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          height: "80vh",
          fontFamily: "Plus Jakarta Sans",
          padding: "0 10%",
        }}>
        <svg
          width="205"
          height="204"
          viewBox="0 0 205 204"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M153.389 152.669C181.495 124.563 181.495 78.9953 153.389 50.8897C125.284 22.7842 79.7158 22.7842 51.6102 50.8898C23.5047 78.9953 23.5047 124.563 51.6102 152.669C79.7158 180.774 125.284 180.774 153.389 152.669Z"
            fill="#FDA81A"
            fill-opacity="0.1"
          />
          <g clip-path="url(#clip0_5840_30073)">
            <path
              d="M98.9453 98.6665C98.9453 95.4176 100.451 92.3018 103.131 90.0044C105.811 87.7071 109.447 86.4165 113.237 86.4165H117.32C121.111 86.4165 124.746 87.7071 127.426 90.0044C130.106 92.3018 131.612 95.4176 131.612 98.6665C131.762 101.318 131.047 103.946 129.574 106.155C128.101 108.365 125.95 110.036 123.445 110.917C120.94 112.091 118.789 114.319 117.316 117.265C115.843 120.211 115.128 123.715 115.279 127.25"
              stroke="#FDA81A"
              stroke-width="2.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M115.279 143.583V143.625"
              stroke="#FDA81A"
              stroke-width="2.75"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <circle
            cx="115.279"
            cy="115"
            r="67.5"
            stroke="#FDA81A"
            stroke-width="3"
          />
          <defs>
            <clipPath id="clip0_5840_30073">
              <rect
                width="98"
                height="98"
                fill="white"
                transform="translate(66.2793 66)"
              />
            </clipPath>
          </defs>
        </svg>
        <h4
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            fontWeight: "700",
          }}>
          {languages === "eng"
            ? "Are you sure want to cancel the registration?"
            : "Apakah kamu yakin ingin membatalkan pendaftaran?"}
        </h4>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "#575757",
          }}>
          {languages === "eng"
            ? "All data you already filled will not be saved."
            : "Semua data yang anda isi tidak akan tersimpan."}
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}>
          <Button
            onClick={props.onYes}
            className="orange-button"
            style={{
              backgroundColor: "#e8e8e8",
              borderColor: "#e8e8e8",
              margin: "1rem 0",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#858585",
              fontSize: "1.3rem",
              height: "4rem",
              width: "100%",
              padding: "0 3rem",
            }}>
            {languages === "eng" ? "Yes I'm Sure" : "Ya,saya yakin"}
          </Button>
          <Button
            onClick={props.onNo}
            className="orange-button text-nowrap"
            style={{
              backgroundColor: "#FDA81A",
              borderColor: "#FDA81A",
              margin: "1.5rem 0",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "#1f1f1f",
              fontSize: "1.3rem",
              height: "4rem",
              width: "100%",
              padding: "0 2rem",
            }}>
            {languages === "eng" ? "No I'm not Sure" : "Tidak,saya tidak yakin"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalCancel;
