import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  fetchIndonesianCurrency,
  getAssetURL,
} from "../../reducers/information/indonesianCurrency_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const IndonesianCurrency = () => {
  const { indonesianCurrency } = useSelector(
    (state) => state.indonesianCurrency
  );
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchIndonesianCurrency());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? indonesianCurrency.judul_indonesia
          : indonesianCurrency.judul_english}
      </Typography>
      {indonesianCurrency?.gambar?.id && (
        <img
          src={getAssetURL(indonesianCurrency?.gambar?.id)}
          alt="weather"
          style={{ borderRadius: "20px", margin: "2rem auto" }}
        />
      )}
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {languages === "ind"
          ? parse("<p>" + indonesianCurrency.isi_deskripsi_indonesia + "</p>")
          : parse("<p>" + indonesianCurrency.isi_deskripsi_english + "</p>")}
      </Typography>
      <div
        style={{
          margin: "0 auto",
          border: "1px",
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
        }}
      >
        <iframe
          title="Foreign Exchange"
          src="https://www.foreignexchange.org.uk/widget/FE-FERT2-2.php?ws=https://www.foreignexchange.org.uk/widgets/foreign-exchange-rates-table-widget.html&amp;os=-7&amp;bc=Base Currency&amp;lc=1280D0&amp;mc=IDR&amp;c1=USD&amp;c2=EUR&amp;c3=AUD&amp;c4=JPY&amp;c5=INR&amp;c6=CAD&amp;c7=ZAR&amp;c8=NZD&amp;c9=SGD&amp;c10=CNY&amp;t=Foreign Exchange Today&amp;tc=1280D0&amp;fc=000000&amp;w=300&amp;bdrc=CBCBCB&amp;tz=7&amp;userhr=23"
          width="300px"
          height="400px"
          frameborder="0"
          scrolling="no"
        ></iframe>
      </div>
    </>
  );
};

export default IndonesianCurrency;
