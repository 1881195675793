import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const fetchScientificForum = createAsyncThunk(
    "program/fetchScientificForum",
    async () => {
        const res = await directus.items("scientific_forum").readByQuery({
            fields: ["*.*"],
        });
        return res.data;
    }
)