import React, { useEffect, useState } from "react";
import { Typography, Container, Card, IconButton } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { FaLongArrowAltLeft } from "react-icons/fa";
import Layout from "../../components/layout/Layout";
import List from "./ListWorldBank";

const WorldBank = () => {
  const [isMobile, setIsMobile] = useState(false);

  const button = {
    border: "none",
    borderRadius: "100%",
    background: "#EDEDED",
    width: "3rem",
    height: "3rem",
  };
  const card = {
    width: "21rem",
    background: "#F8F8F8",
    textAlign: "center",
    borderRadius: "16px",
    padding: "1rem",
    margin: isMobile ? "0.5rem" : "1rem",
    display: "inline-block",
    boxShadow: "none",
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  return (
    <Layout>
      <Row style={{ marginTop: isMobile ? "3rem" : "5rem" }}>
        <Col
          xs={1}
          sm={1}
          style={{
            textAlign: isMobile ? "start" : "end",
            marginBottom: isMobile ? "2rem" : 0,
            marginLeft: isMobile ? "1rem" : 0,
          }}
        >
          <a href="/registration">
            <IconButton sx={button}>
              {/* <FaLongArrowAltLeft sx={{ color: 'grey', textAlign: 'center' }} /> */}
              <img src={require("../../assets/images/arrow back.png")} />
            </IconButton>
          </a>
        </Col>
        <Col xs={11} sm={11}>
          <Container sx={{ mb: "2rem" }}>
            <Typography
              variant="h4"
              sx={{
                color: "#FDA81A",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                display: "inline",
                alignItems: "center",
              }}
            >
              World Bank List of Economies
            </Typography>
          </Container>
          <Container sx={{ mb: 12 }}>
            {List.map((item, index) => (
              <Card style={card} key={index}>
                <img
                  src={require(`../../assets/world-bank/${item.image}.png`)}
                  alt={item.name}
                  style={{ margin: "0 auto", width: "75px", height: "75px" }}
                />
                <Typography
                  variant="body"
                  sx={{
                    display: "block",
                    fontSize: "18px",
                    fontWeight: "bold",
                    margin: "0.5rem 0",
                  }}
                >
                  {item.name}
                </Typography>
                <div
                  id="income"
                  style={{
                    background:
                      item.income === "Lower Income"
                        ? "#FFDADE"
                        : item.income === "Lower Middle Income"
                        ? "#E0E9FF"
                        : "#C8FFDE",
                    borderRadius: "100px",
                    color:
                      item.income === "Lower Income"
                        ? "#ED4C5C"
                        : item.income === "Lower Middle Income"
                        ? "#075DDE"
                        : "#208B16",
                    display: "inline-block",
                    padding: "0.5rem 1rem",
                    fontFamily: "Kumbh Sans",
                    fontWeight: "600",
                  }}
                >
                  {item.income}
                </div>
              </Card>
            ))}
          </Container>
        </Col>
      </Row>
    </Layout>
  );
};

export default WorldBank;
