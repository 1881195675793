import React from 'react'
import Layout from '../../components/layout/Layout'
import ComingSoon from '../../components/soon/ComingSoon'

const RegistrationSoon = () => {
    return (
        <Layout>
            <ComingSoon>
                Our Registration Menu is Coming Soon. Stay Tune!
            </ComingSoon>
        </Layout>
    )
}

export default RegistrationSoon