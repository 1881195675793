import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../../configs/public_url";

const fetchTransportList = createAsyncThunk(
  "transportList/fetchTransportList",
  async () => {
    const res = await directus.items("transport_list").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchTransportList;
