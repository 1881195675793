import { createSlice } from "@reduxjs/toolkit";
import fetchVenueLocation from "../../reducers/information/venueLocation_reducer";

const venueLocationSlice = createSlice({
  name: "venueLocation",
  initialState: {
    venueLocation: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchVenueLocation.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchVenueLocation.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.venueLocation = action.payload;
    });
    builder.addCase(fetchVenueLocation.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
});

export default venueLocationSlice.reducer;
