import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFormPage: false,
}

const formRegistrationSlice = createSlice({
    name: 'formRegistration',
    initialState,
    reducers: {
        setIsFormPage: (state, action) => {
            state.isFormPage = action.payload
        }
    }
})

export const { setIsFormPage } = formRegistrationSlice.actions

export const selectIsFormPage = state => state.formRegistration.isFormPage

export default formRegistrationSlice.reducer