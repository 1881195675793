import React from "react";
import TabComponent from "../../components/tab/TabComponent";
import BecomeSponsor from "./BecomeSponsor";
import ListSponsor from "./ListSponsor";
import Layout from "../../components/layout/Layout";
import { useSelector } from "react-redux";
import { translation, sponsorship_page } from "../../helpers/translation";

const Sponsorship = () => {
  const { languages } = useSelector((state) => state.languages);
  const tab =
    window.location.href.includes("dev") ||
    window.location.href.includes("localhost")
      ? [
          {
            label: translation(sponsorship_page, languages, "become_sponsor"),
            component: <BecomeSponsor />,
            sponsor: true,
          },
          {
            label: translation(sponsorship_page, languages, "list_sponsor"),
            component: <ListSponsor />,
            sponsor: false,
          },
        ]
      : [
          {
            label: translation(sponsorship_page, languages, "become_sponsor"),
            component: <BecomeSponsor />,
            sponsor: true,
          },
        ];

  return (
    <Layout>
      <TabComponent
        labels={tab.map((item) => item.label)}
        tabs={tab.map((item) => item.component)}
      />
    </Layout>
  );
};

export default Sponsorship;
