import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

const ModalLogin = (props) => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        height: "100vh",
      }}>
      <Modal.Body
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          height: "80vh",
          fontFamily: "Plus Jakarta Sans",
          padding: "0 22%",
        }}>
        <svg
          width="204"
          height="204"
          viewBox="0 0 204 204"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M153.11 152.669C181.216 124.563 181.216 78.9953 153.11 50.8897C125.005 22.7842 79.4365 22.7842 51.3309 50.8898C23.2254 78.9953 23.2254 124.563 51.3309 152.669C79.4365 180.774 125.005 180.774 153.11 152.669Z"
            fill="#FDA81A"
            fill-opacity="0.1"
          />
          <path
            d="M113.987 184.105C75.9401 183.768 45.2727 152.524 45.6201 114.48C45.9675 76.4371 77.2015 45.763 115.248 46.1103C131.697 46.2612 147.551 52.2822 159.952 63.0882C160.185 63.2972 160.326 63.5893 160.345 63.9014C160.364 64.2134 160.259 64.5203 160.054 64.7559C159.848 64.9915 159.558 65.1368 159.247 65.1605C158.935 65.1842 158.626 65.0843 158.388 64.8824C146.411 54.4477 131.102 48.6347 115.218 48.4914C78.4864 48.1542 48.3283 77.7659 47.9944 114.501C47.6606 151.235 77.2689 181.39 114 181.727C150.732 182.065 180.893 152.45 181.227 115.718C181.323 106.953 179.678 98.255 176.387 90.13C176.329 89.9849 176.299 89.8298 176.3 89.6733C176.302 89.5168 176.334 89.3621 176.395 89.2181C176.456 89.074 176.545 88.9434 176.656 88.8337C176.768 88.7239 176.9 88.6373 177.045 88.5786C177.19 88.5199 177.345 88.4904 177.502 88.4916C177.658 88.4929 177.813 88.525 177.957 88.5861C178.101 88.6472 178.232 88.736 178.341 88.8476C178.451 88.9591 178.538 89.0912 178.596 89.2362C182.005 97.6514 183.708 106.66 183.608 115.738C183.268 153.775 152.05 184.466 113.987 184.105Z"
            fill="#FDA81A"
          />
          <path
            d="M111.55 143.134C110.132 143.118 108.776 142.551 107.769 141.552L63.3512 97.2731C63.1276 97.0495 63.002 96.7462 63.002 96.4299C63.002 96.1137 63.1276 95.8104 63.3512 95.5868C63.5748 95.3631 63.8781 95.2375 64.1944 95.2375C64.5106 95.2375 64.8139 95.3631 65.0375 95.5868L109.448 139.866C109.746 140.16 110.101 140.39 110.491 140.542C110.88 140.695 111.297 140.766 111.715 140.753C112.134 140.737 112.545 140.634 112.922 140.451C113.299 140.269 113.635 140.01 113.907 139.691L187.154 54.2821C187.36 54.0424 187.652 53.8942 187.967 53.8702C188.282 53.8461 188.594 53.9482 188.834 54.1539C189.073 54.3597 189.221 54.6522 189.245 54.9672C189.269 55.2822 189.167 55.5938 188.962 55.8335L115.711 141.242C115.225 141.807 114.627 142.266 113.956 142.591C113.285 142.916 112.554 143.1 111.809 143.131L111.55 143.134Z"
            fill="#FDA81A"
          />
        </svg>

        <h4
          style={{
            textAlign: "center",
            fontSize: "2rem",
            fontWeight: "700",
          }}>
          {languages === "eng"
            ? "Submit Paper Success"
            : "Berhasil Submit Paper"}
        </h4>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.2rem",
            fontWeight: "500",
            color: "#575757",
          }}>
          {languages === "eng"
            ? "Your paper has been submitted. Please wait for next email."
            : "Selamat Anda berhasil mengumpulkan paper anda. Silahkan tunggu response email selanjutnya"}
        </p>
        <Button
          onClick={props.onHide}
          className="orange-button"
          style={{
            backgroundColor: "#FDA81A",
            borderColor: "#FDA81A",
            margin: "1rem 0",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1f1f1f",
            fontSize: "1.3rem",
            padding: "0.7rem 0",
            width: "40%",
          }}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default ModalLogin;
