import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";
import { getHome } from "../../reducers/home_reducer";
import { getAssetURL } from "../../reducers/organization_reducer";
import parse from "html-react-parser";

export default function Summary() {
  const dispatch = useDispatch();
  const { home } = useSelector((state) => state.home);
  const { languages } = useSelector((state) => state.languages);

  useEffect(() => {
    dispatch(getHome());
  }, [dispatch]);
  const titleStyle = {
    fontWeight: "bold",
    fontSize: "1.5rem",
    padding: "1rem 0",
  };

  return (
    <Container
      className="my-4 px-5"
      style={{ margin: "auto", fontFamily: "Plus Jakarta Sans" }}
    >
      <h1 style={titleStyle}>{languages === "ind" ? home?.judul_indonesia : home?.judul_english}</h1>
      <img
        src={getAssetURL(home?.gambar?.id)}
        alt="WWC"
        style={{
          width: "30%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      />
      {parse("<p>" + (languages === "ind" ? home?.deskripsi_indonesia : home?.deskripsi_english) + "</p>")}
    </Container>
  );
}
