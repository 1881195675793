import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const fetchFieldTrip = createAsyncThunk(
    "program/fetchFieldTrip",
    async () => {
        const res = await directus.items("field_trip").readByQuery({
            fields: ["*.*"],
        });
        return res.data;
    }
)