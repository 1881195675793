import { configureStore } from "@reduxjs/toolkit";
import businessForumReducer from "../slices/businessForum_slicer";
import cardContentReducer from "../slices/cardContent_slicer";
import contactSlice from "../slices/contact_slicer";
import culinaryBazaarReducer from "../slices/culinaryBazaar_slicer";
import faqSlice from "../slices/faq_slicer";
import fieldTripReducer from "../slices/fieldTrip_slicer";
import formInputsReducer from "../slices/formInputs_slicer";
import formRegistrationReducer from "../slices/formRegistration";
import homeReducer from "../slices/home_slicer";
import languagesSlice from "../slices/languages_slicer";
import committeeReducer from "../slices/member_slicer";
import organizationReducer from "../slices/organization_slicer";
import scientificForumReducer from "../slices/scientificForum_slicer";
import sponsorshipSlice from "../slices/sponsorship_slicer";
import synopsisReducer from "../slices/synopsis_slicer";
import tabChangeReducer from "../slices/tabChange";

// general information page
import authReducer from "../reducers/auth_reducer";
import isPreloadReducer from "../reducers/preload_action";
import accomodationListReducer from "../slices/information/accomodationList_slicer";
import accomodationReducer from "../slices/information/accomodation_slicer";
import beautifulIndonesiaReducer from "../slices/information/beautifulIndonesia_slicer";
import indonesianCurrencyReducer from "../slices/information/indonesianCurrency_slicer";
import transportListReducer from "../slices/information/transportList_slicer";
import transportReducer from "../slices/information/transport_slicer";
import venueLocationReducer from "../slices/information/venueLocation_slicer";
import visaReducer from "../slices/information/visa_slicer";
import weatherInformationReducer from "../slices/information/weatherInformation_slicer";
export const store = configureStore({
  reducer: {
    authentication: authReducer,
    isPreload: isPreloadReducer,
    organization: organizationReducer,
    home: homeReducer,
    committee: committeeReducer,
    tabChange: tabChangeReducer,
    synopsis: synopsisReducer,
    fieldTrip: fieldTripReducer,
    culinaryBazaar: culinaryBazaarReducer,
    businessForum: businessForumReducer,
    scientificForum: scientificForumReducer,
    cardContent: cardContentReducer,
    formRegistration: formRegistrationReducer,
    formInputs: formInputsReducer,
    sponsorship: sponsorshipSlice,
    languages: languagesSlice,

    // general information page
    venueLocation: venueLocationReducer,
    accomodation: accomodationReducer,
    accomodationList: accomodationListReducer,
    transport: transportReducer,
    transportList: transportListReducer,
    beautifulIndonesia: beautifulIndonesiaReducer,
    visa: visaReducer,
    weatherInformation: weatherInformationReducer,
    indonesianCurrency: indonesianCurrencyReducer,
    faq: faqSlice,
    contact: contactSlice,
  },
});
