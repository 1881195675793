import { createSlice } from "@reduxjs/toolkit";
import fetchSynopsis from "../reducers/synopsis_reducer";

const synopsisSlice = createSlice({
  name: "synopsis",
  initialState: {
    synopsis: {},
    status: "idle",
    error: null,
  },
  reducers: {
    // No reducers needed for this slice
  },
  extraReducers: {
    // @ts-ignore
    [fetchSynopsis.pending]: (state, action) => {
      state.status = "loading";
    },
    // @ts-ignore
    [fetchSynopsis.fulfilled]: (state, action) => {
      state.status = "succeeded";
      // Add any fetched posts to the array
      state.synopsis = action.payload;
    },
    // @ts-ignore
    [fetchSynopsis.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export default synopsisSlice.reducer;
