import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import {
  getAssetURL,
  getOrganization,
} from "../../reducers/organization_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const OrganizationWPSA = () => {
  const { organization } = useSelector((state) => state.organization);
  const { languages } = useSelector((state) => state.languages);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  const Style = {
    logoKiri : {
      borderRight: isMobile ? 0 : 1, 
      borderBottom: isMobile ? 1 : 0, 
      borderColor: "divider", 
      px:0  
    },
    logoKanan : {
      px: isMobile ? 4 : 10,
      py: isMobile ? 4 : 0,
    },
  }

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    dispatch(getOrganization());
  }, []);

  return (
    <>
      <Typography variant="h4" sx={{ color: "#FDA81A", fontWeight: "bold", mb: 6, fontFamily: "Montserrat" }}>
        {languages === "ind" ? organization.judul_indonesia : organization.judul_english}
      </Typography>
      <Grid container spacing={1} sx={{alignItems: 'center'}}>
        <Grid xs={12} md={6} sx={ Style.logoKiri }>
          <img
            src={getAssetURL(organization?.gambar1?.id)}
            alt="MIPI"
          />
        </Grid>
        <Grid xs={12} md={6} sx={ Style.logoKanan }>
          <img
            src={getAssetURL(organization?.gambar2?.id)}
            alt="WPSA"
          />
        </Grid>
      </Grid>
      <Typography
        variant="body1"
        sx={{ textAlign: "justify", fontSize: "20px", lineHeight: "44px", mt: 5, fontFamily: "Plus Jakarta Sans, sans-serif" }}>
        {parse("<p>" + (languages === "ind" ? organization.deskripsi_indonesia : organization.deskripsi_english) + "</p>")}
      </Typography>
    </>
  );
};

export default OrganizationWPSA;
