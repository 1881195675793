import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchContact } from "../../reducers/contact_reducer";

const ListContact = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { contact } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.languages);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    dispatch(fetchContact());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}>
        {languages === "ind" ? "Kontak" : "Contact"}
      </Typography>

      <Row>
        {contact.map((contact, index) => {
          const Style = {
            divider: {
              borderRight: isMobile
                ? "0px"
                : contact.sort % 2 !== 0
                ? "2px solid #EDEDED"
                : "0px",
              borderBottom: isMobile ? "2px solid #E5E5E5" : "0px",
              paddingBottom: isMobile ? "2rem" : "0",
              marginTop: "3rem",
            },
          };
          return (
            <>
              <Col md={5} style={Style.divider} key={index}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "Plus Jakarta Sans",
                    mb: 6,
                  }}>
                  {languages === "ind" ? contact.divisi_indo : contact.divisi}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.5rem",
                  }}>
                  <p>
                    <b>{contact.nama_contact}</b> <br />
                  </p>
                  <p>
                    {contact.nomer_telepon[0].phone_id.nomer_telepon}
                    <br />
                  </p>
                  {contact.nomer_telepon.map((nomer, index) => {
                    if (index > 0) {
                      return (
                        <p key={index}>
                          {nomer.phone_id.nomer_telepon}
                          <br />
                        </p>
                      );
                    }
                  })}
                  <p>
                    <a
                      href={`mailto:${contact.email}`}
                      style={{ color: "blue" }}>
                      {contact.email}
                    </a>
                  </p>
                </Typography>
              </Col>
              <Col md={1}></Col>
            </>
          );
        })}
      </Row>
    </>
  );
};

export default ListContact;
