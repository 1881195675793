import { createSlice } from "@reduxjs/toolkit";
import fetchTransport from "../../reducers/information/transport_reducer";

const transportSlice = createSlice({
  name: "transport",
  initialState: {
    transport: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchTransport.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransport.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.transport = action.payload;
    });
    builder.addCase(fetchTransport.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
});

export default transportSlice.reducer;
