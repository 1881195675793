import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const fetchFaq = createAsyncThunk(
  "faq/fetchFaq",
  async () => {
    const res = await directus.items("faq").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
)