import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../../configs/public_url";

const fetchAccomodation = createAsyncThunk(
  "accomodation/fetchAccomodation",
  async () => {
    const res = await directus.items("accomodation").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchAccomodation;
