import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../../configs/public_url";

const fetchVenueLocation = createAsyncThunk(
  "venueLocation/fetchVenueLocation",
  async () => {
    const res = await directus.items("venue_and_location").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchVenueLocation;
