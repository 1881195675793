import React from "react";
import { Typography } from "@mui/material";
import CarouselComponent from "../../components/footer/CarouselComponent";
import { isListSponsorship } from "../../slices/sponsorship_slicer";
import { useDispatch } from "react-redux";
import { url } from "../../configs/public_url";
import { useSelector } from "react-redux";
import ComingSoon from '../../components/soon/ComingSoon';

const ListSponsor = () => {
  const { languages } = useSelector((state) => state.languages);
  const hrStyle = {
    marginTop: "2rem",
    marginBottom: "2rem",
  };

  const titleStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
  };

  const dispatch = useDispatch();
  const [diamond, setDiamond] = React.useState([]);
  const [platinum, setPlatinum] = React.useState([]);
  const [gold, setGold] = React.useState([]);

  React.useEffect(() => {
    dispatch(isListSponsorship(true));
    fetch(
      url +
        "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Diamond"
    )
      .then((res) => res.json())
      .then((res) => {
        setDiamond(res.data);
      });
    fetch(
      url +
        "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Platinum"
    )
      .then((res) => res.json())
      .then((res) => {
        setPlatinum(res.data);
      });
    fetch(
      url + "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Gold"
    )
      .then((res) => res.json())
      .then((res) => {
        setGold(res.data);
      });
  }, [dispatch]);
  return (
    <>
      <Typography
        variant="h4"
        sx={{
          color: "#FDA81A",
          fontWeight: "bold",
          mb: 6,
          fontFamily: "Montserrat",
        }}
      >
        {languages === "ind" ? "Daftar Sponsor" : "List of Sponsor"}
      </Typography>

      {window.location.href.includes('dev') || window.location.href.includes('localhost') ?
            <div>
            <h3 style={titleStyle}>Diamond</h3>
            <hr style={hrStyle} />
            <CarouselComponent Data={diamond} />
            <h3 style={titleStyle}>Platinum</h3>
            <hr style={hrStyle} />
            <CarouselComponent Data={platinum} />
            <h3 style={titleStyle}>Gold</h3>
            <hr style={hrStyle} />
            <CarouselComponent Data={gold} />
          </div> :
          <ComingSoon>Our Sponsorship Menu is Coming Soon. Stay Tune!</ComingSoon>
      } 
    </>
  );
};

export default ListSponsor;
