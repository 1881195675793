import { Typography } from "@mui/material";
import React from "react";
import TableComponent from "./TableComponent";
import { useSelector } from "react-redux";

export default function RegistrationPrice() {
  const { languages } = useSelector((state) => state.languages);
  const titleStyle = {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: "900",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  };

  return (
    <div
      style={{
        marginBottom: "2rem",
        padding: "0",
        marginLeft: "0",
        marginRight: "0",
      }}
    >
      <Typography variant="h1" style={titleStyle}>
        {languages === "ind"
          ? "Harga Pendaftaran (Termasuk Pajak)"
          : "Registration Price's (Tax Included)"}
      </Typography>
      <TableComponent />
    </div>
  );
}
