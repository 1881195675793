import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchTypeOfRegistration } from '../../slices/formInputs_slicer'

const FieldsetTypeRegistration = () => {
    const Styles = {
        labelStyle: {
            fontWeight: "900",
            fontSize: "0.85rem",
            marginBottom: "0.5rem",
        },
        flexRadio: {
            display: 'flex',
            gap: '1rem',
        },
        radio: {
            height: '1.5rem',
            width: '1.5rem',
            marginBottom: '0.5rem',
            flexShrink: 0,
        },
        requiredStyle: {
            color: "red",
        },
    }

    const [input, setInput] = React.useState('')

    const dispatch = useDispatch()

    const handleChange = (e) => {
        setInput(
            e.target.value
        )
    }

    useEffect(() => {
        dispatch(fetchTypeOfRegistration(input))
    }, [dispatch, input])

    return (
        <div style={{
            gridColumn: 'span 6',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            marginBottom: '1rem'
        }} onChange={
            handleChange
        }>
            <label htmlFor="ParticipatingIn" style={Styles.labelStyle}><span style={Styles.requiredStyle}>*</span>Type of Registration</label>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type1"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Member WPSA - High income country"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type1">Member WPSA - High income country</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type2"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Member WPSA - Low-middle income country"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type2">Member WPSA - Low-middle income country</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type3"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Member WPSA - Students"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type3">Member WPSA - Students</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type4"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Non-Member WPSA - High income country"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type4">Non-Member WPSA - High income country</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type5"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Non-Member WPSA - Low-middle income country"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type5">Non-Member WPSA - Low-middle income country</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type6"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Non-Member WPSA - Students"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type6">Non-Member WPSA - Students</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type7"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Member WPSA - Indonesian"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type7">Member WPSA - Indonesian</label>
            </div>
            <div style={Styles.flexRadio}>
                <input type="radio"
                    id="type8"
                    name="typeofRegistration"
                    className="orange-radio"
                    value="Non-Member WPSA - Indonesian"
                    style={Styles.radio}
                    required
                />
                <label className='label-medium' htmlFor="type8">Non-Member WPSA - Indonesian</label>
            </div>
        </div>
    )
}

export default FieldsetTypeRegistration