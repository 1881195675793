import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const fetchBusinessForum = createAsyncThunk(
    "program/fetchBusinessForum",
    async () => {
        const res = await directus.items("business_forum").readByQuery({
            fields: ["*.*"],
        });
        return res.data;
    }
)