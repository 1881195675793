import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchVisa = createAsyncThunk("visa/fetchVisa", async () => {
  const res = await directus.items("visa").readByQuery({
    fields: ["*.*"],
  });
  return res.data;
});

export default fetchVisa;

export function getAssetURL(id) {
  return url + `/assets/${id}`;
}
