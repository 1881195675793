import React, { useEffect } from 'react'
import TextInput from '../../components/form/TextInput'
import { useDispatch } from 'react-redux'
import { fetchInputs } from '../../slices/formInputs_slicer'

const TextInputs3 = () => {
    const [isMobile, setIsMobile] = React.useState(false)
    const [inputs, setInputs] = React.useState({
        first_name3: '',
        last_name3: '',
        job_title3: '',
        institution_affiliation3: '',
        email3: '',
        phone_number3: '',
        address3: '',
        city3: '',
        postal_code3: '',
        country3: '',
    })

    const dispatch = useDispatch()

    const Styles = {
        span2: {
            gridColumn: isMobile ? 'span 6' : 'span 2',
        },
        span3: {
            gridColumn: isMobile ? 'span 6' : 'span 3',
        },
        span6and1: {
            gridColumn: 'span 6',
            gridRow: '4',
        },
    }

    const handleOnChange = async (e) => {
        await setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        })
    }

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        dispatch(fetchInputs(inputs))
    }, [dispatch, inputs])
    return (
        <>
            <TextInput
                label="First Name"
                type="text"
                name="first_name3"
                value={inputs.first_name3}
                onChange={handleOnChange}
                placeholder="Type your first name"
                style={Styles.span3}
            />
            <TextInput
                label="Last Name"
                type="text"
                name="last_name3"
                value={inputs.last_name3}
                onChange={handleOnChange}
                placeholder="Type your last name"
                style={Styles.span3}
            />
            <TextInput
                label="Job Title"
                type="text"
                name="job_title3"
                value={inputs.job_title3}
                onChange={handleOnChange}
                placeholder="Type your job title"
                style={Styles.span3}
            />
            <TextInput
                label="Institution/Affiiliation"
                type="text"
                name="institution_affiliation3"
                value={inputs.institution_affiliation3}
                onChange={handleOnChange}
                placeholder="Type your institution/affiliation"
                style={Styles.span3}
            />
            <TextInput
                label="Email"
                type="email"
                name="email3"
                value={inputs.email3}
                onChange={handleOnChange}
                placeholder="Type your email"
                style={Styles.span3}
            />
            <TextInput
                label="Phone Number"
                type="text"
                name="phone_number3"
                value={inputs.phone_number3}
                onChange={handleOnChange}
                placeholder="Type your phone number"
                style={Styles.span3}
            />
            <TextInput
                label="Address"
                type="textArea"
                name="address3"
                value={inputs.address3}
                onChange={handleOnChange}
                placeholder="Type your address"
                style={Styles.span6and1}
            />
            <TextInput
                label="City"
                type="text"
                name="city3"
                value={inputs.city3}
                onChange={handleOnChange}
                placeholder="Type your city"
                style={Styles.span2}
            />
            <TextInput
                label="Postal Code"
                type="text"
                name="postal_code3"
                value={inputs.postal_code3}
                onChange={handleOnChange}
                placeholder="Type your postal code"
                style={Styles.span2}
            />
            <TextInput
                label="Country"
                type="text"
                name="country3"
                value={inputs.country3}
                onChange={handleOnChange}
                placeholder="Type your country"
                style={Styles.span2}
            />
        </>
    )
}

export default TextInputs3