import { createSlice } from "@reduxjs/toolkit";
import { fetchBusinessForum } from "../reducers/businessForum_reducer";

const CulinaryBazaarSlicer = createSlice({
    name: 'businessForum',
    initialState: {
        businessForum: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(fetchBusinessForum.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchBusinessForum.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.businessForum = action.payload;
        })
        builder.addCase(fetchBusinessForum.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        })
    }
})

export  default CulinaryBazaarSlicer.reducer;