import { Description } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../components/form/FileUpload";
import { url } from "../../configs/public_url";
import ModalCancel from "../form/ModalCancel";
import ModalLogin from "../form/ModalLogin";
import AddIcon from "@mui/icons-material/Add";

const FormSubmission = () => {
  const navigate = useNavigate();
  const { authentication } = useSelector((state) => state);
  const idUser = localStorage.getItem("idUser");

  useEffect(() => {
    if (!authentication) {
      navigate("/submission/register-submission");
    }
  });
  const [isMobile, setIsMobile] = React.useState(false);
  const [modal, showModal] = React.useState(false);
  const [modalCancel, showModalCancel] = React.useState(false);
  const response = [];
  const { languages } = useSelector((state) => state.languages);
  const [abstract, setAbstract] = useState("");
  const [fullPaper, setFullPaper] = useState("");
  const [poster, setPoster] = useState("");
  const [presentation, setPresentation] = useState("");
  const [date, setDate] = useState(new Date("2023-06-12"));
  const [deadlineAbstract, setDeadlineAbstract] = useState(
    new Date("2023-04-30"),
  );
  const [dateFullpaper, setDateFullPaper] = useState(new Date("2023-08-31"));
  const today = new Date();
  const dateTarget = new Date("2023-06-01");
  const [files, setFiles] = React.useState({
    abstract: null,
    fullPaper: null,
    poster: null,
    presentation: null,
  });
  const [isAddClick, setIsAddClick] = React.useState(false);

  const handleAddClick = () => {
    setIsAddClick(true);
  };

  const Styles = {
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: "3rem",
      margin: "auto",
      marginTop: "3rem",
      marginBottom: "20%",
      width: "100vw",
      position: "relative",
      maxWidth: "1100px",
      fontFamily: "Plus Jakarta Sans, sans-serif",
      // fontSize: '0.9rem',
    },
    backButton: {
      position: "absolute",
      top: "0",
      left: isMobile ? "1rem" : "0",
      backgroundColor: "#f1f1f1f1",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "70%",
      gap: "2rem",
    },
    grid: {
      display: "grid",
      gridGap: "1.5rem",
      width: "100%",
    },
    titleStyle: {
      color: "#FDA81A",
      fontSize: "30px",
      marginBottom: "3rem",
      fontFamily: "Monsterrat, sans-serif",
      fontWeight: "800",
      lineHeight: "36.57px",
    },
    buttonSubmit: {
      backgroundColor: "#FDA81A",
      borderColor: "#FDA81A",
      margin: "1rem 0",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "3.5rem",
      fontSize: "1.26rem",
      fontFamily: "Plus Jakarta Sans",
      borderRadius: "0.5rem",
      color: "#1f1f1f",
      marginTop: "5rem",
    },
    buttonCancel: {
      backgroundColor: "#e9e9e9",
      borderColor: "#FDA81A",
      margin: "1rem 0",
      fontWeight: "bold",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "3.5rem",
      fontSize: "1.26rem",
      fontFamily: "Plus Jakarta Sans",
      borderRadius: "0.5rem",
      color: "#919191",
      marginTop: "5rem",
    },

    buttonGroup: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      gap: "1rem",
    },

    containerDisabled: {
      backgroundColor: "#F5F5F5",
    },
    titleStyleLabel: {
      fontWeight: "bold",
      fontSize: "0.80rem",
      marginBottom: "0.3rem",
    },

    subTitleStyle: {
      fontSize: "0.80rem",
    },
    iconStyle: {
      width: "100%",
      color: "gray",
      margin: "0.5rem 0",
      fontSize: "2rem",
    },
  };

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    fetch(`${url}/items/abstract_file`)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const test = responseJson.data.filter((z) => z.idUser === idUser);
        setAbstract(test);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    fetch(`${url}/items/fullpaper`)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const test = responseJson.data.filter((z) => z.idUser === idUser);
        setFullPaper(test);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    fetch(`${url}/items/poster`)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const test = responseJson.data.filter((z) => z.idUser === idUser);
        setPoster(test);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    const idUser = localStorage.getItem("idUser");
    fetch(`${url}/items/presentation`)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        const test = responseJson.data.filter((z) => z.idUser === idUser);
        setPresentation(test);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSubmit = async () => {
    const email = localStorage.getItem("email").replaceAll('"', "");
    let abstractId, fullPaperId, posterId, presentationId;
    if (files.abstract !== null) {
      let formData = new FormData();
      let folderId =
        window.location.href.includes("dev") ||
        window.location.href.includes("localhost")
          ? "f3d4e03f-7f38-41d2-b00d-8140f95b0cfc"
          : "4f41f0bf-768b-40c7-8349-ec3d87a2f285";
      formData.append("folder", folderId);
      formData.append("file", files.abstract);

      abstractId = await fetch(`${url}/files`, {
        method: "POST",
        body: formData,
      });
      const responseJson = await abstractId.json();
      if (responseJson.filesize > 2 * 1024 * 1024) {
        alert("Your file is more than 2 MB. Please choose other file");
      }
      const submission = await fetch(`${url}/items/abstract_file`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          abstract_file: responseJson.data.id,
          title: responseJson.data.filename_download,
          idUser: idUser,
          email: email,
        }),
      });

      const responseSubmission = await submission.json();

      showModal(true);
      // window.location.reload();

      return responseSubmission;
    } else if (files.poster !== null) {
      let formData = new FormData();
      let folderId =
        window.location.href.includes("dev") ||
        window.location.href.includes("localhost")
          ? "cc751acc-d46c-40ab-8abd-b1c5ca454648"
          : "53287283-f789-47a3-bb90-9198b9cbcbec";
      formData.append("folder", folderId);
      formData.append("file", files.poster);
      posterId = await fetch(`${url}/files`, {
        method: "POST",
        body: formData,
      });

      const responseJson = await posterId.json();
      if (responseJson.filesize > 2 * 1024 * 1024) {
        alert("Your file is more than 2 MB. Please choose other file");
      }
      const submission = await fetch(`${url}/items/poster`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          poster_file: responseJson.data.id,
          title: responseJson.data.filename_download,
          idUser: idUser,
          email: email,
        }),
      });
      const responseSubmission = await submission.json();
      showModal(true);
      return responseSubmission;
    } else if (files.fullPaper) {
      let formData = new FormData();
      let folderId =
        window.location.href.includes("dev") ||
        window.location.href.includes("localhost")
          ? "214ab142-56ea-4f81-a4bb-12307a6f45f1"
          : "28ea47da-54b2-40ad-804a-28753f790d4b";
      formData.append("folder", folderId);
      formData.append("file", files.fullPaper);
      fullPaperId = await fetch(`${url}/files`, {
        method: "POST",
        body: formData,
      });

      const responseJson = await fullPaperId.json();
      if (responseJson.filesize > 2 * 1024 * 1024) {
        alert("Your file is more than 2 MB. Please choose other file");
      }
      const submission = await fetch(`${url}/items/fullpaper`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          fullpaper_file: responseJson.data.id,
          title: responseJson.data.filename_download,
          idUser: idUser,
          email: email,
        }),
      });
      const responseSubmission = await submission.json();
      showModal(true);
      return responseSubmission;
    } else if (files.presentation) {
      let formData = new FormData();

      let folderId =
        window.location.href.includes("dev") ||
        window.location.href.includes("localhost")
          ? "06f82030-f0d7-4233-ad25-9404aea0653b"
          : "4e2cd0e5-435d-4eb0-bf1d-f97a9038c607";
      formData.append("folder", folderId);
      formData.append("file", files.presentation);
      presentationId = await fetch(`${url}/files`, {
        method: "POST",
        body: formData,
      });
      const responseJson = await presentationId.json();
      if (responseJson.filesize > 2 * 1024 * 1024) {
        alert("Your file is more than 2 MB. Please choose other file");
      }
      const submission = await fetch(`${url}/items/presentation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          presentation_file: responseJson.data.id,
          title: responseJson.data.filename_download,
          idUser: idUser,
          email: email,
        }),
      });
      const responseSubmission = await submission.json();
      showModal(true);
      return responseSubmission;
    } else {
      alert("Masukkan Submission");
    }
  };

  const handleChanges = (e) => {
    setFiles({
      ...files,
      [e.target.name]: e.target.files[0],
    });
  };
  const [click, setClick] = useState(false);

  //let abstractAdditionalField = <div></div>;

  let [abstractAdditionalFields, addField] = useState([]);
  const addAbstract = () => {
    const abstractAdditionalField = React.cloneElement(abstractElement, {
      id: "lalala",
    });
    addField([...abstractAdditionalFields, abstractAdditionalField]);
    //abstractAdditionalField = React.cloneElement(abstractElement);
  };

  const abstractElement = (
    <FileUpload
      id="FileAbstract"
      onChange={handleChanges}
      label={languages === "ind" ? "Pedoman Abstrak" : "Abstract Guideline"}
      type="file"
      value={files.abstract}
      name="abstract"
      style={
        abstract.length === 1
          ? Styles.containerDisabled
          : today < deadlineAbstract
          ? ""
          : ""
      }
      title={
        abstract.length === 1 ? (
          <label>{abstract[0].title}</label>
        ) : !files.abstract ? (
          "Upload Document"
        ) : (
          <label>{files.abstract.name}</label>
        )
      }
      disabled={
        abstract.length === 1 ? true : today < deadlineAbstract ? true : false
      }
      subTitle="Format .docx or .pdf (Max.2 MB)"
      onClick={addAbstract}
      icon={
        <IconButton>
          <Description />
        </IconButton>
      }></FileUpload>
  );

  return (
    <div style={Styles.wrapper}>
      <a href="/submission">
        <IconButton aria-label="back" style={Styles.backButton}>
          <img src={require("../../assets/images/arrow back.png")} />
        </IconButton>
      </a>
      <h1 style={Styles.titleStyle}>Submission Form</h1>

      <div style={Styles.grid}>
        <div style={{ display: "grid", gap: "20px" }}>
          <div
            style={{
              display: "grid",
              gap: "1.2rem",
            }}>
            <FileUpload
              id="FileAbstract"
              onChange={handleChanges}
              label={
                languages === "ind" ? "Pedoman Abstrak" : "Abstract Guideline"
              }
              type="file"
              value={files.abstract}
              name="abstract"
              style={
                abstract.length === 1
                  ? Styles.containerDisabled
                  : today < deadlineAbstract
                  ? ""
                  : ""
              }
              title={
                abstract.length === 1 ? (
                  <label>{abstract[0].title}</label>
                ) : !files.abstract ? (
                  "Upload Document"
                ) : (
                  <label>{files.abstract.name}</label>
                )
              }
              disabled={
                abstract.length === 1
                  ? true
                  : today < deadlineAbstract
                  ? true
                  : false
              }
              subTitle="Format .docx, .pdf, .zip or .rar (Max.2 MB)"
              icon={
                <IconButton>
                  <Description />
                </IconButton>
              }
              onClick={addAbstract}
            />
            {abstractAdditionalFields.map((element, index) => (
              <div key={index}>{element}</div>
            ))}

            <FileUpload
              id="FullPaper"
              onChange={handleChanges}
              label={
                languages === "ind"
                  ? "Pedoman Fullpaper"
                  : "Full Paper Guideline"
              }
              style={fullPaper.length === 1 ? Styles.containerDisabled : ""}
              type="file"
              value={files.fullPaper}
              name="fullPaper"
              disabled={fullPaper.length === 1 ? true : false}
              title={
                fullPaper.length === 1 ? (
                  <label>{fullPaper[0].title}</label>
                ) : !files.fullPaper ? (
                  "Upload Document"
                ) : (
                  <label>{files.fullPaper.name}</label>
                )
              }
              subTitle="Format .docx, .pdf, .zip or .rar (Max.2 MB)"
              icon={
                <IconButton>
                  <Description />
                </IconButton>
              }
            />

            <FileUpload
              id="Poster"
              onChange={handleChanges}
              label={
                languages === "ind" ? "Pedoman Poster" : "Poster Guideline"
              }
              style={poster.length === 1 ? Styles.containerDisabled : ""}
              type="file"
              name="poster"
              value={files.poster}
              disabled={poster.length === 1 ? true : false}
              icon={
                <IconButton>
                  <Description />
                </IconButton>
              }
              title={
                poster.length === 1 ? (
                  <label>{poster[0].title}</label>
                ) : !files.poster ? (
                  "Upload Document"
                ) : (
                  <label>{files.poster.name}</label>
                )
              }
              subTitle="Format .docx, .pdf, .zip or .rar (Max.2 MB)"
            />

            <FileUpload
              id="Presentation"
              onChange={handleChanges}
              label={
                languages === "ind"
                  ? "Pedoman Format Presentasi"
                  : "Presentation Format Guideline"
              }
              value={files.presentation}
              name="presentation"
              style={presentation.length === 1 ? Styles.containerDisabled : ""}
              disabled={presentation.length === 1 ? true : false}
              type="file"
              icon={
                <IconButton>
                  <Description />
                </IconButton>
              }
              title={
                presentation.length === 1 ? (
                  <label>{presentation[0].title}</label>
                ) : !files.presentation ? (
                  "Upload Document"
                ) : (
                  <label>{files.presentation.name}</label>
                )
              }
              subTitle="Format .docx, .pdf, .zip or .rar (Max.2 MB)"
            />
          </div>
        </div>
      </div>
      <div style={Styles.buttonGroup}>
        <button
          style={Styles.buttonCancel}
          className="cancel-button"
          type="button"
          onClick={() => showModalCancel(true)}>
          Cancel
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          style={Styles.buttonSubmit}
          className="orange-button">
          Submit
        </button>
      </div>

      <ModalLogin
        show={modal}
        onHide={() => {
          showModal(false);
          navigate("/submission/register-submission");
        }}
      />
      <ModalCancel
        show={modalCancel}
        onYes={() => {
          showModalCancel(false);
          navigate("/submission");
        }}
        onNo={() => showModalCancel(false)}
      />
    </div>
  );
};

export default FormSubmission;
