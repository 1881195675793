import { Typography } from '@mui/material'
import { Container } from 'react-bootstrap'

const ComingSoon = ({children}) => {
    return (
        <Container className='text-center' style={{margin: '10rem auto 30rem auto'}}>
            <img src={require('../../assets/images/soon.png')} alt="Coming Soon" style={{margin: '0 auto'}} />
            <Typography variant='h4' sx={{fontFamily: 'Plus Jakarta Sans', fontWeight: 'bold'}}>
                Announcement
            </Typography>
            <Typography variant='h5' sx={{fontFamily: 'Plus Jakarta Sans', color: '#747474', mt: 2}}>
                {children}
            </Typography>
        </Container>
    )
}

export default ComingSoon