import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import * as React from "react";
import { useSelector } from "react-redux";

export default function FabComponent() {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Box
      sx={{
        "& > :not(style)": { m: 2 },
        position: "fixed",
        bottom: "5%",
        right: "5%",
        zIndex: "1020",
      }}
    >
      <Fab
        variant="extended"
        size="small"
        sx={{
          mr: 1,
          fontWeight: "900",
          textTransform: "none",
          borderRadius: "10px",
          "&:hover": { boxShadow: "none" },
        }}
        style={{
          fontSize: "0.8rem",
          padding: "1.2rem",
        }}
      >
        <a
          className="wa"
          href="https://api.whatsapp.com/send/?phone=%2B6285697203534&text&type=phone_number&app_absent=0"
        >
          {languages === "ind" ? "Hubungi Kami" : "Contact Us"}
        </a>
      </Fab>
      <Fab
        sx={{
          backgroundColor: "#61D057",
          color: "white",
          "&:hover": { boxShadow: "none" },
        }}
        size="small"
        aria-label="contact"
        style={{
          transform: "scale(1.5)",
        }}
      >
        <a href="https://api.whatsapp.com/send/?phone=%2B6285697203534&text&type=phone_number&app_absent=0">
          <WhatsAppIcon fontSize="small" />
        </a>
      </Fab>
    </Box>
  );
}
