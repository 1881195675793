import { createSlice } from "@reduxjs/toolkit";
import { fetchCulinaryBazaar } from "../reducers/culinaryBazaar_reducer";

const CulinaryBazaarSlicer = createSlice({
    name: 'culinaryBazaar',
    initialState: {
        culinaryBazaar: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(fetchCulinaryBazaar.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchCulinaryBazaar.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.culinaryBazaar = action.payload;
        })
        builder.addCase(fetchCulinaryBazaar.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        })
    }
})

export  default CulinaryBazaarSlicer.reducer;