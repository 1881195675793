import React from 'react'
import TextInput from '../../components/form/TextInput'
import { useDispatch } from 'react-redux'
import { fetchInputs } from '../../slices/formInputs_slicer'

const TextInputs2 = () => {
    const [isMobile, setIsMobile] = React.useState(false)
    const [inputs, setInputs] = React.useState({
        first_name2: '',
        last_name2: '',
        job_title2: '',
        institution_affiliation2: '',
        email2: '',
        phone_number2: '',
        address2: '',
        city2: '',
        postal_code2: '',
        country2: '',
    })

    const dispatch = useDispatch()

    const Styles = {
        span2: {
            gridColumn: isMobile ? 'span 6' : 'span 2',
        },
        span3: {
            gridColumn: isMobile ? 'span 6' : 'span 3',
        },
        span6and1: {
            gridColumn: 'span 6',
            gridRow: '4',
        },
    }

    const handleOnChange = async (e) => {
        await setInputs({
            ...inputs,
            [e.target.name]: e.target.value,
        })
    }

    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    React.useEffect(() => {
        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    React.useEffect(() => {
        dispatch(fetchInputs(inputs))
    }, [dispatch, inputs])
    return (
        <>
            <TextInput
                label="First Name"
                type="text"
                name="first_name2"
                value={inputs.first_name2}
                onChange={handleOnChange}
                placeholder="Type your first name"
                style={Styles.span3}
            />
            <TextInput
                label="Last Name"
                type="text"
                name="last_name2"
                value={inputs.last_name2}
                onChange={handleOnChange}
                placeholder="Type your last name"
                style={Styles.span3}
            />
            <TextInput
                label="Job Title"
                type="text"
                name="job_title2"
                value={inputs.job_title2}
                onChange={handleOnChange}
                placeholder="Type your job title"
                style={Styles.span3}
            />
            <TextInput
                label="Institution/Affiiliation"
                type="text"
                name="institution_affiliation2"
                value={inputs.institution_affiliation2}
                onChange={handleOnChange}
                placeholder="Type your institution/affiliation"
                style={Styles.span3}
            />
            <TextInput
                label="Email"
                type="email"
                name="email2"
                value={inputs.email2}
                onChange={handleOnChange}
                placeholder="Type your email"
                style={Styles.span3}
            />
            <TextInput
                label="Phone Number"
                type="text"
                name="phone_number2"
                value={inputs.phone_number2}
                onChange={handleOnChange}
                placeholder="Type your phone number"
                style={Styles.span3}
            />
            <TextInput
                label="Address"
                type="textArea"
                name="address2"
                value={inputs.address2}
                onChange={handleOnChange}
                placeholder="Type your address"
                style={Styles.span6and1}
            />
            <TextInput
                label="City"
                type="text"
                name="city2"
                value={inputs.city2}
                onChange={handleOnChange}
                placeholder="Type your city"
                style={Styles.span2}
            />
            <TextInput
                label="Postal Code"
                type="text"
                name="postal_code2"
                value={inputs.postal_code2}
                onChange={handleOnChange}
                placeholder="Type your postal code"
                style={Styles.span2}
            />
            <TextInput
                label="Country"
                type="text"
                name="country2"
                value={inputs.country2}
                onChange={handleOnChange}
                placeholder="Type your country"
                style={Styles.span2}
            />
        </>
    )
}

export default TextInputs2