import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import fetchVenueLocation from "../../reducers/information/venueLocation_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const VenueLocation = () => {
  const { venueLocation } = useSelector((state) => state.venueLocation);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVenueLocation());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? venueLocation.judul_indonesia
          : venueLocation.judul_english}
      </Typography>
      <Typography
        variant="h5"
        sx={{ fontWeight: "bold", fontFamily: "Plus Jakarta Sans", mt: 3 }}
      >
        {languages === "ind"
          ? venueLocation.nama_lokasi_indonesia
          : venueLocation.nama_lokasi_english}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        <img
          src={require("../../assets/images/location.png")}
          alt="location"
          style={{ display: "inline", marginRight: "1.5rem" }}
        />
        {venueLocation.alamat}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        <img
          src={require("../../assets/images/phone.png")}
          alt="phone"
          style={{ display: "inline", marginRight: "1.5rem" }}
        />
        {venueLocation.nomor_telepon}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        <img
          src={require("../../assets/images/email.png")}
          alt="email"
          style={{ display: "inline", marginRight: "1.5rem" }}
        />
        {venueLocation.email}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {languages === "ind"
          ? parse("<p>" + venueLocation.deskripsi_indonesia + "</p>")
          : parse("<p>" + venueLocation.deskripsi_english + "</p>")}
      </Typography>
      <iframe
        title="ICE BSD"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.7204187928087!2d106.63426301424172!3d-6.30042019544009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69fb535f152305%3A0x34406ed8b098f478!2sIndonesia%20Convention%20Exhibition!5e0!3m2!1sid!2sid!4v1669620624188!5m2!1sid!2sid"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
};

export default VenueLocation;
