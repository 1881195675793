import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchBusinessForum } from "../../reducers/businessForum_reducer";
import { Button } from "@mui/material";
import parse from "html-react-parser";

const BusinessForum = () => {
  const { businessForum } = useSelector((state) => state.businessForum);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBusinessForum());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? businessForum.judul_indonesia
          : businessForum.judul_english}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {parse(
          "<p>" +
            (languages === "ind"
              ? businessForum.description_indonesia
              : businessForum.description_english) +
            "</p>"
        )}
      </Typography>
      <div style={{ textAlign: "right" }}>
        <a href="/registration/form-registration">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FDA81A",
              color: "black",
              borderRadius: "8px",
              fontSize: "20px",
              fontWeight: "700",
              textTransform: "none",
              fontFamily: "Plus Jakarta Sans, sans-serif",
              lineHeight: "25px",
              textAlign: "right",
              mt: "30px",
              px: "28px",
              py: "16px",
              "&:hover": {
                backgroundColor: "#FDA81A",
                boxShadow: "0 5px 15px #FDA81A",
              },
            }}
          >
            {languages === "ind" ? "Pendaftaran" : "Registration"}
          </Button>
        </a>
      </div>
    </>
  );
};

export default BusinessForum;
