import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TabPanel = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const { children, index, ...other } = props;
  const { value } = useSelector((state) => state.tabChange);

  const Style = {
    box: {
      padding: isMobile ? "0px" : "10px",
      marginLeft: isMobile ? "0px" : "4rem",
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    handleResize();
    document.addEventListener("resize", () => {
      handleResize();
    });

    return () => {
      document.removeEventListener("resize", () => {
        handleResize();
      });
    }
  }, []);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box style={Style.box} >{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any,
};

export default TabPanel;
