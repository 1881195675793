import { createSlice } from "@reduxjs/toolkit";
import { fetchFieldTrip } from "../reducers/fieldTrip_reducer";

const FieldTripSlicer = createSlice({
    name: 'fieldTrip',
    initialState: {
        fieldTrip: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(fetchFieldTrip.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchFieldTrip.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.fieldTrip = action.payload;
        })
        builder.addCase(fetchFieldTrip.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        })
    }
})

export  default FieldTripSlicer.reducer;