import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import {
  fetchBeautifulIndonesia,
  getAssetURL,
} from "../../reducers/information/beautifulIndonesia_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const BeautifulIndonesia = () => {
  const { beautifulIndonesia } = useSelector(
    (state) => state.beautifulIndonesia
  );
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBeautifulIndonesia());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? beautifulIndonesia.judul_indonesia
          : beautifulIndonesia.judul_english}
      </Typography>
      {beautifulIndonesia?.cover?.id && (
        <img
          src={getAssetURL(beautifulIndonesia?.cover?.id)}
          alt="panorama"
          style={{ borderRadius: "20px", margin: "2rem auto" }}
        />
      )}
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {languages === "ind"
          ? parse("<p>" + beautifulIndonesia.isi_deskripsi_indonesia + "</p>")
          : parse("<p>" + beautifulIndonesia.isi_deskripsi_english + "</p>")}
      </Typography>
    </>
  );
};

export default BeautifulIndonesia;
