import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import Announcement from "../../assets/pdf/ANNOUNCEMENT.pdf";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  ArrowBack,
  ArrowForward,
  FlashlightOnRounded,
} from "@mui/icons-material";

const AnnouncementCard = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const Style = {
    container: {
      display: "grid",
      gridRows: "1fr 1fr",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      paddingBottom: "1rem",
    },
    arrow: {
      justifyContent: "center",
      display: "flex",
      marginTop: "2rem",
    },
    arrowBack: {
      alignItems: "center",
    },
    document: {
      width: "100%",
      border: "1px solid",
    },
    documentHalf: {
      width: "50%",
    },
    section: { color: "white", textAlign: "center", margin: 30 },
  };

  const onDocumentSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const prevPage = () => {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  };

  const nextPage = () => {
    setPageNumber(pageNumber >= numPages ? pageNumber : pageNumber + 1);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  });
  return (
    <Layout>
      <div style={Style.container}>
        <div style={Style.arrow}>
          <ArrowBack
            onClick={prevPage}
            role="button"
            fontSize="large"
            sx={{ "&:hover": { backgroundColor: "#d4d4d4" }, color: "orange" }}
          />
          <ArrowForward
            onClick={nextPage}
            role="button"
            fontSize="large"
            sx={{ "&:hover": { backgroundColor: "#d4d4d4" }, color: "orange" }}
          />
        </div>
        <div style={Style.document}>
          <Document file={Announcement} onLoadSuccess={onDocumentSuccess}>
            <Page
              size="A4"
              pageNumber={pageNumber}
              renderTextLayer={false}
              renderInteractiveForms={false}
              renderAnnotationLayer={false}
              height={isMobile ? "500" : null}
            />
          </Document>
        </div>
      </div>
    </Layout>
  );
};

export default AnnouncementCard;
