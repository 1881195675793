import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import TabComponent from "../../components/tab/TabComponent";
import { contact_page, translation } from "../../helpers/translation";
import Faq from "./Faq";
import ListContact from "./ListContact";

const Contact = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <>
      <Layout>
        <TabComponent
          labels={[
            translation(contact_page, languages, "contact"),
            translation(contact_page, languages, "faq"),
          ]}
          tabs={[<ListContact />, <Faq />]}
        />
      </Layout>
    </>
  );
};

export default Contact;
