import { createSlice } from "@reduxjs/toolkit";
import fetchTransportList from "../../reducers/information/transportList_reducer";

const transportListSlice = createSlice({
  name: "transportList",
  initialState: {
    transportList: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchTransportList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTransportList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.transportList = action.payload;
    });
    builder.addCase(fetchTransportList.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
});

export default transportListSlice.reducer;
