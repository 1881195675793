import RegisterSubmission from "../../components/content/RegisterSubmission";
import Layout from "../../components/layout/Layout";

const FormSubmission = () => (
  <Layout>
    <RegisterSubmission />
  </Layout>
);

export default FormSubmission;
