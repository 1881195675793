import React from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import TabComponent from "../../components/tab/TabComponent";
import { program_page, translation } from "../../helpers/translation";
import BusinessForum from "./BusinessForum";
import CulinaryBazaar from "./CulinaryBazaar";
import FieldTrip from "./FieldTrip";
import ProgramSynopsis from "./ProgramSynopsis";
import ScientificForum from "./ScientificForum";

const Program = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Layout>
      <TabComponent
        // labels={[
        //   translation(program_page, languages, "detailed_program"),
        //   translation(program_page, languages, "synopsis"),
        //   translation(program_page, languages, "plenary"),
        //   translation(program_page, languages, "scientific"),
        //   translation(program_page, languages, "business"),
        //   translation(program_page, languages, "culinary"),
        //   translation(program_page, languages, "field_trip"),
        // ]}
        // tabs={[<DetailedProgram />, <ProgramSynopsis />, <PlenarySession />, <ScientificForum />, <BusinessForum />, <CulinaryBazaar />, <FieldTrip />]}

        // hide tab detail program & plenary session
        labels={[
          translation(program_page, languages, "synopsis"),
          translation(program_page, languages, "scientific"),
          translation(program_page, languages, "business"),
          translation(program_page, languages, "culinary"),
          translation(program_page, languages, "field_trip"),
        ]}
        tabs={[
          <ProgramSynopsis />,
          <ScientificForum />,
          <BusinessForum />,
          <CulinaryBazaar />,
          <FieldTrip />,
        ]}
      />
    </Layout>
  );
};

export default Program;
