import React from "react";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";

export default function TableComponent() {
  const { languages } = useSelector((state) => state.languages);
  const tables = [
    {
      no: "1",
      information: "Member WPSA - High income country",
      information_ind: "Member WPSA - Negara berpenghasilan tinggi",
      earlyBirdPrice: "$250",
      regularPrice: "$300",
      latePrice: "$400",
    },
    {
      no: "2",
      information: "Member WPSA - Low-middle income country",
      information_ind: "Member WPSA - Negara berpenghasilan rendah menengah",
      earlyBirdPrice: "$150",
      regularPrice: "$200",
      latePrice: "$250",
    },
    {
      no: "3",
      information: "Member WPSA - Students",
      information_ind: "Member WPSA - Pelajar",
      earlyBirdPrice: "$75",
      regularPrice: "$100",
      latePrice: "$125",
    },
    {
      no: "4",
      information: "Non-Member WPSA - High income country",
      information_ind: "Non-Member WPSA - Negara berpenghasilan tinggi",
      earlyBirdPrice: "$300",
      regularPrice: "$350",
      latePrice: "$450",
    },
    {
      no: "5",
      information: "Non-Member WPSA – Low-middle income country*",
      information_ind:
        "Non-Member WPSA – Negara berpenghasilan rendah-menengah*",
      earlyBirdPrice: "$200",
      regularPrice: "$250",
      latePrice: "$300",
    },
    {
      no: "6",
      information: "Non-Member WPSA - Students",
      information_ind: "Non-Member WPSA - Pelajar",
      earlyBirdPrice: "$100",
      regularPrice: "$125",
      latePrice: "$150",
    },
    {
      no: "7",
      information: "Member WPSA - Indonesian",
      information_ind: "Member WPSA - WNI",
      earlyBirdPrice: "Rp 750.000",
      regularPrice: "Rp 1.000.000",
      latePrice: "Rp 1.500.000",
    },
    {
      no: "8",
      information: "Non-Member WPSA - Indonesian",
      information_ind: "Non-Member WPSA - WNI",
      earlyBirdPrice: "Rp 1.000.000",
      regularPrice: "Rp 1.500.000",
      latePrice: "Rp 2.000.000",
    },
    {
      no: "9",
      information: [
        "Optional Fees :",
        "a. Dinner (for spouse)",
        "b. Tour (for participant or spouse)",
      ],
      information_ind: [
        "Biaya Opsional :",
        "a. Makan malam (untuk pasangan)",
        "b. Wisata (untuk peserta atau pasangan)",
      ],
      earlyBirdPrice: "-",
      regularPrice: ["a. $ 25", "b. $ 40"],
      latePrice: "-",
    },
  ];
  function createTable(table) {
    if (table.no < 9)
      return (
        <tr style={{ verticalAlign: "middle" }}>
          <td
            style={{
              textAlign: "center",
              width: "9rem",
              height: "6rem",
            }}
          >
            {table.no}
          </td>
          <td className="text-nowrap">
            {languages === "ind" ? table.information_ind : table.information}
          </td>
          <td>{table.earlyBirdPrice}</td>
          <td>{table.regularPrice}</td>
          <td>{table.latePrice}</td>
        </tr>
      );
    if (table.no === "9") {
      return (
        <tr style={{ verticalAlign: "middle" }}>
          <td
            style={{
              textAlign: "center",
              width: "9rem",
              height: "6rem",
            }}
          >
            {table.no}
          </td>
          <td className="text-nowrap">
            {languages === "ind"
              ? table.information_ind[0]
              : table.information[0]}
            <br />
            {languages === "ind"
              ? table.information_ind[1]
              : table.information[1]}
            <br />
            {languages === "ind"
              ? table.information_ind[2]
              : table.information[2]}
          </td>
          <td>{table.earlyBirdPrice}</td>
          <td>
            {table.regularPrice[0]}
            <br />
            {table.regularPrice[1]}
          </td>
          <td>{table.latePrice}</td>
        </tr>
      );
    }
  }
  return (
    <Table striped responsive style={{ fontFamily: "Kumbh Sans" }}>
      <thead>
        <tr>
          <th style={{ textAlign: "center", verticalAlign: "middle" }}>No</th>
          <th style={{ verticalAlign: "middle" }}>
            {languages === "ind" ? "Informasi" : "Information"}
          </th>
          <th style={{ textAlign: "start", verticalAlign: "middle" }}>
            {languages === "ind"
              ? "Harga Early Bird Mulai September 2022 - April 2023"
              : "Early Bird Price From September 2022 - April 2023"}
          </th>
          <th style={{ textAlign: "start", verticalAlign: "middle" }}>
            {languages === "ind"
              ? "Harga Reguler Mulai dari Mei - Juli 2023"
              : "Regular Price From May - July 2023"}
          </th>
          <th style={{ textAlign: "start", verticalAlign: "middle" }}>
            {languages === "ind"
              ? "Harga Keterlambatan Dari Agustus - September 2023"
              : "Late Price From August - September 2023"}
          </th>
        </tr>
      </thead>
      <tbody>{tables.map(createTable)}</tbody>
    </Table>
  );
}
