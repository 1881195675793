import React from "react";

export default function Banner() {
  return (
    <img
      src={require("../../Banner.png")}
      alt="Banner"
      width="100%"
      style={{ marginBottom: "2rem" }}
    />
  );
}
