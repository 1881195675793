import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { fetchFieldTrip } from "../../reducers/fieldTrip_reducer";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import parse from "html-react-parser";

const FieldTrip = () => {
  const { fieldTrip } = useSelector((state) => state.fieldTrip);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFieldTrip());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? fieldTrip.judul_indonesia
          : fieldTrip.judul_english}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {parse(
          "<p>" +
            (languages === "ind"
              ? fieldTrip.description_indonesia
              : fieldTrip.description_english) +
            "</p>"
        )}
      </Typography>
      <div style={{ textAlign: "right" }}>
        <a href="/registration/form-registration">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FDA81A",
              color: "black",
              borderRadius: "8px",
              textTransform: "none",
              fontSize: "20px",
              fontWeight: "700",
              fontFamily: "Plus Jakarta Sans, sans-serif",
              lineHeight: "25px",
              textAlign: "right",
              mt: "30px",
              px: "28px",
              py: "16px",
              "&:hover": {
                backgroundColor: "#FDA81A",
                boxShadow: "0 5px 15px #FDA81A",
              },
            }}
          >
            {languages === "ind" ? "Pendaftaran" : "Registration"}
          </Button>
        </a>
      </div>
    </>
  );
};

export default FieldTrip;
