import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

const FileUpload = ({
  label,
  type,
  style,
  icon,
  name,
  title,
  subTitle,
  disabled,
  id,
  onChange,
  value,
  maxsize,
  onClick,
  addNewFieldInput,
}) => {
  const { languages } = useSelector((state) => state.languages);
  const labelStyle = {
    fontWeight: "bold",
    fontSize: "0.85rem",
    marginBottom: "0.5rem",
  };

  const requiredStyle = {
    color: "red",
  };

  const iconStyle = {
    width: "100%",
    color: "gray",
    margin: "0.5rem 0",
    fontSize: "2rem",
  };

  const addStyle = {
    width: "100%",
    margin: "0.6rem 0",
    fontSize: "2rem",
  };

  const titleStyle = {
    fontWeight: "bold",
    fontSize: "0.80rem",
    marginBottom: "0.3rem",
  };

  const subTitleStyle = {
    fontSize: "0.80rem",
  };

  return (
    <div>
      <div>
        <label htmlFor={name} style={labelStyle}>
          {/* <span style={requiredStyle}>*</span> */}
          {label}
        </label>
      </div>
      <div className="card file-upload-wrapper" style={{ ...style }}>
        <div className="card-body">
          <input
            id={id}
            type={type}
            name={name}
            style={{ display: "none" }}
            onChange={onChange}
            accept=".pdf,.docx,.zip,.rar"
            disabled={disabled}
            maxsize={2 * 1024 * 1024}
          />
          <div className="row">
            <div className="col-md-11">
              <label htmlFor={id} style={{ display: "-webkit-box" }}>
                <span style={iconStyle}>{icon ? icon : ``}</span>
                <div style={{ margin: "0.6rem" }}>
                  <h1 style={titleStyle}>{title}</h1>
                  <h2 style={subTitleStyle}>{subTitle}</h2>
                </div>
              </label>
            </div>
            {value && (
              <div
                style={{
                  color: "green",
                  margin: "auto 0",
                  zIndex: "1",
                }}
                className="col-md-1">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                    fill="#0EA34A"
                  />
                </svg>
              </div>
            )}
            {/* <div className="col-md-1">
              <AddIcon fontSize="large" color="primary" onClick={onClick} />
            </div> */}
          </div>
        </div>
      </div>
      <div>
        <span style={{ fontSize: "12px", color: "grey" }}>
          {languages === "eng"
            ? " *for submission of more than one file, please compile it in .zip or .rar"
            : "Untuk submisi yang lebih dari satu file, tolong kumpulkan dalam bentuk .zip atau .rar"}
        </span>
      </div>
    </div>
  );
};

export default FileUpload;
