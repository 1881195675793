import React from "react";
import Layout from "../../components/layout/Layout";
import NavSection from "./NavSection";
import FeesInclude from "./FeesInclude";
import RegistrationPrice from "./RegistrationPrice";
import Redaction from "./Redaction";
import SocialProgram from "./SocialProgram";

export default function Registration() {
  return (
    <Layout>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          marginTop: "4rem",
          marginLeft: "4.9rem",
          marginRight: "4.9rem",
        }}>
        <NavSection />
        <FeesInclude />
        <RegistrationPrice />
        <Redaction />
        {/* {
          window.location.href.includes('dev') || window.location.href.includes('localhost') ?
          <SocialProgram /> : null
        } */}
      </div>
    </Layout>
  );
}
