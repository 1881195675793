import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../configs/public_url";

export const getOrganization = createAsyncThunk(
    "organization/getOrganization",
    async () => {
        const res = await directus.items("org_wpsa").readByQuery({
            fields: ["*.*"],
        });
        return res.data;
    }
)

// export const getMember = createAsyncThunk(
//     "organization/getMember",
//     async () => {
//         const res = await directus.items("org_member")
//     }
// )

export function getAssetURL(id) {
    return url + `/assets/${id}`;
}