import { createAsyncThunk } from "@reduxjs/toolkit";
import { graphql_url } from "../configs/wpsa_grapql_url";

const query =
  window.location.href.includes("dev") ||
  window.location.href.includes("localhost")
    ? `{
  indonesiaNativePoultry {
    nodes {
      id
      title
      content
      indonesiaNativeChickens {
        isiKontenEnglish
        isiKontenIndonesia
        judulEnglish
        namaPenulis
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      date
    }
  }
}`
    : `{
  indonesiaNativePoultry {
    nodes {
      id
      title
      content
      indonesiaNativeChickens {
        isiKontenEnglish
        isiKontenIndonesia
        judulEnglish
        namaPenulis
        tanggalArtikel
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }
  }
}`;

export const getCardContents = createAsyncThunk(
  "/getCardContents",
  async () => {
    const res = await fetch(graphql_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: query,
      }),
    }).then((response) => response.json());
    return res;
  },
);
