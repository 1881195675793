import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../configs/public_url";

export const getHome = createAsyncThunk("home/getHome", async () => {
  const res = await directus.items("home_konference").readByQuery({
    fields: ["*.*"],
  });
  return res.data;
});

export function getAssetURL(id) {
  return url + `/assets/${id}`;
}
