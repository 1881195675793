import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap/";
import "../../Hover.css";
import { useSelector } from 'react-redux';
import { home_page, translation } from "../../helpers/translation";

export default function Consideration() {
  const { languages } = useSelector(state => state.languages);
  const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "0.8rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
  };
  const titleStyle = {
    fontWeight: "700",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  };


  return (
    <Container
      className="my-4 px-5 mt-5"
      style={{ margin: "auto", fontFamily: "Plus Jakarta Sans" }}
    >
      <Row className="d-flex justify-content-around align-items-center">
        <Col sm={6}>
          <h1 style={titleStyle}>
            {translation(home_page, languages, "registration")}
          </h1>
          <p>
            {translation(home_page, languages, "registration_deadline")}
          </p>
          {/* <Button
            href="/registration"
            className="orange-button"
            style={buttonStyle}
          >
            {translation(home_page, languages, "register_now")}
          </Button> */}
        </Col>
        <Col sm={6}>
          <h1 style={titleStyle}>
            {translation(home_page, languages, "submission")}
          </h1>
          <p>
            {translation(home_page, languages, "submission_deadline")}
          </p>
          {/* <Button
            href="/submission"
            className="orange-button"
            style={buttonStyle}
          >
            {translation(home_page, languages, "submit_now")}
          </Button> */}
        </Col>
      </Row>
      <hr style={{ marginTop: "2rem" }} />
    </Container>
  );
}
