import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isListSponsorship } from "../../slices/sponsorship_slicer";

const BecomeSponsor = () => {
  const { languages } = useSelector((state) => state.languages);
  const [isMobile, setIsMobile] = useState(false);
  const dispatch = useDispatch();

  const styles = {
    list: {
      fontSize: "18px",
      fontFamily: "Plus Jakarta Sans",
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      marginLeft: "1.5rem",
      marginBottom: "5rem",
    },
    button: {
      backgroundColor: "#FDA81A",
      color: "black",
      borderRadius: "8px",
      textTransform: "none",
      fontSize: "20px",
      fontWeight: "700",
      fontFamily: "Plus Jakarta Sans",
      lineHeight: "25px",
      textAlign: "center",
      mt: "30px",
      px: "28px",
      py: "16px",
      "&:hover": {
        backgroundColor: "#FDA81A",
        boxShadow: "0 5px 15px #FDA81A",
      },
    },
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    dispatch(isListSponsorship(false));
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          color: "#FDA81A",
          fontWeight: "bold",
          mb: 6,
          fontFamily: "Montserrat",
        }}>
        {languages === "ind" ? "Menjadi Sponsor" : "Become a Sponsor"}
      </Typography>
      <Typography
        variant="h5"
        sx={{
          color: "black",
          fontWeight: "bold",
          mb: 4,
          fontFamily: "Plus Jakarta Sans",
        }}>
        {languages === "ind"
          ? "Biaya pendaftaran sudah termasuk"
          : "The registration fees include"}
      </Typography>

      <ul style={styles.list}>
        <li>
          {languages === "ind"
            ? "Pamerkan merek Anda ke pasar"
            : "Showcase your brand to the market"}
        </li>
        <li>
          {languages === "ind"
            ? "Perkuat posisi Anda sebagai kunci pemain dalam industri ini melalui paket eksklusif yang dibuat khusus"
            : "Reinforce your position as a key player in the industry through exclusive tailor-made packages"}
        </li>
        <li>
          {languages === "ind"
            ? "Jadilah bagian dari acara unik yang didedikasikan untuk pendidikan dan pertukaran ide bagi para profesional perunggasan"
            : "Be part of a unique event dedicated to education and idea exchange for poultry professionals"}
        </li>
        <li>
          {languages === "ind"
            ? "Jangkau dan libatkan  opini utama pemimpin dari pasar unggas"
            : "Reach out to and engage with key opinion leaders from poultry market"}
        </li>
        <li>
          {languages === "ind"
            ? "Menjadi mitra WWC 2023 dan berbagi tujuan untuk meningkatkan dan mengubah dunia ilmu perunggasan dan paket yang dibuat khusus untuk industri"
            : "Become a WWC 2023 partner and share goals for improving and changing the world of poultry science and industry tailor-made packages"}
        </li>
      </ul>

      <Typography
        variant="h5"
        sx={{
          color: "black",
          fontWeight: "bold",
          mb: 4,
          fontFamily: "Plus Jakarta Sans",
        }}>
        {languages === "ind"
          ? "Pendaftaran Sponsorship"
          : "Sponsorship Registration"}
      </Typography>

      <Grid container spacing={1}>
        <Grid
          xs={12}
          md={5}
          sx={{ borderRight: isMobile ? 0 : 1, borderColor: "divider" }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Plus Jakarta Sans", fontSize: "18px" }}>
            {languages === "ind"
              ? "Silakan klik tombol di bawah untuk memulai pendaftaran sponsor"
              : "Please click button below to begin the registration for sponsorship"}
          </Typography>
          <Button
            href="/sponsorship/form-sponsor"
            variant="contained"
            sx={styles.button}>
            {languages === "ind" ? "Menjadi Sponsor" : "Become a Sponsor"}
          </Button>
        </Grid>

        <Grid
          xs={12}
          md={6}
          sx={{ ml: isMobile ? 0 : 4, mt: isMobile ? 4 : 0 }}>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Plus Jakarta Sans", fontSize: "18px" }}>
            {languages === "ind"
              ? "Untuk informasi lebih lanjut tentang paket sponsorship, silakan lihat brosur ini"
              : "For more information about sponsorship package, please take a look on this brochure"}
          </Typography>
          <Button
            href="/sponsorship/get-brochure-pdf"
            variant="contained"
            sx={styles.button}>
            {languages === "ind" ? "Dapatkan Brosur" : "Get Brochure"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default BecomeSponsor;
