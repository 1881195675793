import { Typography } from "@mui/material";
import parse from "html-react-parser";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fetchTransportList from "../../reducers/information/transportList_reducer";
import {
  fetchTransport,
  getAssetURL,
} from "../../reducers/information/transport_reducer";

const Transport = () => {
  const { transport } = useSelector((state) => state.transport);
  const { transportList } = useSelector((state) => state.transportList);
  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.languages);

  useEffect(() => {
    dispatch(fetchTransport());
    dispatch(fetchTransportList());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? transport.judul_indonesia
          : transport.judul_english}
      </Typography>
      {transport?.gambar_transport?.id && (
        <img
          src={getAssetURL(transport?.gambar_transport?.id)}
          alt="transport"
          style={{ borderRadius: "20px", margin: "2rem auto" }}
        />
      )}
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      ></Typography>

      {transportList.map((transportList, index) => (
        <div key={index}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              fontFamily: "Plus Jakarta Sans",
              mt: 3,
              mb: 0.5,
            }}
          >
            {languages === "ind"
              ? transportList.nama_transport_indonesia
              : transportList.nama_transport_english}
          </Typography>
          {transportList?.gambar_transport?.id && (
            <img
              src={getAssetURL(transportList?.gambar_transport?.id)}
              alt="transport"
              style={{ borderRadius: "20px", margin: "2rem auto" }}
            />
          )}

          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              fontSize: "18px",
              lineHeight: "44px",
              fontFamily: "Plus Jakarta Sans, sans-serif",
            }}
          >
            {languages === "ind"
              ? parse(
                  "<p>" + transportList.deskripsi_transport_indonesia + "</p>"
                )
              : parse(
                  "<p>" + transportList.deskripsi_transport_english + "</p>"
                )}
          </Typography>
        </div>
      ))}
    </>
  );
};

export default Transport;
