import React, { useEffect, useState } from "react";
import Tab from "@mui/material/Tab";
import { useDispatch, useSelector } from "react-redux";
import { tabChange } from "../../slices/tabChange";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const TabName = (props) => {
  const { label, index } = props;
  const [clicked, setClicked] = useState(false);
  // @ts-ignore
  const { value } = useSelector((state) => state.tabChange);
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === index) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [value]);

  const handleClick = () => {
    dispatch(tabChange(index));
    if (value === index) {
      setClicked(true);
    } else {
      setClicked(false);
    }
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const theme = createTheme({
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            alignItems: "start",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Tab
        onClick={() => handleClick()}
        // className='text-nowrap'
        sx={{
          px: 1,
          py: clicked ? 1 : 3,
          textAlign: "start",
          margin: "0.5rem auto",
          width: "100%",
          borderRadius: "10px",
          opacity: 1,
          color: "black",
          fontSize: "20px",
          fontWeight: "800",
          fontFamily: "Plus Jakarta Sans",
          textTransform: "none",
          backgroundColor: clicked ? "#d4d4d4" : "#f2f2f2",
          "&:hover": {
            backgroundColor: "#d4d4d4",
            boxShadow: "0",
          },
        }}
        label={label}
        // @ts-ignore
        {...a11yProps(index)}
      />
    </ThemeProvider>
  );
};

export default TabName;
