import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

// {* Pages *}
import Contact from "./pages/contact/Contact";
import Home from "./pages/home/Home";
import Information from "./pages/information/Information";
import GetInvitation from "./pages/information/InformationSoon";
import Organization from "./pages/organization/Organization";
import Program from "./pages/program/Program";
import FormRegistration from "./pages/registration/FormRegistration";
import FormRegistrationSoon from "./pages/registration/RegistrationSoon";
import ListRegistration from "./pages/registration/ListRegistration";
import WorldBank from "./pages/registration/WorldBank";
import Sponsorship from "./pages/sponsorship/Sponsorship";
import SponsorshipSoon, {
  default as FormSponsor,
  default as GetBrochure,
} from "./pages/sponsorship/SponsorshipSoon";
import FormSubmission from "./pages/submission/FormSubmission";
import ListSubmission from "./pages/submission/ListSubmission";
import RegisterSubmission from "./pages/submission/RegisterSubmission";
import SubmissionSoon from "./pages/submission/SubmissionSoon";
import ResetPassword from "./components/form/ResetPassword";
import FormSponsorship from "./pages/sponsorship/FormSponsorship";
import GetBrochurePDF from "./pages/sponsorship/GetBrochure";
import AnnouncementCard from "./components/content/AnnouncementCard";
// import FormSponsor from "./pages/sponsorship/FormSponsor";
// import Sponsorship from "./pages/sponsorship/Sponsorship";
// import GetBrochure from "./pages/sponsorship/SponsorshipSoon";
// import GetInvitation from "./pages/information/InformationSoon";

import { useDispatch, useSelector } from "react-redux";
import ComingSoonCulinary from "./pages/program/ComingSoonCulinary";
import { asyncPreloadProcess } from "./reducers/preload_reducer";

function App() {
  const { authentication = null, isPreload = false } = useSelector(
    (states) => states,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(asyncPreloadProcess());
  }, [dispatch]);
  if (isPreload) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/organization" element={<Organization />} />
      <Route path="/program" element={<Program />} />
      <Route path="/registration" element={<ListRegistration />} />
      <Route path="/registration/form-registration-coming-soon" element={<FormRegistrationSoon />} />
      <Route
        path="/registration/form-registration"
        element={<FormRegistration />}
      />
      <Route path="/registration/world-bank" element={<WorldBank />} />
      <Route path="/submission" element={<ListSubmission />} />
      <Route path="/submission/form-submission" element={<FormSubmission />} />
      <Route
        path="/submission/register-submission"
        element={<RegisterSubmission />}
      />

      <Route
        path="/submission/submit-submission"
        element={<SubmissionSoon />}
      />
      <Route path="/sponsorship" element={<Sponsorship />} />
      <Route path="/sponsorship/form-sponsor" element={<FormSponsorship />} />
      <Route
        path="/sponsorship/get-brochure-pdf"
        element={<GetBrochurePDF />}
      />
      <Route path="/sponsorship/get-brochure" element={<GetBrochure />} />
      <Route path="/general-information" element={<Information />} />
      <Route
        path="/general-information/get-invitation"
        element={<GetInvitation />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route
        path="/program/culinary-registration"
        element={<ComingSoonCulinary />}
      />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/announcement" element={<AnnouncementCard />} />
    </Routes>
  );
}

export default App;
