import { createSlice } from "@reduxjs/toolkit";
import fetchBeautifulIndonesia from "../../reducers/information/beautifulIndonesia_reducer";

const beautifulIndonesiaSlice = createSlice({
  name: "beautifulIndonesia",
  initialState: {
    beautifulIndonesia: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchBeautifulIndonesia.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchBeautifulIndonesia.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.beautifulIndonesia = action.payload;
    });
    builder.addCase(fetchBeautifulIndonesia.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
});

export default beautifulIndonesiaSlice.reducer;
