import { Handshake } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function FeesInclude() {
  const { languages } = useSelector((state) => state.languages);
  const [isMobile, setIsMobile] = React.useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const titleStyle = {
    color: "#FDA81A",
    fontWeight: "800",
    fontSize: "2rem",
    marginBottom: "1rem",
    fontFamily: "Montserrat",
  };
  const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "0.8rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
  };
  const dates = [
    {
      id: 1,
      judul: "First Announcement:",
      judul_ind: "Pengumuman Pertama:",
      tgl: "September 6th, 2022",
      tgl_ind: "6 September 2022",
      guideline: "Abstract Guideline",
      guideline_ind: "Pedoman Abstrak",
      button: "Download",
      button_ind: "Unduh",
      url: "https://docs.google.com/document/d/1EbMXkLK2u4wyc4yYnN62WC89eNy5mA7t/export?format=doc",
    },
    {
      id: 2,
      judul: "Abstract Submission Deadline:",
      judul_ind: "Batas Waktu Pengiriman Abstrak:",
      tgl: "July 15th, 2023",
      tgl_ind: "15 Juli 2023",
      guideline: "Full Paper Guideline",
      guideline_ind: "Pedoman Makalah Lengkap",
      button: "Download",
      button_ind: "Unduh",
      url: "https://drive.google.com/u/0/uc?id=12GOTps2CW_owuThhPM8lP7xfaFjjsdXP&export=download",
    },
    {
      id: 3,
      judul: "Notification of Accepted Abstract:",
      judul_ind: "Pemberitahuan Abstrak Diterima:",
      tgl: "July 22nd, 2023",
      tgl_ind: "22 Juli 2023",
      guideline: "Poster Guideline",
      guideline_ind: "Pedoman Poster",
      button: "Download",
      button_ind: "Unduh",
      url: "https://docs.google.com/document/d/10ThGkkrpmKC-9wNWzLbG7xL89kEqz9tN/export?format=doc",
    },
    {
      id: 4,
      judul: "Full Paper Submission Deadline:",
      judul_ind: "Batas Waktu Pengiriman Makalah Lengkap:",
      tgl: "August 15th, 2023",
      tgl_ind: "15 Agustus 2023",
      guideline: "Presentation Format Guideline",
      guideline_ind: "Pedoman Format Presentasi",
      button: "Download",
      button_ind: "Unduh",
      url: "https://docs.google.com/document/d/10ThGkkrpmKC-9wNWzLbG7xL89kEqz9tN/export?format=doc",
    },
    {
      id: 5,
      judul: "Notification of Accepted Full Paper:",
      judul_ind: "Pemberitahuan Full Paper yang Diterima:",
      tgl: "September 1st, 2023",
      tgl_ind: "1 September 2023",
    },
    {
      id: 6,
      judul: "Revised Full Paper Submission Deadline:",
      judul_ind: "Batas Waktu Pengiriman Revisi Makalah:",
      tgl: "September 10th, 2023",
      tgl_ind: "10 September 2023",
    },
    {
      id: 7,
      judul: "Pre-Registration",
      judul_ind: "Pra-Pendaftaran",
      tgl: null,
      tgl_ind: null,
    },
  ];
  function createJudul(date) {
    return (
      <li
        style={{
          paddingLeft: "1.2rem",
        }}>
        {languages === "ind" ? date.judul_ind : date.judul}
      </li>
    );
  }
  function createDate(date) {
    return (
      <li
        style={{ fontWeight: "bold", paddingLeft: "1.2rem" }}
        className="text-nowrap">
        {languages === "ind" ? date.tgl_ind : date.tgl}
      </li>
    );
  }
  function createGL(date) {
    if (date.id < 5) {
      return (
        <div style={{ padding: "1rem 0" }}>
          <Row className="d-flex justify-content-between align-items-center">
            <Col
              xs={8}
              style={{
                fontFamily: "Plus Jakarta Sans",
                fontWeight: "500",
                fontSize: "20px",
                letterSpacing: "0.01px",
              }}>
              {languages === "ind" ? date.guideline_ind : date.guideline}
            </Col>
            <Col xs={4}>
              <Button
                href={date.url}
                className="orange-button float-end"
                style={buttonStyle}>
                {languages === "ind" ? date.button_ind : date.button}
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      );
    }
  }
  return (
    <Container className="my-4" style={{ margin: "auto" }}>
      <h1 style={titleStyle}>
        {languages === "ind" ? "Tanggal Penting" : "Important Dates"}
      </h1>
      <Row className="d-flex justify-content-between">
        <Col
          xs={8}
          style={{
            lineHeight: isMobile ? "" : "3",
            padding: isMobile ? "0" : "1rem 1rem",
            fontFamily: "Plus Jakarta Sans",
            fontSize: "18px",
            fontWeight: "500",
          }}>
          <ul
            style={{
              lineHeight: "2.3",
              paddingLeft: isMobile ? "" : "1.6rem",
              fontFamily: "Plus Jakarta Sans",
              listStyleType: isMobile ? "none" : "",
            }}>
            {dates.map(createJudul)}
          </ul>
        </Col>
        <Col
          xs={4}
          style={{
            lineHeight: isMobile ? "" : "3",
            padding: isMobile ? "" : "1rem 0",
            textAlign: "right",
            fontFamily: "Plus Jakarta Sans",
            fontSize: "18px",
            fontWeight: "900",
            marginTop: isMobile ? "1.8rem" : "",
          }}>
          <ul
            style={{
              lineHeight: "3.3",
              paddingLeft: isMobile ? "" : "1rem",
              paddingTop: "0.5rem",
              fontFamily: "Plus Jakarta Sans",
            }}
            className="list-unstyled">
            {dates.map(createDate)}
          </ul>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Alert
          fluid
          key="primary"
          variant="primary"
          style={{
            color: "black",
            fontWeight: "900",
            fontFamily: "Plus Jakarta Sans",
          }}>
          <InfoIcon color="primary" sx={{ mx: "2%" }} />
          {languages === "ind"
            ? "WWC diselenggarakan di ICE BSD, Indonesia pada tanggal 20-22 September 2023"
            : "WWC will be held in ICE BSD, Indonesia on September 20-22th, 2023"}
        </Alert>
      </Row>
      <Row
        className="d-flex justify-content-center"
        style={{
          padding: "2rem 0",
          paddingTop: "0",
        }}>
        <Col>
          {/* <Button
            href="/submission/register-submission"
            className="orange-button float-end"
            style={buttonStyle}>
            {languages === "ind" ? "Kirim Sekarang" : "Submit Now"}
          </Button> */}
        </Col>
      </Row>
      <hr
        style={{
          padding: "1rem 0",
        }}
      />
      {dates.map(createGL)}
    </Container>
  );
}
