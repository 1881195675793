import React, { useState, useEffect } from "react";
import { Container, Card, CardGroup, Button, Modal } from "react-bootstrap/";
import { useSelector, useDispatch } from "react-redux";
import { getCardContents } from "../../reducers/cardContent_reducer";
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { isIndonesia } from "../../helpers/translation";

function CarouselComponent() {
  const buttonStyle = {
    fontWeight: "bold",
    color: "#000000",
    backgroundColor: "white",
    border: "none",
    margin: "1rem 0",
  };
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const dispatch = useDispatch();
  const { cardContents } = useSelector((state) => state.cardContent);
  const [dataModal, setDataModal] = useState({
    title: "",
    content: "",
    image: "",
  });

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setIsTablet(false);
    } else if (window.innerWidth < 1024) {
      setIsMobile(false);
      setIsTablet(true);
    } else {
      setIsMobile(false);
      setIsTablet(false);
    }
  };

  useEffect(() => {
    dispatch(getCardContents());

    document.querySelectorAll(".swiper-wrapper").forEach((slide) => {
      slide.classList.add("align-items-center");
    });

    handleResize();
    document.addEventListener("scroll", () => {
      handleResize();
    });

    return () => {
      document.removeEventListener("scroll", () => {
        handleResize();
      });
    };
  }, [dispatch]);

  const data = cardContents.data?.indonesiaNativePoultry?.nodes;

  const { languages } = useSelector((state) => state.languages);

  const setModal = (i) => {
    setDataModal({
      title: isIndonesia(languages)
        ? window.location.href.includes("dev") ||
          window.location.href.includes("localhost")
          ? data[i].title
          : data[i].indonesiaNativeChickens.judulIndonesia
        : data[i].indonesiaNativeChickens.judulEnglish,
      content: isIndonesia(languages)
        ? data[i].indonesiaNativeChickens.isiKontenIndonesia
        : data[i].indonesiaNativeChickens.isiKontenEnglish,
      image: data[i].featuredImage?.node.sourceUrl,
    });
  };

  const [show, setShow] = useState(false);

  // const carouselStyle = {
  //   height: "300px",
  // };

  return (
    <Container className="my-4 px-5" style={{ margin: "auto" }}>
      <Swiper
        style={style.swiper}
        spaceBetween={20}
        slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}
        navigation={true}
        loop={true}
        modules={[Navigation]}
        className="mySwiper">
        {data?.map((item, i) => (
          <SwiperSlide style={style.slide}>
            <Button
              onClick={() => {
                setShow(true);
                setModal(i);
              }}
              data-id="button-itik"
              style={buttonStyle}>
              <CardGroup>
                <Card
                  style={{
                    margin: "2%",
                    position: "relative",
                    borderStyle: "none",
                  }}>
                  <Card.Img
                    src={item.featuredImage?.node.sourceUrl}
                    style={{
                      borderRadius: "15px",
                      maxWidth: "100%",
                      display: "block",
                    }}
                  />
                  <Card.ImgOverlay style={{ padding: "0" }}>
                    <div
                      className="bg-white text-black"
                      style={{
                        position: "absolute",
                        bottom: "-3rem",
                        padding: "0.5rem",
                        margin: "1rem 0",
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: "bold",
                        textAlign: "left",
                        maxWidth: "90%",
                      }}>
                      <Card.Text className="text-nowrap my-2">
                        {isIndonesia(languages)
                          ? item.title
                          : item.judulEnglish}
                      </Card.Text>
                      <Card.Text
                        style={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}>
                        {item.indonesiaNativeChickens.namaPenulis}
                      </Card.Text>
                    </div>
                  </Card.ImgOverlay>
                </Card>
              </CardGroup>
            </Button>
          </SwiperSlide>
        ))}
      </Swiper>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        style={{ textAlign: "justify" }}>
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            {dataModal.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={dataModal.image}
            alt="cover"
            style={{ width: "100%", height: "auto", borderRadius: "10px" }}
          />
          <p style={{ padding: "0 1.5rem", marginTop: "10px" }}>
            {parse(dataModal.content)}
          </p>
        </Modal.Body>
      </Modal>
      <hr style={{ margin: "5%" }} />
    </Container>
  );
}
export default CarouselComponent;

const style = {
  box: {
    marginLeft: "12%",
    marginRight: "12%",
    marginTop: "5%",
  },
  swiper: {
    paddingLeft: "3%",
    paddingRight: "3%",
    width: "100%",
    height: "100%",
    position: "relative",
  },
};
