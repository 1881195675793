import { Typography } from "@mui/material";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function NavSection() {
  const { languages } = useSelector((state) => state.languages);
  const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "0.8rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
    transform: "translateY(2rem)",
  };
  return (
    <div
      style={{
        marginBottom: "4rem",
        padding: "0",
        marginLeft: "0",
        marginRight: "0",
      }}
    >
      <Row className="d-flex justify-content-center align-items-start">
        <Col sm={9}>
          <Typography
            variant="h1"
            sx={{
              color: "#FDA81A",
              fontWeight: "800",
              marginBottom: "20px",
              fontFamily: "Montserrat",
              fontSize: "1.8rem",
            }}
          >
            {languages === "ind" ? "Pendaftaran" : "Registration"}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              fontSize: "20px",
              lineHeight: "40px",
              mt: 3,
              fontFamily: "Plus Jakarta Sans, sans-serif",
            }}
          >
            {languages === "ind"
              ? "Harap dicatat bahwa harga pendaftaran dari Agustus hingga 6 September 2023 akan dikenakan Late Price."
              : "Please note registration from August to September 6, 2023 will be charged with Late Price"}
          </Typography>
        </Col>
        <Col
          sm={3}
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "end",
            flexDirection: "column",
          }}
        >
          {/* <Button className="orange-button" style={buttonStyle}>
            <a
              style={{ color: "black", fontWeight: "bold" }}
              href="/registration/form-registration"
            >
              {languages === "ind" ? "Daftar Sekarang" : "Register Now"}
            </a>
          </Button> */}
        </Col>
      </Row>
    </div>
  );
}
