import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

const fetchSynopsis = createAsyncThunk("synopsis/fetchSynopsis", async () => {
  const res = await directus.items("synopsis").readByQuery({
    fields: ["*.*"],
  });
  return res.data;
});

export default fetchSynopsis;
