import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { url } from "../../configs/public_url";
import CarouselComponent from "./CarouselComponent";

function SponsorshipComponent() {
  const { languages } = useSelector((state) => state.languages);
  const hrStyle = {
    marginTop: "2rem",
    marginBottom: "2rem",
  };

  const titleStyle = {
    fontSize: "1.5rem",
    fontWeight: "bold",
  };
  const [published, setPublished] = React.useState([]);
  const [diamond, setDiamond] = React.useState([]);
  const [platinum, setPlatinum] = React.useState([]);
  const [gold, setGold] = React.useState([]);

  React.useEffect(() => {
    // fetch(
    //   url +
    //     "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Diamond",
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setDiamond(res.data);
    //   });
    // fetch(
    //   url +
    //     "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Platinum",
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setPlatinum(res.data);
    //   });
    // fetch(
    //   url + "/items/sponsorship?fields=*.*&filter[kategori_sponsor][_eq]=Gold",
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     setGold(res.data);
    //   });
    fetch(
      url + "/items/sponsorship?fields=*.*&filter[status_brand][_eq]=Published",
    )
      .then((res) => res.json())
      .then((res) => {
        setPublished(res.data);
      });
  }, []);

  return (
    <Container className="my-4 px-5">
      <h1
        className="d-flex justify-content-center"
        style={{
          fontSize: "2rem",
          fontWeight: "bold",
          marginBottom: "3.5rem",
        }}>
        {languages === "ind" ? "Pendukung" : "Sponsorship"}
      </h1>
      <div>
        {/* <h3 style={titleStyle}>Diamond</h3>
        <hr style={hrStyle} />
        <CarouselComponent Data={diamond} />
        <h3 style={titleStyle}>Platinum</h3>
        <hr style={hrStyle} />
        <CarouselComponent Data={platinum} />
        <h3 style={titleStyle}>Gold</h3>
        <hr style={hrStyle} />
        <CarouselComponent Data={gold} /> */}
        <CarouselComponent Data={published} />
      </div>
    </Container>
  );
}
export default SponsorshipComponent;
