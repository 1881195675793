import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Button, styled, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaq } from "../../reducers/faq_reducer";
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&:hover": {
    background: "#FFFFFF",
    boxShadow: "0 10px 34px rgba(253, 168, 26, 0.1)",
  },
  "&.Mui-expanded": {
    boxShadow: "0 10px 34px rgba(253, 168, 26, 0.1)",
  },
  boxShadow: "0px 10px 34px rgba(0, 0, 0, 0.05)",
  borderRadius: "15px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon sx={{ fontSize: "1.5rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: (theme.palette.mode = "#FFFFFF"),
  flexDirection: "row",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    color: "#FDA81A",
  },
  "&:hover .MuiAccordionSummary-expandIconWrapper": {
    color: "#FDA81A",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "&:hover": {
    color: "#FDA81A",
  },
  "&.Mui-expanded": {
    color: "#FDA81A",
  },
  padding: "20px 40px",
  borderRadius: "15px",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(5),
}));

const Faq = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [expanded, setExpanded] = React.useState("");
  const [more, setMore] = useState("none");
  const { faq } = useSelector((state) => state.faq);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    dispatch(fetchFaq());
  }, []);

  const Style = {
    question: {
      fontWeight: "bold",
      fontFamily: "Plus Jakarta Sans",
      display: "flex",
      alignItems: "center",
      flex: "none",
      order: "0",
      flexGrow: "0",
      mt: 2,
      width: isMobile ? "85%" : "95%",
    },
    answer: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      lineHeight: "30px",
      display: "flex",
      alignItems: "center",
      textAlign: "justify",
      borderTop: "1px solid rgba(0, 0, 0, .125)",
      pt: 2,
    },
    statement: {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "16px",
      lineHeight: "30px",
      display: "flex",
      alignItems: "center",
      textAlign: "justify",
      marginTop: "1rem",
    },
    arrow: {
      fontSize: "1.2rem",
    },
    button: {
      backgroundColor: "#FDA81A",
      color: "black",
      borderRadius: "8px",
      textTransform: "none",
      fontSize: "20px",
      fontWeight: "700",
      fontFamily: "Plus Jakarta Sans, sans-serif",
      lineHeight: "25px",
      mt: "70px",
      px: "28px",
      py: "16px",
      "&:hover": {
        backgroundColor: "#FDA81A",
        boxShadow: "0 5px 15px #FDA81A",
      },
    },
  };

  const pertanyaan_english = [
    "What do you (international travelers) need to prepare before traveling to Indonesia?",
    "Are you fully vaccinated for travel to Indonesia?",
    "Do you need to be quarantined on your arrival ?",
    "How does the inspection process take place upon arrival in Indonesia?",
    "How With Visa Free Aplication and Visa on Arrival (VoA) ?",
  ];

  const pertanyaan_indonesia = [
    "Apakah yang Anda ( wisatawan internasional ) butuhkan untuk persiapan sebelum berwisata ke Indonesia?",
    "Apakah Anda divaksinasi penuh untuk wisata ke Indonesia?",
    "Apakah Anda butuh dikarantina saat kedatangan Anda?",
    "Bagaimana proses pemeriksaan saat tiba di Indonesia?",
    "Bagaimana Dengan Aplikasi Bebas Visa dan Visa on Arrival (VoA)?",
  ];

  const jawaban_english = {
    question1: {
      point: [
        "a.Valid passport.",
        "b.Have a complete Covid-19 vaccine* (full dose) to be quarantine-free.",

        "c.Bring a Covid-19 vaccine certificate document (physical/digital).",
      ],
      statement:
        "*Vaccination requirements are waived for overseas travelers under 18 years.",
    },
    question2: {
      point: [
        "You are considered fully vaccinated :",
        "a. 2 weeks (14 days) after your dose of accepted single-dose vaccine (Johnson & Johnson).",
        "b. 2 weeks (14 days) after your dose of an accepted 2-dose vaccine (Pfizer, Moderna, Sinovac, AstraZeneca, and Sinopharm).",
      ],
      statement: [
        "International travelers can show proof of vaccination in English, both in digital or hard copy.",
      ],
    },
    question3: {
      point: [
        "a. NO, IF you have been fully vaccinated 14 days before departure.",
        "b. YES, IF you have not received the vaccine or incomplete dose 14 days before departure, you need to undergo quarantine for a 5x24 hour. ",
      ],
      statement: [
        "For international travelers under the age of 18 years and/or requiring exceptional protection, the requirements for vaccination and quarantine period follow the provisions of the parents/legal guardians/travel companions.",
      ],
    },
    question3: {
      point: [
        "a. NO, IF you have been fully vaccinated 14 days before departure.",
        "b. YES, IF you have not received the vaccine or incomplete dose 14 days before departure, you need to undergo quarantine for a 5x24 hour. ",
      ],
      statement: [
        "For international travelers under the age of 18 years and/or requiring exceptional protection, the requirements for vaccination and quarantine period follow the provisions of the parents/legal guardians/travel companions.",
      ],
    },
    question4: {
      point: [
        "When you arrive at the Indonesian airport/port, then the officer will direct you to the health checkpoint to check document requirements and body temperature.",
        "a.If you have no COVID-19 symptoms and body temperature is below 37.5 degrees Celsius, you are eligible to continue your trip with temporary green status for 30 days in the PeduliLindungi website or application.",
        "b.If you show COVID-19 symptoms or have a body temperature above 37.5 degrees Celsius, then you have to do RT-PCR test at the airport/port and wait for the result in the hotel.",
      ],
      statement: [
        "You are not allowed to go outside the hotel and interact with other people while waiting for the RT-PCR test.",
        "If the test result is negative, you are allowed to travel domestically. However, if the test results are positive, you need to isolate in accordance with applicable regulations. International travelers under the age of 18 are also required to undergo isolation if their parents or caregivers/travel companions are tested positive for COVID-19.",
        "The cost of the COVID-19 RT-PCR test for foreigners will be charged independently, while for Indonesian citizens covered by the government (free).",
      ],
    },
    question5: {
      point: [
        "a.Visa-free only applies to foreigners from 9 ASEAN countries.",
        "b.VoA applies to foreigners who aim to travel throughout Indonesia.",
        "c.VoA is submitted upon arrival of the foreigner after completing the health check and RT-PCR test at I Gusti Ngurah Rai Airport, with the required documents.",
      ],
    },
  };
  const jawaban_indonesia = {
    question1: {
      point: [
        "a.Paspor yang sah.",
        "b.Memiliki vaksin Covid-19 yang lengkap* ( dosis penuh ) untuk bebas karantina.",

        "c.Membawa dokumen sertifikat vaksin Covid-19 ( fisik/digital ).",
      ],
      statement:
        "*Kebutuhan vaksinasi dibebaskan untuk wisatawan luar negeri dibawah 18 tahun.",
    },
    question2: {
      point: [
        "Anda dipertimbangkan vaksinasi penuh :",
        "a. 2 minggu ( 14 hari ) setelah dosis Anda diterima vaksin dosis-tunggal ( Johnson & Johnson ).",
        "b. 2 minggu ( 14 hari ) setelah dosis Anda diterima 2-vaksin dosis ( Pfizer, Moderna, Sinovac, AstraZeneca, dan Sinofarm.",
      ],
      statement: [
        "Wisatawan internasional bisa menunjukkan bukti vaksinasi dalam bahasa inggris, keduanya baik dalam digital maupun salinan dalam bentuk cetak.",
      ],
    },
    question3: {
      point: [
        "a. TIDAK, JIKA Anda telah memiliki vaksinasi penuh 14 hari sebelum keberangkatan.",
        "b. YA, JIKA Anda tidak menerima vaksin atau dosis yang tidak lengkap 14 hari sebelum keberangkatan, Anda harus menjalani karantina selama 5x24 jam.",
      ],
      statement: [
        "Untuk wisatawan internasional  dibawah 18 tahun dan/atau memerlukan perlindungan khusus, persyaratan vaksinasi dan masa karantina mengikuti ketentuan dari orang tua/wali/pendamping perjalanan.",
      ],
    },
    question4: {
      point: [
        "Saat Anda tiba di bandara/pelabuhan Indonesia, kemudian petugas akan mengarahkan Anda ke pos pemeriksaan kesehatan untuk pemeriksaan kelengkapan dokumen dan suhu tubuh.",
        "a.Jika Anda tidak memiliki gejala COVID-19 dan suhu tubuh di bawah 37,5 derajat Celcius, Anda berhak melanjutkan perjalanan dengan status hijau sementara selama 30 hari di website atau aplikasi PeduliLindungi.",
        "b.Jika Anda menunjukkan gejala COVID-19 atau memiliki suhu tubuh di atas 37,5 derajat Celcius, maka Anda harus melakukan tes RT-PCR di bandara/pelabuhan dan menunggu hasilnya di hotel.",
      ],
      statement: [
        "Anda tidak diperbolehkan keluar hotel dan berinteraksi dengan orang lain selama menunggu tes RT-PCR.",
        "Jika hasil tes negatif, Anda diperbolehkan bepergian ke dalam negeri. Namun, jika hasil tesnya positif, Anda perlu melakukan isolasi sesuai ketentuan yang berlaku. Pelancong internasional di bawah usia 18 tahun juga diharuskan menjalani isolasi jika orang tua atau pengasuh/pendamping perjalanannya dinyatakan positif COVID-19.",
        "Biaya tes RT-PCR COVID-19 untuk WNA akan dibebankan secara mandiri, sedangkan untuk WNI ditanggung oleh pemerintah ( gratis ).",
      ],
    },
    question5: {
      point: [
        "a.Bebas visa hanya berlaku untuk orang asing dari 9 negara ASEAN.",
        "b.VoA berlaku untuk orang asing yang ingin bepergian ke seluruh Indonesia.",
        "c.VoA diserahkan pada saat kedatangan WNA setelah menyelesaikan pemeriksaan kesehatan dan tes RT-PCR di Bandara I Gusti Ngurah Rai, dengan membawa dokumen persyaratan.",
      ],
    },
  };

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          color: "#FDA81A",
          fontWeight: "bold",
          fontFamily: "Montserrat",
          mb: 5,
        }}
      >
        {languages === "ind"
          ? "Pertanyaan yang sering diajukan"
          : "Frequently Asked Question"}
      </Typography>
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5" sx={Style.question}>
            {languages === "ind"
              ? pertanyaan_indonesia[0]
              : pertanyaan_english[0]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Typography sx={Style.answer}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question1.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question1.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </Typography>
          <p style={Style.statement}>
            {languages === "ind"
              ? jawaban_indonesia.question1.statement
              : jawaban_english.question1.statement}
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5" sx={Style.question}>
            {languages === "ind"
              ? pertanyaan_indonesia[1]
              : pertanyaan_english[1]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Typography sx={Style.answer}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question2.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question2.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </Typography>
          <p style={Style.statement}>
            {languages === "ind"
              ? jawaban_indonesia.question2.statement
              : jawaban_english.question2.statement}
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5" sx={Style.question}>
            {languages === "ind"
              ? pertanyaan_indonesia[2]
              : pertanyaan_english[2]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Typography sx={Style.answer}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question3.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question3.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </Typography>
          <p style={Style.statement}>
            {languages === "ind"
              ? jawaban_indonesia.question3.statement
              : jawaban_english.question3.statement}
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5" sx={Style.question}>
            {languages === "ind"
              ? pertanyaan_indonesia[3]
              : pertanyaan_english[3]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Typography sx={Style.answer}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question4.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question4.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </Typography>
          <p style={Style.statement}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question4.statement.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question4.statement.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </p>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ mb: 3 }}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h5" sx={Style.question}>
            {languages === "ind"
              ? pertanyaan_indonesia[4]
              : pertanyaan_english[4]}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ pt: 0 }}>
          <Typography sx={Style.answer}>
            <ol style={{ listStyleType: "none" }}>
              {languages === "ind"
                ? jawaban_indonesia.question5.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))
                : jawaban_english.question5.point.map((key) => (
                    <li key={key}>{key}</li>
                  ))}
            </ol>
          </Typography>
          <p style={Style.statement}>
            {languages === "ind" ? (
              <p>
                Informasi lebih lanjut dapat dilihat di
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://covid19.go.id/id"
                >
                  {" "}
                  https://covid19.go.id/id
                </a>
              </p>
            ) : (
              <p>
                More information can be found at
                <a
                  style={{ textDecoration: "underline" }}
                  href="https://covid19.go.id/id"
                >
                  {" "}
                  https://covid19.go.id/id
                </a>
              </p>
            )}
          </p>
        </AccordionDetails>
      </Accordion>
      {/* 
      {faq.map((faq, index) =>
        index < 5 ? (
          <Accordion
            key={index}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
            sx={{ mb: 3 }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h5" sx={Style.question}>
                {languages === "ind"
                  ? faq.pertanyaan_indonesia
                  : faq.pertanyaan_english}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Typography sx={Style.answer} component="div">
                {languages === "ind"
                  ? faq.jawaban_indonesia
                  : faq.jawaban_english}
              </Typography>
              <List dense={dense} sx={Style.answer}>
                <ListItem>
                  <ListItemText>
                    {languages === "ind"
                      ? faq.jawaban_indonesia
                      : faq.jawaban_english}
                  </ListItemText>
                </ListItem>
              </List>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Accordion
            key={index}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
            sx={{ mb: 3, display: more }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography variant="h5" sx={Style.question}>
                {languages === "ind"
                  ? faq.pertanyaan_indonesia
                  : faq.pertanyaan_english}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 0 }}>
              <Typography sx={Style.answer}>
                {languages === "ind"
                  ? faq.jawaban_indonesia
                  : faq.jawaban_english}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )
      )} */}

      {/* <div className="text-center">
        {more === "none" ? (
          <Button sx={Style.button} onClick={() => setMore(null)}>
            {languages === "ind" ? "Tampilkan Lebih Banyak" : "Show More"}
          </Button>
        ) : (
          <Button sx={Style.button} onClick={() => setMore("none")}>
            {languages === "ind" ? "Tampilkan Lebih Sedikit" : "Show Less"}
          </Button>
        )}
      </div> */}
    </>
  );
};

export default Faq;
