import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/form/TextInput";
import { url } from "../../configs/public_url";
import { registration_form, translation } from "../../helpers/translation";
import {
  fetchMembership,
  fetchMembershipId,
} from "../../slices/formInputs_slicer";

const FieldsetMembership = () => {
  const [isMember1, setIsMember1] = React.useState(false);
  const [isMember2, setIsMember2] = React.useState(false);
  const [isMember3, setIsMember3] = React.useState(true);
  const [isMember, setIsMember] = React.useState(false);
  const [memberType, setMemberType] = React.useState("Non Member");
  const { languages } = useSelector((state) => state.languages);
  const [inputs, setInputs] = React.useState({});
  const Styles = {
    labelStyle: {
      fontWeight: "900",
      fontSize: "0.85rem",
      marginBottom: "0.5rem",
    },
    flexRadio: {
      display: "flex",
      gap: "1rem",
    },
    radio: {
      height: "1.5rem",
      width: "1.5rem",
    },
    requiredStyle: {
      color: "red",
    },
  };

  const dispatch = useDispatch();
  const [length, setLength] = React.useState(0);
  const [id, setId] = React.useState("");

  const validateId = async (e) => {
    //setLength(0);
    let res = null;
    if (isMember1) {
      res = await fetch(
        url + `/items/member_wpsa_international?filter[id_member][_eq]=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => res.json());
    } else if (isMember2) {
      res = await fetch(
        url + `/items/member_wpsa_indonesia?filter[id_member][_eq]=${e}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).then((res) => res.json());
    }
    const data = res;
    const length = await data.data.length;
    if (length > 0) {
      setIsMember(true);
      dispatch(fetchMembershipId(e));
    } else {
      setIsMember(false);
      dispatch(fetchMembershipId(""));
    }
    setLength(length);
  };

  const handleChange = (e) => {
    //setLength(0);
    //setId(e.target.value);
    if (e.target.id === "member1") {
      setIsMember1(true);
      setIsMember2(false);
      setIsMember3(false);
      setMemberType("Member WPSA International");
    } else if (e.target.id === "member2") {
      setIsMember1(false);
      setIsMember2(true);
      setIsMember3(false);
      setMemberType("Member WPSA Indonesia");
    } else if (e.target.id === "member3") {
      setIsMember1(false);
      setIsMember2(false);
      setIsMember3(true);
      setIsMember(false);
      setMemberType("Non Member");
    }
  };

  useEffect(() => {
    dispatch(fetchMembership(memberType));
  }, [memberType]);

  return (
    <>
      <fieldset
        style={{
          gridColumn: "span 6",
          gridRow: "4",
          display: "flex",
          flexDirection: "column",
          gap: "1.2rem",
          marginBottom: "2rem",
        }}
      >
        <label htmlFor="Membership" style={Styles.labelStyle}>
          <span style={Styles.requiredStyle}>*</span>
          {translation(registration_form, languages, "membership")}
        </label>
        <div style={Styles.flexRadio}>
          <input
            type="radio"
            id="member1"
            name="membership"
            className="orange-radio"
            value="Member WPSA International"
            style={Styles.radio}
            checked={isMember1}
            onChange={(e) => handleChange(e)}
          />
          <span className="label-medium">
            {translation(registration_form, languages, "member_inter")}
          </span>
        </div>
        <div style={Styles.flexRadio}>
          <input
            type="radio"
            id="member2"
            name="membership"
            className="orange-radio"
            value="Member WPSA Indonesia"
            style={Styles.radio}
            checked={isMember2}
            onChange={(e) => handleChange(e)}
          />
          <span className="label-medium">
            {translation(registration_form, languages, "member_indo")}
          </span>
        </div>
        <div style={Styles.flexRadio}>
          <input
            type="radio"
            id="member3"
            name="membership"
            className="orange-radio"
            value="Non Member"
            style={Styles.radio}
            checked={isMember3}
            onChange={(e) => handleChange(e)}
          />
          <span className="label-medium">
            {translation(registration_form, languages, "non_member")}
          </span>
        </div>

        {isMember1 || isMember2 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <TextInput
              label={translation(registration_form, languages, "membership_id")}
              name="membershipID"
              type="number"
              placeholder={translation(
                registration_form,
                languages,
                "membership_id_statement"
              )}
              required={true}
              style={{
                marginTop: "1rem",
              }}
              value={id}
              onChange={(e) => {
                setId(e.target.value);
                validateId(e.target.value);
              }}
              validation={isMember}
              id="membershipID"
            />
            {length < 1 && id !== "" && (
              <span style={{ color: "red" }}>Incorrect data entered</span>
            )}
            {isMember && (
              <div
                style={{
                  color: "green",
                  position: "absolute",
                  bottom: "20%",
                  right: "3%",
                  zIndex: "1",
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM8 15L3 10L4.41 8.59L8 12.17L15.59 4.58L17 6L8 15Z"
                    fill="#0EA34A"
                  />
                </svg>
              </div>
            )}
          </div>
        ) : null}
      </fieldset>
    </>
  );
};

export default FieldsetMembership;
