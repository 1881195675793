import { createSlice } from "@reduxjs/toolkit";
import { fetchContact } from "../reducers/contact_reducer";

const ContactSlicer = createSlice({
  name: 'contact',
  initialState: {
    contact: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchContact.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchContact.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.contact = action.payload;
    })
    builder.addCase(fetchContact.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    })
  }
})

export  default ContactSlicer.reducer;