import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

const tabChangeSlice = createSlice({
  name: "tabChange",
  initialState,
  reducers: {
    tabChange: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { tabChange } = tabChangeSlice.actions;
export default tabChangeSlice.reducer;
