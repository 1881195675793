import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../../Hover.css";
import eng from "../../assets/images/eng.png";
import indo from "../../assets/images/indo.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { setLanguage } from "../../slices/languages_slicer";
import { navbar_component, translation } from "../../helpers/translation";

function NavbarComponent() {
  const dispatch = useDispatch();
  const { languages } = useSelector((state) => state.languages);
  const handleSelect = (eventKey) => {
    if (eventKey === "1") {
      setLanguage("eng");
      dispatch(setLanguage("eng"));
    } else if (eventKey === "2") {
      setLanguage("ind");
      dispatch(setLanguage("ind"));
    }
  };

  React.useEffect(() => {
    const path = window.location.pathname;
    if (path === "/") {
      document.getElementById("home").classList.add("active");
    } else if (path === "/organization") {
      document.getElementById("organization").classList.add("active");
    } else if (path === "/program") {
      document.getElementById("program").classList.add("active");
    } else if (path.includes("/registration")) {
      document.getElementById("registration").classList.add("active");
    } else if (path.includes("/submission")) {
      document.getElementById("submission").classList.add("active");
    } else if (path === "/sponsorship") {
      document.getElementById("sponsorship").classList.add("active");
    } else if (path === "/general-information") {
      document.getElementById("general-information").classList.add("active");
    } else if (path === "/contact") {
      document.getElementById("contact").classList.add("active");
    }
  }, []);

  return (
    <Navbar
      variant="dark"
      sticky="top"
      expand="lg"
      style={{
        paddingBottom: "0",
        color: "white",
        fontWeight: "bold",
        fontFamily: "Plus Jakarta Sans",
        backgroundColor: "#1F1F1F",
      }}
    >
      <Container fluid className="justify-content-center">
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          style={{ marginBottom: "2%" }}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav fill className="col" defaultActiveKey="/home">
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/"
              id="home"
            >
              {translation(navbar_component, languages, "home")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/organization"
              id="organization"
            >
              {translation(navbar_component, languages, "organization")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/program"
              id="program"
            >
              {translation(navbar_component, languages, "program")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/registration"
              id="registration"
            >
              {translation(navbar_component, languages, "registration")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/submission"
              id="submission"
            >
              {translation(navbar_component, languages, "submission")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/sponsorship"
              id="sponsorship"
            >
              {translation(navbar_component, languages, "sponsorship")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/general-information"
              id="general-information"
            >
              {translation(navbar_component, languages, "general_information")}
            </Nav.Link>
            <Nav.Link
              className="hover-underline-animation text-white pb-3 pt-3"
              href="/contact"
              id="contact"
            >
              {translation(navbar_component, languages, "contact")}
            </Nav.Link>
            <Dropdown
              className="pb-3 pt-2"
              onSelect={handleSelect}
              style={{ fontSize: "14px" }}
            >
              <Dropdown.Toggle
                style={{
                  background: "none",
                  border: "none",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
                value="eng"
              >
                {languages === "eng" ? (
                  <>
                    <img
                      src={eng}
                      alt="english"
                      style={{ width: "2rem", display: "inline" }}
                    />{" "}
                    ENG
                  </>
                ) : (
                  <>
                    <img
                      src={indo}
                      alt="indo"
                      style={{ width: "2rem", display: "inline" }}
                    />{" "}
                    IND
                  </>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {languages === "ind" ? (
                  <Dropdown.Item
                    eventKey="1"
                    style={{ fontWeight: "bold", fontSize: "14px" }}
                    value="ind"
                  >
                    <img
                      src={eng}
                      alt="english"
                      style={{ width: "2rem", display: "inline" }}
                    />{" "}
                    ENG
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    eventKey="2"
                    style={{ fontWeight: "bold", fontSize: "14px" }}
                    value="ind"
                  >
                    <img
                      src={indo}
                      alt="indo"
                      style={{ width: "2rem", display: "inline" }}
                    />{" "}
                    IND
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
