import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap/";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../Hover.css";
import TextInput from "../../components/form/TextInput";
import { directus, url } from "../../configs/public_url";
import { home_page, translation } from "../../helpers/translation";
import { asyncSetAuthentication } from "../../reducers/auth_action";
import ModalLogin from "../form/ModalLogin";
import TextInputPassword from "../form/TextInputPassword";
import swal from "sweetalert";
import CryptoJS from "crypto-js";

export default function RegisterSubmission() {
  const { languages } = useSelector((state) => state.languages);
  const [show, setShow] = useState(false);
  const [modal, setModal] = useState(false);
  const { modalreset, setModalReset } = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  // const { data } = useSelector((state) => state.formInputs);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPwd] = useState("");
  const [dataUser, setDataUser] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const { authentication = null } = useSelector((state) => state);
  const secretPass = "PWHqwUG210103fW2t3ji1";
  const decryptData = (input) => {
    const bytes = CryptoJS.AES.decrypt(input, secretPass);
    const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return (data);
  };

  // useEffect(() => {
  //   if (authentication !== null) {
  //     navigate("/submission/submit-submission");
  //   }
  // });

  const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "0.8rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
  };
  const loginButtonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "0.8rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
    width: "100%",
  };
  const titleStyle = {
    fontWeight: "700",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  };
  const registerTextSection = {
    textAlign: "center",
    fontSize: "18px",
  };
  const registerNowText = {
    color: "#2D7B63",
    cursor: "pointer",
  };

  const resetPasswordStyle = {
    color: "#2D7B63",
    cursor: "pointer",
    fontSize: "18px",
    textDecoration: "none",
    marginTop: "-0.25rem",
  };

  let correctPassword = false;

  const handleAlertSendEmail = () => {
    swal({
      title: "Success!",
      icon: "success",
      text: "Email was send,please check for next instructions!",
      cancelButtonColor: "#999999",
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: true,
    });
  };

  const handleAlertErrorSendEmail = () => {
    swal({
      title: "Error!",
      icon: "error",
      text: "Email has been used!",
      cancelButtonColor: "#999999",
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: true,
    });
  };

  const handleChange = (e, set, error, message) => {
    let value;
    if (e) {
      value = e.target ? e.target.value : e.value;
    } else {
      value = "";
    }

    if (!value) {
      error(`Please enter your ${message}`);
    } else {
      error("");
    }

    set(value);
  };

  let [errorEmail, setErrorEmail] = useState("");
  let [errorUsername, setErrorUsername] = useState("");
  let [errorPwd, setErrorPwd] = useState("");
  let [errorConfirm, setErrorConfirmPassword] = useState("");
  const handleError = (data) => {
    let tempData = dataUser;
    for (let i = 11; i < tempData.data.length; i++)
    {
      try
      {
        tempData.data[i].password = decryptData(tempData.data[i].password);
        tempData.data[i].confirm_password = decryptData(tempData.data[i].confirm_password);
      }
      catch(err) 
      {
        console.log("Error:", err);
      }
    }
    setDataUser(tempData);
  
    const checkUsername = dataUser.data.filter(
      (z) => z.username === data.username,
    );
    const checkPassword = dataUser.data.filter(
      (z) => z.password === data.password,
    );
    const checkConfirm = dataUser.data.filter(
      (z) => z.confirm_password === data.confirm_password,
    );

    let isFieldValid = true;

    if (data.username === "") {
      setErrorUsername(
        languages === "eng"
          ? "Please enter your username"
          : "Tolong masukkan nama pengguna Anda",
      );
      isFieldValid = false;
    } else if (checkUsername.length === 0) {
      setErrorUsername(
        languages === "eng"
          ? "Username is invalid"
          : "Nama pengguna tidak valid",
      );
      isFieldValid = false;
    } else {
      isFieldValid = true;
    }

    if (data.password === "") {
      setErrorPwd(
        languages === "eng"
          ? "Please enter your password"
          : "Tolong masukkan kata sandi Anda",
      );
      isFieldValid = false;
    } else if (checkPassword.length === 0) {
      setErrorPwd(
        languages === "eng"
          ? "Password is incorrect"
          : "Kata sandi tidak benar",
      );
      isFieldValid = false;
    } else {
      isFieldValid = true;
    }

    if (data.confirm_password === "") {
      setErrorConfirmPassword(
        languages === "eng"
          ? "Please enter your confirm password"
          : "Tolong masukkan konfirmasi kata sandi Anda",
      );
      isFieldValid = false;
    } else if (data.password != data.confirm_password) {
      setErrorConfirmPassword(
        languages === "eng"
          ? "Password is not match"
          : "Kata sandi tidak cocok",
      );
      isFieldValid = false;
    } else {
      isFieldValid = true;
    }

    if (checkUsername[0].username === data.username && 
      checkUsername[0].password === data.password &&
      checkUsername[0].confirm_password === data.confirm_password) {
      correctPassword = true;
    } else {
      correctPassword = false;
    }
    setIsValid(isFieldValid);
    setIsFormValid(isFormValid && isFieldValid);
  };

  const [passwordEye, setPasswordEye] = useState(false);
  const handlePassword = () => {
    setPasswordEye(!passwordEye);
  };

  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const handleConfirm = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const [items, setItems] = useState("");
  const handleLogin = async (e) => {
    e.preventDefault();
    let payload = {
      username: username,
      password: password,
      confirm_password: confirm_password,
    };

    handleError(payload);
    if (
      payload.username === "" ||
      payload.password === "" ||
      payload.confirm_password === "" ||
      payload.confirm_password !== payload.password ||
      !correctPassword
    ) {
      return;
    }

    try {
      await dispatch(
        asyncSetAuthentication({ username, password, confirm_password }),
      );
      navigate("/submission/submit-submission");
    } catch (error) {
      alert(error);
    }
    // setIsFormValid(true);
    // dispatch(asyncSetAuthentication({ username, password, confirm_password }));
    // const timeout = setTimeout(() => {
    //   navigate("/submission/submit-submission");
    // }, 1000);

    // return () => clearTimeout(timeout);
  };

  const handleErrorEmail = (data) => {
    if (data.email === "") {
      setErrorEmail(
        languages === "eng"
          ? "Please enter your email"
          : "Tolong masukkan email Anda",
      );
    }
  };

  const handleResetPassword = async (e) => {
    const token = Math.random().toString(36).substring(2);
    let body = {
      email: email.toLowerCase(),
      reset_password_token: token,
    };
    handleErrorEmail(body);

    for (var keys in body) {
      if (body[keys] === "") {
        e.preventDefault();
        return;
      }
    }

    try {
      e.preventDefault();
      const response = await fetch(`${url}/items/forgot_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.status === 200) {
        // const { email } = body;
        handleAlertSendEmail();
      } else {
        // const error = await response.json();
        // console.error(error);
        handleAlertErrorSendEmail();
      }
    } catch (error) {
      alert("error", error.message);
    }
  };

  useEffect(() => {
    fetch(`${url}/items/submission_register`)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        setDataUser(responseJson);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    localStorage.setItem("datas", JSON.stringify(items));
  }, [items]);

  return (
    <Container
      className="my-4 px-5 mt-5"
      style={{ margin: "auto", fontFamily: "Plus Jakarta Sans" }}>
      <Row className="d-flex justify-content-around align-items-center">
        <Col sm={6}>
          <h1 style={titleStyle}>
            {translation(home_page, languages, "have_account")}
          </h1>
          <p>{translation(home_page, languages, "submit_paper")}</p>
          <Button
            onClick={() => setShow(true)}
            className="orange-button"
            style={buttonStyle}>
            {translation(home_page, languages, "submit_now")}
          </Button>
        </Col>
        <Col sm={6}>
          <h1 style={titleStyle}>
            {translation(home_page, languages, "dont_have_account")}
          </h1>
          <p>{translation(home_page, languages, "register_paper")}</p>
          <Button
            href="/submission/form-submission"
            className="orange-button"
            style={buttonStyle}>
            {translation(home_page, languages, "register_now")}
          </Button>
        </Col>
      </Row>
      <hr style={{ marginTop: "2rem" }} />
      {!resetPassword ? (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          style={{ padding: "30px" }}
          id="modalLogin">
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {languages === "ind" ? "Masuk" : "Login"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="form" onSubmit={handleLogin}>
              <TextInput
                label={languages === "ind" ? "Nama Pengguna" : "Username"}
                type="text"
                name="username"
                placeholder={
                  languages === "ind"
                    ? "Masukkan nama pengguna Anda"
                    : "Type your username"
                }
                value={username || ""}
                onChange={(e) =>
                  handleChange(e, setUsername, setErrorUsername, "username")
                }
                errorMessage={errorUsername}
              />
              <TextInputPassword
                label={languages === "ind" ? "Kata Sandi" : "Password"}
                type={passwordEye === false ? "password" : "text"}
                name="password"
                placeholder={
                  languages === "ind"
                    ? "Masukkan kata sandi Anda"
                    : "Type your password"
                }
                rightIcon={
                  <IconButton onClick={handlePassword}>
                    {passwordEye === false ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                }
                value={password || ""}
                onChange={(e) =>
                  handleChange(e, setPassword, setErrorPwd, "password")
                }
                errorMessage={errorPwd}
              />
              <TextInputPassword
                label={
                  languages === "ind"
                    ? "Konfirmasi Kata Sandi"
                    : "Confirm Password"
                }
                type={confirmPasswordEye === false ? "password" : "text"}
                name="confirm_password"
                placeholder={
                  languages === "ind"
                    ? "Masukkan konfirmasi kata sandi Anda"
                    : "Type your confirm password"
                }
                rightIcon={
                  <IconButton onClick={handleConfirm}>
                    {confirmPasswordEye === false ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                }
                value={confirm_password || ""}
                onChange={(e) =>
                  handleChange(
                    e,
                    setConfirmPwd,
                    setErrorConfirmPassword,
                    "confirm password",
                  )
                }
                errorMessage={errorConfirm}
              />
              <Button
                onClick={() => setShow(true)}
                className="orange-button"
                style={loginButtonStyle}
                type="submit"
                // navigate("/submission/submit-submission")
              >
                {translation(home_page, languages, "login")}
              </Button>
              <div style={registerTextSection}>
                <span>
                  {translation(
                    home_page,
                    languages,
                    "dont_have_account_register",
                  )}
                </span>
                <a href="/submission/form-submission" style={registerNowText}>
                  {translation(home_page, languages, "register_now")}
                </a>
              </div>
              <div style={registerTextSection}>
                <span>
                  {languages === "ind"
                    ? "Lupa Password?"
                    : "Forgotten Password?"}
                </span>
                <Button
                  style={resetPasswordStyle}
                  variant="link"
                  className="px-0"
                  onClick={() => setResetPassword(true)}>
                  {languages === "ind" ? "Klik disini!" : "Click here!"}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={resetPassword}
          onHide={() => setResetPassword(false)}
          dialogClassName="modal-90w"
          aria-labelledby="reset-password"
          style={{ padding: "30px" }}>
          <Modal.Header closeButton>
            <Modal.Title id="reset-password">
              {languages === "ind" ? "Atur Ulang Kata Sandi" : "Reset Password"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form id="form" onSubmit={handleResetPassword}>
              <TextInput
                label="Email"
                type="email"
                name="email"
                placeholder={
                  languages === "ind"
                    ? "Masukkan email Anda"
                    : "Type your email"
                }
                value={email || ""}
                onChange={(e) =>
                  handleChange(e, setEmail, setErrorEmail, "email")
                }
                errorMessage={errorEmail}
              />

              <Button
                className="orange-button"
                style={loginButtonStyle}
                type="submit"
                // navigate("/submission/submit-submission")
              >
                {languages === "ind" ? "Kirim" : "Send"}
              </Button>
              <div style={registerTextSection}>
                <span>
                  {languages === "ind"
                    ? "Apakah Anda sudah punya akun?"
                    : "Do you have account?"}
                </span>
                <Button
                  variant="link"
                  style={resetPasswordStyle}
                  onClick={() => setResetPassword(false)}
                  className="px-0">
                  {languages === "ind" ? "Masuk disini" : "Login here"}
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}
      <ModalLogin
        show={modal}
        onHide={() => {
          setModal(false);
          navigate("/submission/submit-submission");
        }}
      />
    </Container>
  );
}
