import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isListSponsorship: false,
};

const sponsorshipSlice = createSlice({
    name: 'sponsorship',
    initialState,
    reducers: {
        isListSponsorship: (state, action) => {
            state.isListSponsorship = action.payload;
        },
    },
});

export const { isListSponsorship } = sponsorshipSlice.actions;

export default sponsorshipSlice.reducer;