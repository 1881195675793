import { getCardContents } from "../reducers/cardContent_reducer";
import { createSlice } from "@reduxjs/toolkit";

const CardContentSlicer = createSlice({
  name: "cardContent",
  initialState: {
    home: [],
    cardContents: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(getCardContents.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCardContents.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.cardContents = action.payload;
    });
    builder.addCase(getCardContents.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
  reducers: undefined,
});

export default CardContentSlicer.reducer;
