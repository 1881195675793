import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  fetchWeatherInformation,
  getAssetURL,
} from "../../reducers/information/weatherInformation_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const WeatherInformation = () => {
  const { weatherInformation } = useSelector(
    (state) => state.weatherInformation
  );
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWeatherInformation());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? weatherInformation.judul_indonesia
          : weatherInformation.judul_english}
      </Typography>
      {weatherInformation?.gambar?.id && (
        <img
          src={getAssetURL(weatherInformation?.gambar?.id)}
          alt="weather"
          style={{ borderRadius: "20px", margin: "2rem auto" }}
        />
      )}
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "44px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
        }}
      >
        {languages === "ind"
          ? parse("<p>" + weatherInformation.isi_deskripsi_indonesia + "</p>")
          : parse("<p>" + weatherInformation.isi_deskripsi_english + "</p>")}
      </Typography>
      <div
        style={{
          margin: "0 auto",
          border: "1px",
          display: "flex",
          justifyContent: "center",
          alignItem: "center",
        }}
      >
        <iframe
          title="Weather"
          src="https://www.theweather.com/getwid/22d830af718302335f5e751c9a4757aa"
          allowTransparency="true"
          frameBorder="0"
          scrolling="no"
          name="flipe"
          width="177px"
          height="300px"
        ></iframe>
      </div>
    </>
  );
};

export default WeatherInformation;
