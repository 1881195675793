import { createSlice } from "@reduxjs/toolkit";
import { fetchFaq } from "../reducers/faq_reducer";

const FaqSlicer = createSlice({
  name: 'faq',
  initialState: {
    faq: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchFaq.pending, (state) => {
      state.isLoading = true;
    })
    builder.addCase(fetchFaq.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.faq = action.payload;
    })
    builder.addCase(fetchFaq.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    })
  }
})

export  default FaqSlicer.reducer;