import React, { useState } from "react";
import useFetch from "./useFetch";
import { home_page, translation } from "../../helpers/translation";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import { useEffect } from "react";
import key from "../../assets/key.json";
import { url } from "../../configs/public_url";

const Visitor = () => {
  const { languages } = useSelector((state) => state.languages);
  const [visitorCount, setVisitorCount] = React.useState("");
  const [jumlahVisitor, setJumlahVisitor] = useState(0);

  // const { data, loading, error } = useFetch(
  //   "https://api.countapi.xyz/hit/wwcindonesia2023.id/6cf0a943-6723-4498-a0c1-03e18bafc52d#",
  // );

  useEffect(() => {
    const fetchVisitorCount = async () => {
      try {
        const response = await fetch(`${url}/items/hit_visitor`);
        if (response.ok) {
          const data = await response.json();
          const id = data.data[0].id;
          const takeJumlahVisitor = data.data[0].jumlah_visitor;
          const updateAfterTake = takeJumlahVisitor + 1;
          await fetch(`${url}/items/hit_visitor/${id}`, {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              jumlah_visitor: updateAfterTake,
            }),
          })
            .then((res) => {
              return res.json();
            })
            .then((resJson) => {
              setJumlahVisitor(resJson.data.jumlah_visitor);
            })
            .catch((error) => {
              setJumlahVisitor(0);
            });
        }
      } catch (error) {
        console.error("Terjadi kesalahan:", error);
      }
    };

    fetchVisitorCount();
  }, []);

  return (
    <h1>
      {jumlahVisitor} {translation(home_page, languages, "visitor")}
    </h1>
  );
};

export default Visitor;
