import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchTransport = createAsyncThunk(
  "transport/fetchTransport",
  async () => {
    const res = await directus.items("transport").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchTransport;

export function getAssetURL(id) {
  return url + `/assets/${id}`;
}
