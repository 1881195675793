import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchIndonesianCurrency = createAsyncThunk(
  "indonesianCurrency/fetchIndonesianCurrency",
  async () => {
    const res = await directus.items("indonesian_currency").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchIndonesianCurrency;

export function getAssetURL(id) {
  return `${url}/assets/${id}`;
}
