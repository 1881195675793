import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus } from "../configs/public_url";

export const fetchContact = createAsyncThunk(
  "contact/fetchContact",
  async () => {
    const res = await directus.items("contact").readByQuery({
      fields: ["*.*", "nomer_telepon.phone_id.nomer_telepon"],
    });
    return res.data;
  }
);
