import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import fetchSynopsis from "../../reducers/synopsis_reducer";
import { Typography, Grid } from "@mui/material";
import parse from "html-react-parser";

const ProgramSynopsis = () => {
  // @ts-ignore
  const { synopsis } = useSelector((state) => state.synopsis);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSynopsis());
  }, []);

  return (
    <>
      <Grid>
        <Typography
          variant="h4"
          sx={{ color: "#FDA81A", fontWeight: "bold", marginBottom: "20px", fontFamily: "Montserrat" }}>
          {languages === "ind" ? synopsis.Judul_Indonesia : synopsis.Judul_English}
        </Typography>
        <Typography
          variant="body1"
          sx={{ textAlign: "justify", fontSize: "20px", lineHeight: "44px", mt: 3, fontFamily: "Plus Jakarta Sans, sans-serif" }}>
          {parse("<p>" + (languages === "ind" ? synopsis.isi_deskripsi_indonesia : synopsis.isi_deskripsi_english) + "</p>")}
        </Typography>
      </Grid>
    </>
  );
};

export default ProgramSynopsis;
