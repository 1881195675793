import React from 'react'

const TextInputPassword = ({
    label,
    type,
    name,
    value,
    onChange,
    placeholder,
    style,
    rightIcon,
    errorMessage
}) => {
    const labelStyle = {
        fontWeight: "bold",
        fontSize: "0.85rem",
        marginBottom: "0.5rem",
    }

    const requiredStyle = {
        color: "red",
    }

    const inputStyle = {
        width: "100%",
        height: type === "textArea" ? "5.5rem" : "3rem",
        alignItems: "start",
        padding: "1rem",
        paddingTop: type === "textArea" ? "0rem" : "1rem",
        border: "1px solid gray",
        borderRadius: "0.5rem",
        margin: "0.5rem 0",
        outline: "none",
        fontSize: "0.85rem",
        position:"relative",
    };

    const password = {
        display: "inline",   
    };

    const icon = {
        position:"absolute",
        margin: "0.7rem -42px",
    }

    const styleError = {
        fontSize:"0.60rem",
        color:"red",
    }


    return (
        <div style={style} >
            <div>
            <label htmlFor={name}
                style={labelStyle}
            ><span style={requiredStyle}>*</span>{label}</label >
            </div>
            <div style={password}>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                style={inputStyle}
                // rightIcon={rightIcon ? rightIcon : "a"}
                // required 
            />
            <span style={icon}>{ rightIcon ? rightIcon : ``}</span>
            <span style={styleError}>{errorMessage}</span>
            </div>
        </div >
    )
}

export default TextInputPassword