import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import backgroundImage from "../../assets/images/Header.png";

export default function HeaderComponent() {
  const [isHome, setIsHome] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [isTablet, setIsTablet] = React.useState(false);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setIsTablet(false);
    } else if (window.innerWidth < 1024) {
      setIsMobile(false);
      setIsTablet(true);
    } else {
      setIsMobile(false);
      setIsTablet(false);
    }
  };

  React.useEffect(() => {
    if (window.location.pathname === "/") {
      setIsHome(true);
    } else {
      setIsHome(false);
    }

    handleResize();
    document.addEventListener("scroll", () => {
      handleResize();
    });
    return () => {
      document.removeEventListener("scroll", () => {
        handleResize();
      });
    };
  }, []);

  const imgHeader = {
    background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(253,168,26,0.4) 0%, rgba(0,0,0,1) 100%), url(${backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: isHome ? "80vh" : "40vh",
    margin: "0",
    paddingLeft: "10%",
    paddingRight: "10%",
  };

  const captionLeft = {
    color: "#FDA81A",
    filter: "drop-shadow(0 0.2rem 0.1rem rgba(253, 168, 26, 0.4))",
    // paddingLeft: "10%",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "2.5rem",
    fontWeight: "bold",
    minWidth: isMobile ? "100%" : isTablet ? "100%" : "",
  };

  const captionRight = {
    color: "white",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "1.3rem",
    minWidth: isMobile ? "100%" : isTablet ? "100%" : "",
  };

  return (
    <Container
      className="row justify-content-center align-items-end pb-5"
      style={imgHeader}
    >
      <Col sm={8} style={captionLeft} className="mb-4">
        <h1
          style={{ fontSize: isMobile ? "3.5rem" : isHome ? "5rem" : "2.7rem" }}
        >
          7th
        </h1>
        <h4
          style={{ fontSize: isMobile ? "2.5rem" : isHome ? "3rem" : "2.7rem" }}
          className="text-nowrap"
        >
          World Waterfowl
        </h4>
        <h4
          style={{ fontSize: isMobile ? "2.5rem" : isHome ? "3rem" : "2.7rem" }}
          className="text-nowrap"
        >
          Conference 2023
        </h4>
      </Col>
      <Col
        sm={4}
        className="row justify-content-end align-items-center mb-4"
        style={captionRight}
      >
        <h1 className="d-flex justify-content-end">
          <b
            className="text-nowrap"
            style={{
              fontSize: isMobile ? "1.5rem" : isHome ? "2rem" : "1.5rem",
            }}
          >
            20 - 22 September 2023
          </b>
        </h1>
        <p
          className="d-flex justify-content-end text-nowrap"
          style={{ fontSize: isMobile ? "1.5rem" : isHome ? "2rem" : "1.5rem" }}
        >
          ICE BSD, Indonesia
        </p>
      </Col>
    </Container>
  );
}
