import { Typography } from "@mui/material";
import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function Redaction() {
  const { languages } = useSelector((state) => state.languages);
  const buttonStyle = {
    fontWeight: "bold",
    backgroundColor: "#FDA81A",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    fontSize: "20px",
    padding: "1.4rem 1.5rem",
    fontFamily: "Plus Jakarta Sans",
    transform: "translateY(2rem)",
  };
  return (
    <div
      className="my-4"
      style={{ margin: "auto", fontFamily: "Plus Jakarta Sans" }}>
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "40px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
          fontWeight: "500",
        }}>
        {languages === "ind"
          ? `KLARIFIKASI UNTUK PENDAFTARAN: peserta yang berhak atas biaya pendaftaran ‘berpenghasilan rendah’ WWC 2023 adalah mereka yang berasal dari negara yang dapat ditemukan dalam daftar bank dunia untuk kelompok ‘berpenghasilan rendah’ dan ‘berpenghasilan menengah ke bawah’. Bank dunia menggunakan GNI per kapita dengan menggunakan`
          : `CLARIFICATION for REGISTRATION: participants eligible to the ‘low
        income’ registration fee of WWC 2023 are those coming from a country
        which can be found in the list of the world bank for ‘Low income’ group
        and ‘lower middle income’ group. The world bank uses the GNI per capita
        using the`}{" "}
        <a
          href="https://datahelpdesk.worldbank.org/knowledgebase/articles/378832-what-is-the-world-bank-atlas-method"
          style={{ color: "black", fontWeight: "bold" }}>
          {languages === "ind"
            ? "Metode World Bank Atlas."
            : "World Bank Atlas method."}
        </a>
      </Typography>
      <br />
      <Typography
        variant="body1"
        sx={{
          textAlign: "justify",
          fontSize: "20px",
          lineHeight: "40px",
          mt: 3,
          fontFamily: "Plus Jakarta Sans, sans-serif",
          fontWeight: "500",
        }}>
        {languages === "ind"
          ? "Daftar terperinci telah diambil dari"
          : "The detailed list has been extracted from"}{" "}
        <a
          href="https://datahelpdesk.worldbank.org/knowledgebase/articles/906519"
          style={{ color: "black", fontWeight: "bold" }}>
          {languages === "ind"
            ? "Bank Dunia di sini."
            : "World Bank just here."}
        </a>
      </Typography>
      <Row className="d-flex justify-content-center align-items-center">
        <Col sm={4}>
          <Button className="orange-button" style={buttonStyle}>
            <a
              style={{ color: "black", fontWeight: "bold" }}
              href="https://wpsaindonesia.id/membership/register">
              {languages === "ind"
                ? "Gabung WPSA Indonesia 2022"
                : "Join WPSA Indonesia 2022"}
            </a>
          </Button>
        </Col>
        <Col
          sm={4}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: "3rem",
          }}>
          <Button className="orange-button" style={buttonStyle}>
            <a
              style={{ color: "black", fontWeight: "bold" }}
              href="/registration/world-bank">
              {languages === "ind"
                ? "Daftar Ekonomi Bank Dunia"
                : "World Bank List of Economies"}
            </a>
          </Button>
        </Col>
      </Row>
    </div>
  );
}
