import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Visitor from "./Visitor";
import { useSelector } from "react-redux";
import { home_page, translation } from "../../helpers/translation";

export default function Countdown() {
  const { languages } = useSelector((state) => state.languages);
  const calculateTimeLeft = () => {
    // let year = new Date().getFullYear();
    const difference = +new Date(`2023-9-20`) - +new Date();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        interval: Math.floor(difference / (1000 * 60 * 60 * 24)),
        // hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        // minutes: Math.floor((difference / 1000 / 60) % 60),
        // seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  //   const [year] = useState(new Date().getFullYear());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  const timerComponents = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval]) {
      return;
    }

    timerComponents.push(
      <span>
        {timeLeft[interval]}
      </span>
    );
  });

  const widgetStyles = {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "1.5rem",
    fontWeight: "600",
    backgroundColor: "rgba(253, 168, 26, 0.08)",
    color: "#000000",
    borderColor: "#FDA81A",
    margin: "1rem 0",
    padding: "1rem 1rem",
    borderRadius: "20px",
  };

  const iconStyles = {
    borderRadius: "50%",
    backgroundColor: "#FDA81A",
    width: "5rem",
    height: "5rem",
    margin: "0 1rem",
  };
  return (
    <Container
      className="my-4 px-5"
      style={{ margin: "auto", textTransform: "capitalize", textAlign: "left" }}
    >
      <Row className="d-flex justify-content-around align-items-center">
        <Col
          className="d-flex justify-content-center align-items-center"
          style={widgetStyles}
          sm={5}
        >
          <img
            src={require("../../assets/images/clock.png")}
            alt="clock"
            style={iconStyles}
          />
          <h1>
            {timerComponents.length &&
              timerComponents}
            {" "}
            {timerComponents.length ? translation(home_page, languages, "countdown") :
              <span>D - Day!</span>}
          </h1>
        </Col>
        <Col
          className="d-flex justify-content-center align-items-center"
          style={widgetStyles}
          sm={5}
        >
          <img
            src={require("../../assets/images/group.jpg")}
            alt="clock"
            style={iconStyles}
          />
          <Visitor />
        </Col>
      </Row>
      <hr style={{ marginTop: "2rem" }} />
    </Container>
  );
}
