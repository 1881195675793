import { getMember } from "../reducers/member_reducer";
import { createSlice } from "@reduxjs/toolkit";

const MemberSlicer = createSlice({
    name: "member",
    initialState: {
        committee: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(getMember.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(getMember.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.committee = action.payload;
        });
        builder.addCase(getMember.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        });
    }
});

export default MemberSlicer.reducer;