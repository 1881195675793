import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useSelector } from "react-redux";
import { registration_form, translation } from "../../helpers/translation";

const FieldsetTotalPayment = () => {
  const Styles = {
    labelStyle: {
      fontWeight: "900",
      fontSize: "0.85rem",
    },
    requiredStyle: {
      color: "red",
    },
    inputGroup: {
      display: "flex",
      width: "100%",
      border: "1px solid #919191",
      borderRadius: "0.4rem",
      backgroundColor: "#cfcfcf",
    },
  };
  const [totalPayment, setTotalPayment] = React.useState("");
  const [totalPaymentTour, setTotalPaymentTour] = React.useState("");

  const { data } = useSelector((state) => state.formInputs);
  const { languages } = useSelector((state) => state.languages);

  React.useEffect(() => {
    setTotalPayment(data.total_payment_conference);
    setTotalPaymentTour(data.total_payment_tour_field_trip);
  }, [data]);

  return (
    <fieldset
      style={{
        gridColumn: "span 6",
        gridRow: "8",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <label htmlFor="TotalPayment" style={Styles.labelStyle}>
        <span style={Styles.requiredStyle}>*</span>
        {translation(registration_form, languages, "total_payment_conference")}
      </label>
      <InputGroup style={Styles.inputGroup}>
        <InputGroup.Text
          style={{
            backgroundColor: "#cfcfcf",
            color: "#717171",
            padding: "0.5rem 2rem",
            fontWeight: "bold",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          {data.type_of_registration.includes("Indonesian") ? "IDR" : "USD"}
        </InputGroup.Text>
        <Form.Control
          aria-label="Your Total Payment"
          placeholder="Your Total Payment"
          style={{
            height: "3rem",
            backgroundColor: "#e9e9e9",
            color: "#474747",
            fontWeight: "bold",
            fontFamily: "Plus Jakarta Sans",
          }}
          type="number"
          disabled={true}
          name="totalPayment"
          value={totalPayment}
        />
      </InputGroup>

      <label htmlFor="TotalPayment" style={Styles.labelStyle}>
        <span style={Styles.requiredStyle}>*</span>
        {translation(registration_form, languages, "total_payment_tour")}
      </label>
      <InputGroup style={Styles.inputGroup}>
        <InputGroup.Text
          style={{
            backgroundColor: "#cfcfcf",
            color: "#717171",
            padding: "0.5rem 2rem",
            fontWeight: "bold",
            fontFamily: "Plus Jakarta Sans",
          }}
        >
          USD
        </InputGroup.Text>
        <Form.Control
          aria-label="Your Total Payment"
          placeholder="Your Total Payment"
          style={{
            height: "3rem",
            backgroundColor: "#e9e9e9",
            color: "#474747",
            fontWeight: "bold",
            fontFamily: "Plus Jakarta Sans",
          }}
          type="number"
          disabled={true}
          name="totalPayment"
          value={totalPaymentTour}
        />
      </InputGroup>
    </fieldset>
  );
};

export default FieldsetTotalPayment;
