import { Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import { registration_form, translation } from "../../helpers/translation";

const PaymentTransfer = () => {
  const { languages } = useSelector((state) => state.languages);
  const styles = {
    card: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "30px 40px",
      gap: "30px",
      background: "#EFF5FF",
      border: "1px solid #075DDE",
      borderRadius: "20px",
      marginTop: "1rem",
    },
    title: {
      fontFamily: "Kumbh Sans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "center",
      color: "#000000",
    },
    columns: {
      fontWeight: 700,
      fontSize: "16px",
      lineHeight: "20px",
    },
    paymentDiv: {
      display: "grid",
      gap: "10px",
    },
  };
  const paymentData = [
    {
      column: "Beneficiary Name",
      data: "PT. KHARISMA SATWA MEDIA",
    },
    {
      column: "Beneficiary Address",
      data: "Komplek Mangga Dua Square Blok E. No. 23. Jl. Gunung Sahari Raya No.1, Jakarta Utara - 14430",
    },
    {
      column: "Bank Name",
      data: "Bank Central Asia ( BCA )",
    },
    {
      column: "Bank Account No.",
      data: "5910 2399 98",
    },
    {
      column: "SWIFT Code",
      data: "CENAIDJA",
    },
    {
      column: "Branch",
      data: "KCP Mangga Dua Square",
    },
    {
      column: "Bank Address",
      data: `Rukan Mangga Dua Square Blok G No 8-9, 
      Jl. Gunung Sahari Raya No.1, 
      Jakarta Utara - 14430`,
    },
  ];
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <div style={styles.card}>
      <Typography variant="h1" style={styles.title}>
        {translation(registration_form, languages, "title_payment")}
      </Typography>
      <div style={styles.paymentDiv}>
        {paymentData.map((item) => (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <p style={styles.columns}>{item.column}</p>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <p>: {item.data}</p>
            </Grid>
          </Grid>
        ))}
      </div>
    </div>
  );
};

export default PaymentTransfer;
