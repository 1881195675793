import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import fetchAccomodation from "../../reducers/information/accomodation_reducer";
import {
  fetchAccomodationList,
  getAssetURL,
} from "../../reducers/information/accomodationList_reducer";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";

const Accomodation = () => {
  const { accomodation } = useSelector((state) => state.accomodation);
  const { accomodationList } = useSelector((state) => state.accomodationList);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAccomodation());
    dispatch(fetchAccomodationList());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "800", fontFamily: "Montserrat" }}
      >
        {languages === "ind"
          ? accomodation.judul_indonesia
          : accomodation.judul_english}
      </Typography>

      {accomodationList.map((accomodationList, index) => (
        <div key={index}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "700", fontFamily: "Montserrat", mt: 5 }}
          >
            {parse(
              "<p>" +
              (accomodationList.id +
                ". " +
                accomodationList.nama_hotel +
                " " +
                (accomodationList.bintang_hotel === 1
                  ? "⭐"
                  : accomodationList.bintang_hotel === 2
                    ? "⭐⭐"
                    : accomodationList.bintang_hotel === 3
                      ? "⭐⭐⭐"
                      : accomodationList.bintang_hotel === 4
                        ? "⭐⭐⭐⭐"
                        : accomodationList.bintang_hotel === 5
                          ? "⭐⭐⭐⭐⭐"
                          : "")) +
              "</p>"
            )}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: "Plus Jakarta Sans", fontSize: "18px", mt: 2 }}
          >
            {languages === "ind"
              ? parse("<p>" + accomodationList.deskripsi_hotel_indonesia + "</p>")
              : parse("<p>" + accomodationList.deskripsi_hotel_english + "</p>")}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "justify",
              fontSize: "20px",
              lineHeight: "44px",
              mt: 3,
              fontFamily: "Plus Jakarta Sans, sans-serif",
            }}
          >
            <Row>
              <Col sm={1}>
                <img
                  src={require("../../assets/images/location.png")}
                  alt="location"
                  style={{
                    display: "inline",
                    marginRight: "1.5rem",
                    verticalAlign: "middle",
                  }}
                />
              </Col>
              <Col
                sm={11}
                style={{
                  fontSize: "18px",
                  textAlign: "left",
                  lineHeight: "27px",
                  verticalAlign: "middle",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {accomodationList.lokasi_hotel}
              </Col>
            </Row>
          </Typography>
          {accomodationList?.gambar_hotel?.id && <img
            src={getAssetURL(accomodationList?.gambar_hotel?.id)}
            alt="hotel"
            style={{ borderRadius: "20px", margin: "2rem auto" }}
          />}
        </div>
      ))}
    </>
  );
};

export default Accomodation;
