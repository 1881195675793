const ListWorldBank = [
  { id: 79, name: "Andorra (AND)", income: "High Income", image: "andorra" },
  {
    id: 80,
    name: "Antigua and Barbuda (ATG)",
    income: "High Income",
    image: "antigua",
  },
  { id: 81, name: "Aruba (ABW)", income: "High Income", image: "aruba" },
  {
    id: 82,
    name: "Australia (ASU)",
    income: "High Income",
    image: "australia",
  },
  { id: 83, name: "Austria (AUT)", income: "High Income", image: "austria" },
  {
    id: 84,
    name: "Bahamas (The BHS)",
    income: "High Income",
    image: "bahamas",
  },
  { id: 85, name: "Bahrain (BHR)", income: "High Income", image: "bahrain" },
  { id: 86, name: "Barbados (BB)", income: "High Income", image: "barbados" },
  { id: 87, name: "Belgium (BEL)", income: "High Income", image: "belgium" },
  { id: 88, name: "Bermuda (BMU)", income: "High Income", image: "bermuda" },
  {
    id: 89,
    name: "British Virgin Islands (VGB)",
    income: "High Income",
    image: "british-virgin",
  },
  {
    id: 90,
    name: "Brunei Darussalam (BRN)",
    income: "High Income",
    image: "brunei",
  },
  { id: 91, name: "Canada (CAN)", income: "High Income", image: "canada" },
  {
    id: 92,
    name: "Cayman Islands (CYM)",
    income: "High Income",
    image: "cayman-islands",
  },
  {
    id: 93,
    name: "Channel Islands (GB)",
    income: "High Income",
    image: "channel",
  },
  { id: 94, name: "Chile (CHL)", income: "High Income", image: "chile" },
  { id: 95, name: "Croatia (HRV)", income: "High Income", image: "croatia" },
  { id: 96, name: "Curacao (CW)", income: "High Income", image: "curacao" },
  { id: 97, name: "Cyprus (CYP)", income: "High Income", image: "cyprus" },
  { id: 98, name: "Czechia (CZE)", income: "High Income", image: "czechia" },
  { id: 99, name: "Denmark (DNK)", income: "High Income", image: "denmark" },
  { id: 100, name: "Estonia (EST)", income: "High Income", image: "estonia" },
  {
    id: 101,
    name: "Faroe Islands (FRO)",
    income: "High Income",
    image: "faroe-islands",
  },
  { id: 102, name: "Finland (FIN)", income: "High Income", image: "finland" },

  { id: 103, name: "France (FRA)", income: "High Income", image: "france" },
  {
    id: 104,
    name: "French Polynesia (PYF)",
    income: "High Income",
    image: "french-polynesia",
  },
  { id: 105, name: "Germany (DEU)", income: "High Income", image: "germany" },
  {
    id: 106,
    name: "Gibraltar (GIB)",
    income: "High Income",
    image: "gibraltar",
  },

  { id: 107, name: "Greece (GRC)", income: "High Income", image: "greece" },
  {
    id: 108,
    name: "Greenland (GRL)",
    income: "High Income",
    image: "greenland",
  },

  { id: 109, name: "Guam (GUM)", income: "High Income", image: "guam" },
  {
    id: 110,
    name: "Hong Kong Sar China (HKG)",
    income: "High Income",
    image: "hong-kong-sar-china",
  },
  { id: 111, name: "Hungary (HUN)", income: "High Income", image: "hungary" },
  { id: 112, name: "Iceland (ISL)", income: "High Income", image: "iceland" },
  { id: 113, name: "Ireland (IRL)", income: "High Income", image: "ireland" },
  {
    id: 114,
    name: "Isle of Man (IMN)",
    income: "High Income",
    image: "isle-of-man",
  },

  { id: 115, name: "Israel (ISR)", income: "High Income", image: "israel" },
  { id: 116, name: "Italy (ITA)", income: "High Income", image: "italy" },

  { id: 117, name: "Japan (JPN)", income: "High Income", image: "japan" },
  {
    id: 118,
    name: "Korea Rep (KOR)",
    income: "High Income",
    image: "south-korea",
  },
  { id: 119, name: "Kuwait (KWT)", income: "High Income", image: "kuwait" },
  { id: 120, name: "Latvia (LVA)", income: "High Income", image: "latvia" },
  {
    id: 121,
    name: "Liechtenstein (LIE)",
    income: "High Income",
    image: "liechtenstein",
  },
  {
    id: 122,
    name: "Lithuania (LTU)",
    income: "High Income",
    image: "lithuania",
  },
  {
    id: 123,
    name: "Luxembourg (LUX)",
    income: "High Income",
    image: "luxembourg",
  },
  {
    id: 124,
    name: "Macao Sar China  (MAC)",
    income: "High Income",
    image: "macao-sar-china",
  },
  { id: 125, name: "Malta (MLT)", income: "High Income", image: "malta" },
  { id: 126, name: "Monaco (MCO)", income: "High Income", image: "monaco" },
  { id: 127, name: "Nauru (NRU)", income: "High Income", image: "nauru" },
  {
    id: 128,
    name: "Netherlands (NLD)",
    income: "High Income",
    image: "netherlands",
  },
  {
    id: 129,
    name: "New Caledonia (NCL)",
    income: "High Income",
    image: "new-caledonia",
  },
  {
    id: 130,
    name: "New Zealand (NZL)",
    income: "High Income",
    image: "new-zealand",
  },
  {
    id: 131,
    name: "Northern Mariana Islands (MNP)",
    income: "High Income",
    image: "northern-mariana-islands",
  },
  {
    id: 132,
    name: "Norway (NOR)",
    income: "High Income",
    image: "norway",
  },
  { id: 133, name: "Oman (OMN)", income: "High Income", image: "oman" },
  {
    id: 134,
    name: "Panama (PAN)",
    income: "High Income",
    image: "panama",
  },
  { id: 135, name: "Poland (POL)", income: "High Income", image: "poland" },
  {
    id: 136,
    name: "Portugal (PRT)",
    income: "High Income",
    image: "portugal",
  },
  {
    id: 137,
    name: "Puerto Rico (PRI)",
    income: "High Income",
    image: "puerto-rico",
  },
  {
    id: 138,
    name: "Qatar (QAT)",
    income: "High Income",
    image: "qatar",
  },
  { id: 139, name: "Romania (ROU)", income: "High Income", image: "romania" },
  {
    id: 140,
    name: "San Marino (SMR)",
    income: "High Income",
    image: "san-marino",
  },
  {
    id: 141,
    name: "Saudi Arabia (SAU)",
    income: "High Income",
    image: "saudi-arabia",
  },
  {
    id: 142,
    name: "Seychelles (SYC)",
    income: "High Income",
    image: "seychelles",
  },
  {
    id: 143,
    name: "Singapore (SGP)",
    income: "High Income",
    image: "singapore",
  },
  {
    id: 144,
    name: "Sint Maarten (Dutch Part)",
    income: "High Income",
    image: "sint-maarten",
  },
  {
    id: 145,
    name: "Slovak Republic (SVK)",
    income: "High Income",
    image: "slovakia",
  },
  {
    id: 146,
    name: "Slovenia (SVN)",
    income: "High Income",
    image: "slovenia",
  },
  { id: 147, name: "Spain (ESP)", income: "High Income", image: "spain" },
  {
    id: 148,
    name: "ST. Kitts And Nevis (KNA)",
    income: "High Income",
    image: "st-kitts-and-nevis",
  },
  {
    id: 149,
    name: "ST. Martin (French Part) (MAF)",
    income: "High Income",
    image: "st-martin",
  },
  {
    id: 150,
    name: "Sweden (SWE)",
    income: "High Income",
    image: "sweden",
  },
  {
    id: 151,
    name: "Switzerland (CHE)",
    income: "High Income",
    image: "switzerland",
  },
  {
    id: 152,
    name: "Trinidad And Tobago (TTO)",
    income: "High Income",
    image: "trinidad-and-tobago",
  },
  {
    id: 153,
    name: "Turks And Caicos Islands (TCA)",
    income: "High Income",
    image: "turks-and-caicos-islands",
  },
  {
    id: 154,
    name: "United Arab Emirate (UEA)",
    income: "High Income",
    image: "united-arab-emirates",
  },
  {
    id: 155,
    name: "United Kingdom (GBR)",
    income: "High Income",
    image: "united-kingdom",
  },
  {
    id: 156,
    name: "United States (USA)",
    income: "High Income",
    image: "united-states",
  },

  { id: 157, name: "Uruguay (URY)", income: "High Income", image: "uruguay" },
  {
    id: 158,
    name: "Virgin Island (U.S) (VIR)",
    income: "High Income",
    image: "british-virgin-islands",
  },

  {
    id: 1,
    name: "Angola (AGO)",
    income: "Lower Middle Income",
    image: "angola",
  },
  {
    id: 2,
    name: "Bangladesh (BGD)",
    income: "Lower Middle Income",
    image: "bangladesh",
  },
  {
    id: 3,
    name: "Bhutan (BTN)",
    income: "Lower Middle Income",
    image: "bhutan",
  },
  {
    id: 4,
    name: "Bolovia (BOL)",
    income: "Lower Middle Income",
    image: "bolovia",
  },
  {
    id: 5,
    name: "Cabo Verde (CPV)",
    income: "Lower Middle Income",
    image: "cabo",
  },
  {
    id: 6,
    name: "Cambodia (KHM)",
    income: "Lower Middle Income",
    image: "cambodia",
  },
  {
    id: 7,
    name: "Cameroon (CMR)",
    income: "Lower Middle Income",
    image: "cameroon",
  },
  {
    id: 8,
    name: "Comoros (COM)",
    income: "Lower Middle Income",
    image: "comoros",
  },
  {
    id: 9,
    name: "Congo, Rep. (COG)",
    income: "Lower Middle Income",
    image: "congo",
  },
  {
    id: 10,
    name: "Cote d’Ivoire (CIV)",
    income: "Lower Middle Income",
    image: "cote",
  },
  {
    id: 11,
    name: "Djibouti (DJI)",
    income: "Lower Middle Income",
    image: "djibouti",
  },
  {
    id: 12,
    name: "Egypt, Arab Rep. (EGY)",
    income: "Lower Middle Income",
    image: "egypt",
  },
  {
    id: 13,
    name: "El Salvador (SLV)",
    income: "Lower Middle Income",
    image: "salvador",
  },
  {
    id: 14,
    name: "Eswatini (SWZ)",
    income: "Lower Middle Income",
    image: "eswatini",
  },
  {
    id: 15,
    name: "Ghana (GHA)",
    income: "Lower Middle Income",
    image: "ghana",
  },
  {
    id: 16,
    name: "Honduras (HND)",
    income: "Lower Middle Income",
    image: "honduras",
  },
  {
    id: 17,
    name: "India (IND)",
    income: "Lower Middle Income",
    image: "india",
  },
  {
    id: 18,
    name: "Indonesia (IDN)",
    income: "Lower Middle Income",
    image: "indonesia",
  },
  {
    id: 19,
    name: "Kenya (KEN)",
    income: "Lower Middle Income",
    image: "kenya",
  },
  {
    id: 20,
    name: "Kiribati (KIR)",
    income: "Lower Middle Income",
    image: "kiribati",
  },
  {
    id: 21,
    name: "Kyrgyz Republic (KGZ)",
    income: "Lower Middle Income",
    image: "kyrgyz",
  },
  {
    id: 22,
    name: "Lao PDR (LAO)",
    income: "Lower Middle Income",
    image: "laos",
  },
  {
    id: 23,
    name: "Lesotho (LSO)",
    income: "Lower Middle Income",
    image: "lesotho",
  },
  {
    id: 24,
    name: "Mauritania (MRT)",
    income: "Lower Middle Income",
    image: "mauritania",
  },
  {
    id: 25,
    name: "Micronesia, Fed. Sts. (FSM)",
    income: "Lower Middle Income",
    image: "micronesia",
  },
  {
    id: 26,
    name: "Moldova (MDA)",
    income: "Lower Middle Income",
    image: "moldova",
  },
  {
    id: 27,
    name: "Mongolia (MNG)",
    income: "Lower Middle Income",
    image: "mongolia",
  },
  {
    id: 28,
    name: "Morocco (MAR)",
    income: "Lower Middle Income",
    image: "morocco",
  },
  {
    id: 29,
    name: "Myanmar (MMR)",
    income: "Lower Middle Income",
    image: "myanmar",
  },
  {
    id: 30,
    name: "Nicaragua (NIC)",
    income: "Lower Middle Income",
    image: "nicaragua",
  },
  {
    id: 31,
    name: "Nigeria (NGA)",
    income: "Lower Middle Income",
    image: "nigeria",
  },
  {
    id: 32,
    name: "Pakistan (PAK)",
    income: "Lower Middle Income",
    image: "pakistan",
  },
  {
    id: 33,
    name: "Papua New Guinea (PNG)",
    income: "Lower Middle Income",
    image: "papua",
  },
  {
    id: 34,
    name: "Philippines (PHL)",
    income: "Lower Middle Income",
    image: "philippines",
  },
  {
    id: 35,
    name: "Sao Tome and Principe (STP)",
    income: "Lower Middle Income",
    image: "sao",
  },
  {
    id: 36,
    name: "Senegal (SEN)",
    income: "Lower Middle Income",
    image: "senegal",
  },
  {
    id: 37,
    name: "Solomon Islands (SLB)",
    income: "Lower Middle Income",
    image: "solomon",
  },
  {
    id: 38,
    name: "Sudan (SDN)",
    income: "Lower Middle Income",
    image: "sudan",
  },
  {
    id: 39,
    name: "Timor-Leste (TLS)",
    income: "Lower Middle Income",
    image: "timor",
  },
  {
    id: 40,
    name: "Tunisia (TUN)",
    income: "Lower Middle Income",
    image: "tunisia",
  },
  {
    id: 41,
    name: "Ukraine (UKR)",
    income: "Lower Middle Income",
    image: "ukraine",
  },
  {
    id: 42,
    name: "Uzbekistan (UZB)",
    income: "Lower Middle Income",
    image: "uzbekistan",
  },
  {
    id: 43,
    name: "Vanuatu (VUT)",
    income: "Lower Middle Income",
    image: "vanuatu",
  },
  {
    id: 44,
    name: "Vietnam (VNM)",
    income: "Lower Middle Income",
    image: "vietnam",
  },
  {
    id: 45,
    name: "West Bank and Gaza (PSE)",
    income: "Lower Middle Income",
    image: "gaza",
  },
  {
    id: 46,
    name: "Zambia (ZMB)",
    income: "Lower Middle Income",
    image: "zambia",
  },
  {
    id: 47,
    name: "Zimbabwe (ZWE)",
    income: "Lower Middle Income",
    image: "zimbabwe",
  },
  {
    id: 48,
    name: "Afghanistan (AFG)",
    income: "Lower Income",
    image: "afghanistan",
  },
  { id: 49, name: "Benin (BEN)", income: "Lower Income", image: "benin" },
  {
    id: 50,
    name: "Burkina Faso (BFA)",
    income: "Lower Income",
    image: "burkina",
  },
  { id: 51, name: "Burundi (BDI)", income: "Lower Income", image: "burundi" },
  {
    id: 52,
    name: "Central African Republic (CAF)",
    income: "Lower Income",
    image: "african",
  },
  { id: 53, name: "Chad (TCD)", income: "Lower Income", image: "chad" },
  {
    id: 54,
    name: "Congo, Dem. Rep. (COD)",
    income: "Lower Income",
    image: "cod",
  },
  { id: 55, name: "Eritrea (ERI)", income: "Lower Income", image: "eritrea" },
  { id: 56, name: "Ethiopia (ETH)", income: "Lower Income", image: "ethiopia" },
  {
    id: 57,
    name: "Gambia, The (GMB)",
    income: "Lower Income",
    image: "gambia",
  },
  { id: 58, name: "Guinea (GIN)", income: "Lower Income", image: "guinea" },
  {
    id: 59,
    name: "Guinea-Bissau (GNB)",
    income: "Lower Income",
    image: "bissau",
  },
  { id: 60, name: "Haiti (HTI)", income: "Lower Income", image: "haiti" },
  {
    id: 61,
    name: "Korea, Dem. People’s Rep. (PRK)",
    income: "Lower Income",
    image: "korea",
  },
  { id: 62, name: "Liberia (LBR)", income: "Lower Income", image: "liberia" },
  {
    id: 63,
    name: "Madagascar (MDG)",
    income: "Lower Income",
    image: "madagascar",
  },
  {
    id: 64,
    name: "Mozambique (MOZ)",
    income: "Lower Income",
    image: "mozambique",
  },
  { id: 65, name: "Malawi (MWI)", income: "Lower Income", image: "malawi" },
  { id: 66, name: "Mali (MLI)", income: "Lower Income", image: "mali" },
  { id: 67, name: "Nepal (NPL)", income: "Lower Income", image: "nepal" },
  { id: 68, name: "Niger (NER)", income: "Lower Income", image: "niger" },
  { id: 69, name: "Rwanda (RWA)", income: "Lower Income", image: "rwanda" },
  {
    id: 70,
    name: "Sierra Leone (SLE)",
    income: "Lower Income",
    image: "sierra",
  },
  { id: 71, name: "Somalia (SOM)", income: "Lower Income", image: "somalia" },
  { id: 72, name: "South Sudan (SSD)", income: "Lower Income", image: "south" },
  {
    id: 73,
    name: "Syria Arab Republic (SYR)",
    income: "Lower Income",
    image: "syiria",
  },
  {
    id: 74,
    name: "Tajikistan (TJK)",
    income: "Lower Income",
    image: "tajikistan",
  },
  { id: 75, name: "Tanzaia (TZA)", income: "Lower Income", image: "tanzania" },
  { id: 76, name: "Togo (TGO)", income: "Lower Income", image: "togo" },
  { id: 77, name: "Uganda (UGA)", income: "Lower Income", image: "uganda" },
  { id: 78, name: "Yemen, Rep. (YEM)", income: "Lower Income", image: "yemen" },
];

export default ListWorldBank;
