import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    data: {
        first_name: '',
        last_name: '',
        job_title: '',
        institution_affiliation: '',
        email: '',
        phone_number: '',
        address: '',
        city: '',
        postal_code: '',
        country: '',
        first_name2: '',
        last_name2: '',
        job_title2: '',
        institution_affiliation2: '',
        email2: '',
        phone_number2: '',
        address2: '',
        city2: '',
        postal_code2: '',
        country2: '',
        first_name3: '',
        last_name3: '',
        job_title3: '',
        institution_affiliation3: '',
        email3: '',
        phone_number3: '',
        address3: '',
        city3: '',
        postal_code3: '',
        country3: '',
        membership_type: '',
        membership_id: '',
        scientific_forum: '',
        business_forum: '',
        tour_field_trip_type: '',
        tour_field_trip_persons: 0,
        type_of_registration: '',
        total_payment_conference: 0,
        total_payment_tour_field_trip: 0,
    },
    isLoading: false,
    error: null,
};

const formInputsSlice = createSlice({
    name: 'formInputs',
    initialState,
    reducers: {
        fetchInputs: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        fetchMembership: (state, action) => {
            state.data = {
                ...state.data,
                membership_type: action.payload,
            };
        },
        fetchMembershipId: (state, action) => {
            state.data = {
                ...state.data,
                membership_id: action.payload,
            };
        },
        fetchParticipatingIn: (state, action) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        fetchTypeOfRegistration: (state, action) => {
            state.data = {
                ...state.data,
                type_of_registration: action.payload,
            };
            if (action.payload === 'Member WPSA - High income country') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 400 : Date.now() > 1675184400 ? 300 : 250,
                };
            } else if (action.payload === 'Member WPSA - Low-middle income country') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 250 : Date.now() > 1675184400 ? 200 : 150,
                };
            } else if (action.payload === 'Member WPSA - Students') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 125 : Date.now() > 1675184400 ? 100 : 75,
                };
            } else if (action.payload === 'Member WPSA - Indonesian') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 1500000 : Date.now() > 1675184400 ? 1000000 : 750000,
                };
            } else if (action.payload === 'Non-Member WPSA - Students') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 150 : Date.now() > 1675184400 ? 125 : 100,
                };
            } else if (action.payload === 'Non-Member WPSA - Indonesian') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 2000000 : Date.now() > 1675184400 ? 1500000 : 1000000,
                };
            } else if (action.payload === 'Non-Member WPSA - Low-middle income country') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 300 : Date.now() > 1675184400 ? 250 : 200,
                };
            } else if (action.payload === 'Non-Member WPSA - High income country') {
                state.data = {
                    ...state.data,
                    total_payment_conference: Date.now() > 1688144400 ? 450 : Date.now() > 1675184400 ? 350 : 300,
                };
            }
        },
        fetchTotalPaymentConference: (state, action) => {
            state.data = {
                ...state.data,
                total_payment_conference: action.payload,
            };
        },
        fetchTotalPaymentTour: (state, action) => {
            state.data = {
                ...state.data,
                total_payment_tour_field_trip: action.payload,
            };
        },
    },
});

export const { fetchInputs, fetchMembership, fetchParticipatingIn, fetchTypeOfRegistration, fetchTotalPaymentTour, fetchMembershipId, fetchTotalPaymentConference } = formInputsSlice.actions;

export default formInputsSlice.reducer;
