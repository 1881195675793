import React from "react";
import SubmitSubmission from "../../components/content/SubmitSubmission";
import Layout from "../../components/layout/Layout";

const SubmissionSoon = () => {
  return (
    <Layout>
      <SubmitSubmission />
    </Layout>
  );
};

export default SubmissionSoon;
