import { Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

export default function FeesInclude() {
  const { languages } = useSelector((state) => state.languages);
  const titleStyle = {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: "900",
    fontSize: "1.5rem",
    marginBottom: "1rem",
  };
  const eng_lists = [
    "Admission to all scientific plenary and parallel sessions",
    "Access to exhibition and poster area",
    "Conference bag and name badge",
    "Conference program and abstracts",
    "Lunches, coffee, tea and refreshments during breaks",
    "Business meetings",
    "Conference dinner (for participant only)",
    "E-posters",
    "Certificate",
  ];
  const ind_lists = [
    "Masuk ke semua pleno ilmiah dan sesi paralel",
    "Akses ke area pameran dan poster",
    "Tas konferensi dan lencana nama",
    "Program konferensi dan abstrak",
    "Makan siang, kopi, teh, dan minuman saat istirahat",
    "Pertemuan bisnis",
    "Makan malam konferensi (untuk peserta saja)",
    "E-poster",
    "Sertifikat",
  ];
  function createList(list) {
    return (
      <li
        style={{
          paddingLeft: "2rem",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            fontSize: "18px",
            lineHeight: "35px",
            mt: 3,
            fontFamily: "Plus Jakarta Sans, sans-serif",
            fontWeight: "500",
          }}
        >
          {list}
        </Typography>
      </li>
    );
  }
  return (
    <div
      style={{
        marginBottom: "4rem",
        padding: "0",
        marginLeft: "0",
        marginRight: "0",
      }}
    >
      <Typography variant="h1" style={titleStyle}>
        {languages === "ind"
          ? "Biaya pendaftaran sudah termasuk:"
          : "The registration fees include:"}
      </Typography>
      <ul
        style={{
          lineHeight: "1.3",
          paddingLeft: "2rem",
          fontFamily: "Plus Jakarta Sans",
        }}
      >
        {languages === "ind"
          ? ind_lists.map(createList)
          : eng_lists.map(createList)}
      </ul>
    </div>
  );
}
