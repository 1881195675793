import React, { useEffect, useState } from "react";
import Layout from "../../components/layout/Layout";
import ComingSoon from "../../components/soon/ComingSoon";
import CardContent from "@mui/material/CardContent";
import { Container } from "react-bootstrap";
import Logo from "../../assets/images/logo_wwc_2023.jpg";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
const InformationSoon = () => {
  const [isHome, setsIsHome] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const { languages } = useSelector((states) => states.languages);
  const container = {
    marginTop: "2rem",
    width: "100%",
  };

  const text = {
    margin: "2rem",
    fontFamily: "Montserrat",
    textAlign: "justify",
  };
  const email = (
    <a href="mailto:sc.wwcindonesia2023@gmail.com">
      sc.wwcindonesia2023@gmail.com
    </a>
  );

  return (
    <Layout>
      <Container style={container}>
        <CardContent>
          <img
            src={Logo}
            style={{
              borderRadius: "15px",
              width: "40%",
              display: "block",
              margin: "auto",
            }}
            alt="Logo WWC 2023"
          />
          <Typography style={text}>
            <table>
              <tr>
                <td>{languages === "ind" ? "Tanggal" : "Date"}</td>
                <td>:</td>
                <td></td>
              </tr>
              <tr>
                <td>Re</td>
                <td>:</td>
                <td>
                  {languages === "ind" ? "Surat Undangan" : "Invitation Letter"}
                </td>
              </tr>
            </table>
          </Typography>
          <Typography style={text}>
            {languages === "ind" ? "Kepada Tuan/Nyonya" : "Dear Mr/Mrs..."}
          </Typography>
          <Typography style={text}>
            {languages === "ind"
              ? "Panitia penyelenggara Konferensi Unggas Air Dunia ke-7 dengan senang hati mengundang Anda untuk menghadiri konferensi yang akan diselenggarakan pada 20-22 September 2023, di Indonesia Convention Exhibition (ICE) BSD City di Tangerang, Indonesia, sebagai"
              : "The organizing committee of the 7th World Waterfowl Conference has the pleasure to invite you to attend the conference which will be held on 20-22 September 2023, at the Indonesia Convention Exhibition (ICE) BSD City in Tangerang, Indonesia, as"}
          </Typography>
          <Typography
            style={{
              marginLeft: isMobile ? "8rem" : isTablet ? "2rem" : "8rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
            }}
          >
            {languages === "ind"
              ? "Pembicara/Pembawa Acara/Peserta"
              : "A speaker/presenter/participant"}
          </Typography>
          <Typography style={text}>
            {languages === "ind"
              ? "Dengan senang hati kami akan menyambut Anda bersama dengan peserta lain dari berbagai negara untuk mendiskusikan isu-isu relevan yang berkaitan dengan pengembangan ilmu pengetahuan dan bisnis di industri unggas air, dan menikmati keramahan kami."
              : "It will be our pleasure to welcome you along with other participants from many different countries to discuss relevant issues pertaining to the development of science and business in waterfowl industry, and enjoy our hospitality."}
          </Typography>
          <Typography style={text}>
            {languages === "ind"
              ? "Jika Anda memerlukan informasi lebih lanjut, jangan ragu untuk menghubungi kami atau kunjungi website kami"
              : "If you need further information, please do not hesitate to contact us or visit our website"}{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="https://wwcindonesia2023.id/"
            >
              https://wwcindonesia2023.id/
            </a>
          </Typography>
          <Typography
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              fontFamily: "Montserrat",
              fontWeight: "600",
            }}
          >
            {languages === "ind"
              ? "Konferensi Unggas Air Dunia ke-7 2023"
              : "7th World Waterfowl Conference 2023"}
          </Typography>
          <Typography
            style={{
              marginLeft: "2rem",
              marginRight: "2rem",
              fontFamily: "Montserrat",
            }}
          >
            Kompleks Taman Laguna Blok I No.32. Jl. Alternatif Cibubur -
            Cileungsi, Jatisampurna Bekasi, Jawa Barat, Indonesia – 17433
          </Typography>
          <Typography style={text}>
            <table>
              <tr>
                <td>Tel.</td>
                <td>:</td>
                <td>(021) 8430 1025</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>
                  {email}| Web:
                  <a
                    style={{ textDecoration: "underline" }}
                    href="https://wwcindonesia2023.id/"
                  >
                    {" "}
                    https://wwcindonesia2023.id/
                  </a>
                </td>
              </tr>
            </table>
          </Typography>
        </CardContent>
      </Container>

      {/* <ComingSoon>
                Our General Information Menu is Coming Soon. Stay Tune!
            </ComingSoon> */}
    </Layout>
  );
};

export default InformationSoon;
