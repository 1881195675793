import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchBeautifulIndonesia = createAsyncThunk(
  "beautifulIndonesia/fetchBeautifulIndonesia",
  async () => {
    const res = await directus.items("beautiful_indonesia").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchBeautifulIndonesia;

export function getAssetURL(id) {
  return url + `/assets/${id}`;
}
