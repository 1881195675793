export const isIndonesia = (languages) => {
  return languages === "ind";
};

export const isEnglish = (languages) => {
  return languages === "eng";
};

export const translation = (jsonFile, languages, key) => {
  if (isIndonesia(languages)) {
    return jsonFile.ind[key];
  } else if (isEnglish(languages)) {
    return jsonFile.eng[key];
  }
};

// all json file is start here
export const home_page = require("../translations/home_page.json");
export const navbar_component = require("../translations/navbar_component.json");
export const organization_page = require("../translations/organization_page.json");
export const program_page = require("../translations/program_page.json");
export const submission_page = require("../translations/submission_page.json");
export const sponsorship_page = require("../translations/sponsorship_page.json");
export const information_page = require("../translations/information_page.json");
export const contact_page = require("../translations/contact_page.json");
export const registration_form = require("../translations/registration_form.json");
