import React from "react";
import Layout from "../../components/layout/Layout";
import ComingSoon from "../../components/soon/ComingSoon";

const ComingSoonCulinary = () => {
  return (
    <Layout>
      <ComingSoon>
        Our Culinary Bazaar Program is Coming Soon. Stay Tune!
      </ComingSoon>
    </Layout>
  );
};

export default ComingSoonCulinary;
