import Layout from "../../components/layout/Layout";
import Consideration from "../../components/content/Consideration";
import Countdown from "../../components/content/Countdown";
import Summary from "../../components/content/Summary";
import Banner from "../../components/content/Banner";
import Card from "../../components/content/Card";
import { useState, useEffect } from "react";
import ModalAnnouncement from "../../components/form/ModalAnnouncement";

const Home = () => {
  const [modalDisplayed, setModalDisplayed] = useState(false);

  const handleClick = () => {
    setModalDisplayed(false);
  };

  useEffect(() => {
    const isModalDisplayed = false;

    if (!isModalDisplayed) {
      localStorage.setItem("modalDisplayed", true);
      setModalDisplayed(true);
    }
  }, []);

  return (
    <Layout>
      <Consideration />
      <Countdown />
      <Summary />
      <Banner />
      <Card />
      {modalDisplayed ? (
        <ModalAnnouncement show={modalDisplayed} onClick={handleClick} />
      ) : (
        console.log("gagal")
      )}
    </Layout>
  );
};

export default Home;
