import { createSlice } from "@reduxjs/toolkit";
import fetchIndonesianCurrency from "../../reducers/information/indonesianCurrency_reducer";

const indonesianCurrencySlice = createSlice({
  name: "indonesianCurrency",
  initialState: {
    indonesianCurrency: [],
    isLoading: false,
    isSuccess: false,
    isError: null,
  },
  extraReducers(builder) {
    builder.addCase(fetchIndonesianCurrency.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchIndonesianCurrency.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.indonesianCurrency = action.payload;
    });
    builder.addCase(fetchIndonesianCurrency.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = action.error.message;
    });
  },
});

export default indonesianCurrencySlice.reducer;
