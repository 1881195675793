import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { getMember } from "../../reducers/member_reducer";
import { useDispatch, useSelector } from "react-redux";

const OrganizationMember = () => {
  const { committee } = useSelector((state) => state.committee);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMember());
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{ color: "#FDA81A", fontWeight: "bold", fontFamily: "Montserrat" }}
      >
        {languages === "ind" ? "Anggota WWC 2023" : "The Member of WWC 2023"}
      </Typography>

      {committee.map((committee, index) => (
        <div key={index}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", fontSize: "25px", mt: 6, mb: 2 }}
          >
            {languages === "ind" ? committee.nama : committee.name}
          </Typography>

          {committee.member.map((member, i, element) => {
            const roleName = member.org_member_id.jabatan[0].org_role_id.name;
            const roleNama = member.org_member_id.jabatan[0].org_role_id.nama;
            const firstRoleIndexEng = committee.member.findIndex(
              (member) =>
                member.org_member_id.jabatan[0].org_role_id.name === roleName
            );
            const firstRoleIndexInd = committee.member.findIndex(
              (member) =>
                member.org_member_id.jabatan[0].org_role_id.nama === roleNama
            );
            const nomor = 1 + i - firstRoleIndexEng;
            return (
              <div key={i}>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "18px", fontFamily: "Plus Jakarta Sans" }}
                >
                  {languages === "ind"
                    ? firstRoleIndexInd == i &&
                      member.org_member_id.jabatan[0].org_role_id.nama
                    : firstRoleIndexEng == i &&
                      member.org_member_id.jabatan[0].org_role_id.name}
                </Typography>

                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "Plus Jakarta Sans",
                    mt: 0.5,
                    mb: 2.5,
                  }}
                >
                  {/* {i > 0 ? i + ". " : ""} */}
                  {member.org_member_id.jabatan[0].org_role_id.name ===
                    "Members" ||
                  member.org_member_id.jabatan[0].org_role_id.nama ===
                    "Anggota" ||
                  member.org_member_id.jabatan[0].org_role_id.nama ===
                    "Bagian Rapat" ||
                  member.org_member_id.jabatan[0].org_role_id.name ===
                    "Meeting Section"
                    ? nomor + ". " + member.org_member_id.nama_lengkap
                    : member.org_member_id.nama_lengkap}
                  {/* {member.org_member_id.nama_lengkap} */}
                </Typography>
              </div>
            );
          })}
        </div>
      ))}
    </>
  );
};

export default OrganizationMember;
