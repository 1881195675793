import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Container from "react-bootstrap/Container";
import { Box } from "@mui/material";
import { url } from "../../configs/public_url";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import { useLocation } from "react-router-dom";
import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "../../Hover.css";
import ModalReset from "./ModalReset";
import ModalCancel from "./ModalCancel";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfirmPassword, setErrorConfirmPassword] = useState("");
  const [submissionRegisterId, setSubmissionRegisterId] = useState("");
  const [takenEmail, setTakeEmail] = useState("");
  const [Id, setId] = useState("");
  const [passwordEye, setPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalCancel, setShowModalCancel] = useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const container = {
    // border:"1px solid",
    margin: "4rem",
  };
  const styleReset = {
    marginTop: "3rem",
    textAlign: "center",
    fontWeight: "700",
    // border:"1px solid"
  };

  const box = {
    margin: "2rem",
    backgroundColor: "white",
    borderRadius: "5px",
    width: "50%",
    height: "50vh",
    // border:"1px solid",
  };

  const formControl = {
    display: "grid",
    marginTop: "1rem",
    gridTemplateRows: "1fr 1fr",
  };

  const passwordStyle = {
    margin: "0.5rem",
    alignItems: "center",
  };

  const input = {
    fontSize: "12px",
    width: "100%",
  };

  const button = {
    marginTop: "2rem",
    color: "#1f1f1f",
    borderRadius: "0.5rem",
    backgroundColor: "#FDA81A",
    border: "none",
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "3.5rem",
    fontSize: "1.26rem",
    fontFamily: "Plus Jakarta Sans",
  };

  const icon = {
    position: "absolute",
  };

  const errorStyle = {
    color: "red",
    fontSize: "12px",
  };

  const handleTypePassword = () => {
    setPasswordEye(!passwordEye);
  };

  const handleTypeConfirmPassword = () => {
    setConfirmPasswordEye(!confirmPasswordEye);
  };

  const handleAlertToken = () => {
    swal({
      title: "Sorry!",
      icon: "info",
      text: "Your link has been expired!",
      cancelButtonColor: "#999999",
      reverseButtons: true,
      focusConfirm: false,
      focusCancel: true,
    });
  };

  const handleChange = (event, set, error, message) => {
    let value;
    if (event) {
      value = event.target ? event.target.value : event.value;
    } else {
      value = "";
    }

    if (!value) {
      error(`Please enter your ${message}`);
    } else {
      error("");
    }
    set(value);
  };

  const handleError = (data) => {
    data.password
      ? setErrorPassword("")
      : setErrorPassword("Please enter your password");
    if (data.confirm_password !== data.password) {
      setErrorConfirmPassword("Password doesn't match");
    } else if (data.confirm_password === "") {
      setErrorConfirmPassword("Please enter your confirm password");
    }
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("reset_password_token");

    const fetchForgotPassword = async () => {
      try {
        const response = await fetch(
          `${url}/items/forgot_password?filter[reset_password_token]=${token}&filter[isUsed]=0`,
        );

        const data = await response.json();
        setData(data);

        if (data.data.length > 0) {
          const takeEmailForgotPassword = data.data[0].email;
          const takeId = data.data[0].id;
          setTakeEmail(takeEmailForgotPassword);
          setId(takeId);

          const fetchSubmissionRegister = async () => {
            try {
              const response = await fetch(
                `${url}/items/submission_register?filter[email]=${takeEmailForgotPassword}`,
              );
              const data = await response.json();

              if (data.data.length > 0) {
                const submissionRegisterId = data.data[0].id;
                setSubmissionRegisterId(submissionRegisterId);
              }
            } catch (error) {
              console.log(
                "Error saat mencari email dalam collection submission_register:",
                error,
              );
            }
          };
          fetchSubmissionRegister();
        } else {
          await handleAlertToken();
          return navigate("/submission");
        }
      } catch (error) {
        console.error(
          "Error saat mencari token dalam collection directus forgot password:",
          error,
        );
      }
    };
    fetchForgotPassword();
  }, []);

  const resetPasswordChange = async (e) => {
    e.preventDefault();
    let payload = {
      password: password,
      confirm_password: confirmPassword,
    };

    const body = {
      isUsed: 1,
    };

    handleError(payload);
    for (var keys in payload) {
      if (payload[keys] === "") {
        return;
      }
    }

    try {
      const updateResponse = await fetch(
        `${url}/items/submission_register/${submissionRegisterId}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      if (updateResponse.ok) {
        setShowModal(true);
      } else {
        setShowModalCancel(true);
      }
    } catch (error) {
      console.log(
        "Error saat mengupdate password dalam collection submission_register:",
        error,
      );
    }

    const changeIsUsedToTrue = async () => {
      await fetch(`${url}/items/forgot_password/${Id}`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
    };
    changeIsUsedToTrue();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (data) {
    return (
      <Container
        style={container}
        className="row justify-content-center align-items-center">
        {" "}
        <Typography style={styleReset} variant="h5">
          Reset Your Password
        </Typography>
        <Box style={box}>
          <div>
            <Typography
              style={{
                marginTop: "2rem",
                marginLeft: "0.5rem",
              }}>
              Forgot your password?Reset here!
            </Typography>
          </div>
          <form style={formControl} onSubmit={resetPasswordChange}>
            <div style={passwordStyle}>
              <InputLabel htmlFor="password">New Password</InputLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}>
                <Input
                  style={input}
                  id="password"
                  aria-describedby="my-helper-text"
                  placeholder="Type your password"
                  type={passwordEye === false ? "password" : "text"}
                  value={password || ""}
                  name="password"
                  onChange={(e) =>
                    handleChange(e, setPassword, setErrorPassword, "password")
                  }
                />
                <IconButton onClick={handleTypePassword} style={icon}>
                  {passwordEye === false ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
              <div>
                <span style={errorStyle}>{errorPassword}</span>
              </div>
            </div>
            <div style={passwordStyle}>
              <InputLabel htmlFor="confirmPassword">
                Confirm Password
              </InputLabel>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}>
                <Input
                  style={input}
                  id="confirmPassword"
                  aria-describedby="my-helper-text"
                  placeholder="Type your confirm password"
                  type={confirmPasswordEye === false ? "password" : "text"}
                  value={confirmPassword || ""}
                  name="confirmPassword"
                  onChange={(e) =>
                    handleChange(
                      e,
                      setConfirmPassword,
                      setErrorConfirmPassword,
                      "confirm password",
                    )
                  }
                />
                <IconButton style={icon} onClick={handleTypeConfirmPassword}>
                  {confirmPasswordEye === false ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </div>
              <div>
                <span style={errorStyle}>{errorConfirmPassword}</span>
              </div>
            </div>

            <button className="send-password" type="submit" style={button}>
              Send Password
            </button>
          </form>
        </Box>
        <ModalReset
          show={showModal}
          onHide={() => {
            setShowModal(false);
            navigate("/submission/register-submission");
          }}
        />
        <ModalCancel
          show={modalCancel}
          onYes={() => {
            setShowModalCancel(false);
            navigate("/submission");
          }}
          onNo={() => setShowModalCancel(false)}
        />
      </Container>
    );
  } else {
    return <div></div>;
  }
};

export default ResetPassword;
