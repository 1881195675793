import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useDispatch, useSelector } from "react-redux";
import { registration_form, translation } from "../../helpers/translation";
import {
  fetchParticipatingIn,
  fetchTotalPaymentConference,
  fetchTotalPaymentTour,
} from "../../slices/formInputs_slicer";
import FieldsetTypeRegistration from "./FieldsetTypeRegistration";
import TextInputs2 from "./TextInputs2";
import TextInputs3 from "./TextInputs3";

const FieldsetPartisipation = () => {
  const [isScientific, setIsScientific] = React.useState(false);
  const [isWithPaper, setIsWithPaper] = React.useState(false);
  const [isWithSponsorship, setIsWithSponsorship] = React.useState(false);
  const [isBusiness, setIsBusiness] = React.useState(false);
  const [isTour, setIsTour] = React.useState(false);
  const [isTechnicalTour, setIsTechnicalTour] = React.useState(true);
  const [inputs, setInputs] = React.useState({
    scientific_forum: "",
    business_forum: "",
    tour_field_trip_type: "",
    tour_field_trip_persons: 0,
  });
  const { languages } = useSelector((state) => state.languages);

  const dispatch = useDispatch();

  const handleParticipatingIn = (e) => {
    if (e.target.checked) {
      setIsScientific(true);
    } else {
      setIsScientific(false);
      setInputs({
        ...inputs,
        scientific_forum: "",
      });
    }
  };

  const handleScientificForum = (e) => {
    if (e.target.value === "With Paper") {
      setIsWithPaper(true);
    } else if (e.target.value === "Without Paper") {
      setIsWithPaper(false);
    }
    setInputs({
      ...inputs,
      scientific_forum: e.target.value,
    });
  };

  const handleBusinessForum = (e) => {
    if (e.target.value === "With Sponsorship") {
      setIsWithSponsorship(true);
    } else if (e.target.value === "Without Sponsorship") {
      setIsWithSponsorship(false);
    }
    setInputs({
      ...inputs,
      business_forum: e.target.value,
    });
  };

  const handleTour = async (e) => {
    if (e.target.value === "Technical Tour") {
      setIsTechnicalTour(true);
      setInputs({
        ...inputs,
        tour_field_trip_type: "Technical Tour",
      });
    } else {
      setIsTechnicalTour(false);
      setInputs({
        ...inputs,
        tour_field_trip_type: "Cultural Tour",
      });
    }
  };

  const handleTourPersons = async (e) => {
    if (e.target.value > 2) {
      await alert("Max 2 persons per tour");
      e.target.value = 2;
    }
    if (e.target.value < 0) {
      await alert("Min 1 persons per tour");
      e.target.value = 1;
    }
    setInputs({
      ...inputs,
      tour_field_trip_persons: e.target.value,
    });
  };

  useEffect(() => {
    if (isTour === true) {
      dispatch(fetchTotalPaymentTour(inputs.tour_field_trip_persons * 40));
      setInputs({
        ...inputs,
        tour_field_trip_type: "Technical Tour",
      });
    } else if (isTour === false) {
      dispatch(fetchTotalPaymentTour(0));
      inputs.tour_field_trip_persons = 0;
      setIsTechnicalTour(true);
    }

    if (!isScientific && !isBusiness) {
      dispatch(fetchTotalPaymentConference(0));
    }

    dispatch(fetchParticipatingIn(inputs));
    document.addEventListener("wheel", function (event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });

    return () => {
      document.removeEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
          document.activeElement.blur();
        }
      });
    };
  }, [dispatch, inputs, isTour]);

  useEffect(() => {
    if (isBusiness) {
      setIsWithSponsorship(false);
      setInputs({
        ...inputs,
        business_forum: "Without Sponsorship",
      });
    }
  }, [isBusiness]);

  useEffect(() => {
    if (isScientific) {
      setIsWithPaper(false);
      setInputs({
        ...inputs,
        scientific_forum: "Without Paper",
      });
    }
  }, [isScientific]);

  return (
    <>
      <fieldset
        style={{
          gridColumn: "span 6",
          gridRow: "5",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        <label htmlFor="ParticipatingIn" style={Styles.labelStyle}>
          <span style={Styles.requiredStyle}>*</span>
          {translation(registration_form, languages, "participating_in")}
        </label>

        {/* participating 1 */}
        <div style={Styles.flexCheckbox}>
          <input
            type="checkbox"
            id="scientific_forum"
            value="Scientific Forum"
            style={Styles.checkbox}
            className="orange-checkbox"
            onChange={handleParticipatingIn}
          />
          <span className="label-medium">
            {translation(registration_form, languages, "scientific_forum")}
          </span>
        </div>
        {isScientific && (
          <>
            <div style={Styles.flexCheckbox}>
              <input
                type="radio"
                name="with_or_without_paper"
                value="With Paper"
                onChange={handleScientificForum}
                className="orange-radio"
                style={Styles.level1}
                checked={isWithPaper}
                required
              />
              <label className="label-medium" htmlFor="withPaper">
                {translation(registration_form, languages, "with_paper")}
              </label>
            </div>
          </>
        )}
        {isScientific && isWithPaper ? (
          <>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <input
                type="radio"
                name="oral_or_poster"
                value="Oral"
                style={Styles.level2}
                onChange={handleScientificForum}
                className="orange-radio"
                checked={inputs.scientific_forum === "Oral"}
                required
              />
              <label className="label-medium" htmlFor="oral">
                {translation(registration_form, languages, "oral")}
              </label>
            </div>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <input
                type="radio"
                name="oral_or_poster"
                value="Poster"
                style={Styles.level2}
                onChange={handleScientificForum}
                className="orange-radio"
                checked={inputs.scientific_forum === "Poster"}
                required
              />
              <label className="label-medium" htmlFor="poster">
                {translation(registration_form, languages, "poster")}
              </label>
            </div>
          </>
        ) : null}
        {isScientific && (
          <>
            <div style={Styles.flexCheckbox}>
              <input
                type="radio"
                name="with_or_without_paper"
                value="Without Paper"
                onChange={handleScientificForum}
                className="orange-radio"
                style={Styles.level1}
                checked={!isWithPaper}
                required
              />
              <label className="label-medium" htmlFor="withPaper">
                {translation(registration_form, languages, "without_paper")}
              </label>
            </div>
          </>
        )}

        {/* participating 2 */}
        <div className="mt-3" style={Styles.flexCheckbox}>
          <input
            type="checkbox"
            id="business_forum"
            name="businessForum"
            value="Business Forum"
            style={Styles.checkbox}
            onChange={() => setIsBusiness(!isBusiness)}
            className="orange-checkbox"
          />
          <span className="label-medium">
            {translation(registration_form, languages, "business_forum")}
          </span>
        </div>
        {isBusiness && (
          <>
            <div style={Styles.flexCheckbox}>
              <input
                type="radio"
                name="business_forum"
                value="With Sponsorship"
                style={Styles.level1}
                onChange={handleBusinessForum}
                className="orange-radio"
                // checked={inputs.business_forum === "With Sponsorship"}
                checked={isWithSponsorship}
                required
              />
              <label className="label-medium" htmlFor="withSponsor">
                {translation(registration_form, languages, "with_sponsor")}
              </label>
            </div>
          </>
        )}
        {isBusiness && (
          <>
            <div style={Styles.flexCheckbox}>
              <input
                type="radio"
                name="business_forum"
                value="Without Sponsorship"
                style={Styles.level1}
                onChange={handleBusinessForum}
                className="orange-radio"
                // checked={inputs.business_forum === "Without Sponsorship"}
                checked={!isWithSponsorship}
                required
              />
              <label className="label-medium" htmlFor="withSponsor">
                {translation(registration_form, languages, "without_sponsor")}
              </label>
            </div>
          </>
        )}

        {/* participating 3 */}
        <div className="mt-3" style={Styles.flexCheckbox}>
          <input
            type="checkbox"
            id="tour_or_field_trip"
            name="tour_field_trip_type"
            value={isTechnicalTour ? "Technical Tour" : "Cultural Tour"}
            style={Styles.checkbox}
            onChange={() => setIsTour(!isTour)}
            className="orange-checkbox"
          />
          <span className="label-medium">
            {translation(registration_form, languages, "tour_field")}
          </span>
        </div>
        {isTour && (
          <>
            <div style={Styles.flexWithInputGroup}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "start",
                }}
              >
                <input
                  type="radio"
                  name="tour"
                  value="Technical Tour"
                  style={Styles.level1}
                  onChange={handleTour}
                  className="orange-radio"
                  id="technical_tour"
                  checked={isTechnicalTour}
                />
                <label
                  className="text-nowrap label-medium"
                  htmlFor="withSponsor"
                >
                  {translation(registration_form, languages, "technical_tour")}
                </label>
              </div>
              {isTechnicalTour && (
                <InputGroup style={Styles.inputGroup}>
                  <Form.Control
                    aria-label="Total of Person"
                    placeholder="Total of Person"
                    style={{
                      height: "3rem",
                    }}
                    type="number"
                    name="tour_field_trip_persons"
                    onChange={handleTourPersons}
                    value={inputs.tour_field_trip_persons}
                    min={0}
                    max={2}
                    required
                  />
                  <InputGroup.Text>
                    {translation(registration_form, languages, "person")}
                  </InputGroup.Text>
                </InputGroup>
              )}
            </div>
          </>
        )}
        {isTour && (
          <>
            <div style={Styles.flexWithInputGroup}>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "start",
                }}
              >
                <input
                  type="radio"
                  name="tour"
                  value="Cultural Tour"
                  id="cultural_tour"
                  style={Styles.level1}
                  onChange={handleTour}
                  className="orange-radio"
                />
                <label className="text-nowrap label-medium" htmlFor="withPaper">
                  {translation(registration_form, languages, "cultural_tour")}
                </label>
              </div>
              {!isTechnicalTour && (
                <InputGroup style={Styles.inputGroup}>
                  <Form.Control
                    aria-label="Total of Person"
                    placeholder="Total of Person"
                    style={{
                      height: "3rem",
                    }}
                    type="number"
                    name="tour_field_trip_persons"
                    onChange={handleTourPersons}
                    value={inputs.tour_field_trip_persons}
                    min={0}
                    max={2}
                    required
                  />
                  <InputGroup.Text>
                    {translation(registration_form, languages, "person")}
                  </InputGroup.Text>
                </InputGroup>
              )}
            </div>
          </>
        )}
      </fieldset>
      <fieldset
        style={{
          gridColumn: "span 6",
          gridRow: "6",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "1rem",
        }}
      >
        {parseInt(inputs.tour_field_trip_persons) === 1 ? (
          <>
            <label htmlFor="person1" style={Styles.personStyle}>
              1st Person
            </label>
            <div
              style={{
                marginLeft: "3rem",
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: "1.5rem",
              }}
            >
              <TextInputs2 />
            </div>
          </>
        ) : parseInt(inputs.tour_field_trip_persons) === 2 ? (
          <>
            <label htmlFor="person1" style={Styles.personStyle}>
              1st Person
            </label>
            <div
              style={{
                marginLeft: "3rem",
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: "1.5rem",
                marginBottom: "1rem",
              }}
            >
              <TextInputs2 />
            </div>
            <label htmlFor="person1" style={Styles.personStyle}>
              2nd Person
            </label>
            <div
              style={{
                marginLeft: "3rem",
                display: "grid",
                gridTemplateColumns: "repeat(6, 1fr)",
                gap: "1.5rem",
              }}
            >
              <TextInputs3 />
            </div>
          </>
        ) : null}
      </fieldset>
      {(isBusiness || isScientific) && (
        <fieldset
          style={{
            gridColumn: "span 6",
            gridRow: "7",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <FieldsetTypeRegistration />
        </fieldset>
      )}
    </>
  );
};

export default FieldsetPartisipation;

const Styles = {
  labelStyle: {
    fontWeight: "900",
    fontSize: "0.85rem",
    marginBottom: "0.5rem",
  },
  flexRadio: {
    display: "flex",
    gap: "1rem",
  },
  radio: {
    height: "1.5rem",
    width: "1.5rem",
  },
  flexCheckbox: {
    display: "flex",
    gap: "1rem",
  },
  flexWithInputGroup: {
    display: "flex",
    gap: "1rem",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "60%",
  },
  checkbox: {
    height: "1.5rem",
    width: "1.5rem",
    color: "white",
  },
  level1: {
    height: "1.5rem",
    width: "1.5rem",
    marginLeft: "3rem",
  },
  level2: {
    height: "1.5rem",
    width: "1.5rem",
    marginLeft: "5rem",
  },
  requiredStyle: {
    color: "red",
  },
  inputGroup: {
    display: "flex",
    maxWidth: "15rem",
  },
  personStyle: {
    fontWeight: "700",
    fontSize: "18px",
    marginBottom: "0.5rem",
    marginTop: "1rem",
    marginLeft: "3rem",
    color: "#0ea34a",
    fontFamily: "Plus Jakarta Sans",
  },
};
