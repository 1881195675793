import React from 'react'
import Layout from '../../components/layout/Layout'
import TabComponent from '../../components/tab/TabComponent'
import BeautifulIndonesia from './BeautifulIndonesia'
import IndonesianCurrency from './IndonesianCurrency'
import Transport from './Transport'
import Visa from './Visa'
import WeatherInformation from './WeatherInformation'
import VenueLocation from './VenueLocation'
import Accomodation from './Accomodation'
import { useSelector } from 'react-redux'
import { translation, information_page } from '../../helpers/translation'

const Information = () => {
  const { languages } = useSelector((state) => state.languages)
  return (
    <Layout>
        <TabComponent 
            labels={[
              translation(information_page, languages, "venue_location"),
              translation(information_page, languages, "accomodation"),
              translation(information_page, languages, "transport"),
              translation(information_page, languages, "beautiful_indonesia"),
              translation(information_page, languages, "visa"),
              translation(information_page, languages, "weather_information"),
              translation(information_page, languages, "indonesia_currency")
            ]}
            tabs={[<VenueLocation />, <Accomodation />, <Transport />, <BeautifulIndonesia />, <Visa />, <WeatherInformation />, <IndonesianCurrency />]}
        />
    </Layout>
  )
}

export default Information