import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchAccomodationList = createAsyncThunk(
  "accomodationList/fetchAccomodationList",
  async () => {
    const res = await directus.items("accomodation_list").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchAccomodationList;

export function getAssetURL(id) {
  return `${url}/assets/${id}`;
}
