import { Button, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchVisa,
  getAssetURL,
} from "../../reducers/information/visa_reducer";
import parse from "html-react-parser";

const Visa = () => {
  const { visa } = useSelector((state) => state.visa);
  const { languages } = useSelector((state) => state.languages);
  const dispatch = useDispatch();
  const isHide = true;

  useEffect(() => {
    dispatch(fetchVisa());
  }, []);

  const style = {
    button: {
      backgroundColor: "#FDA81A",
      borderRadius: "8px",
      color: "black",
      fontFamily: "Plus Jakarta Sans, sans-serif",
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "25px",
      textTransform: "none",
      px: "28px",
      py: "16px",
      mt: "30px",
      "&:hover": {
        backgroundColor: "#FDA81A",
        boxShadow: "0 5px 15px #FDA81A",
      },
    },
  };

  const replaceEmailWithLink = (node) => {
    if (node.type === "text" && node.data && node.data.includes("@")) {
      return (
        <a
          href={`mailto:${node.data}`}
          target="_blank"
          rel="noopener noreferrer">
          {node.data}
        </a>
      );
    }

    return node;
  };

  return (
    <>
      {/* {isHide ? (
        <ComingSoon>Our Visa Menu is Coming Soon. Stay Tune!</ComingSoon>
      ) : ( */}
      <>
        <Typography
          variant="h4"
          sx={{
            color: "#FDA81A",
            fontWeight: "bold",
            fontFamily: "Montserrat",
          }}>
          {languages === "ind" ? visa.judul_indonesia : visa.judul_english}
        </Typography>
        {visa?.gambar?.id && (
          <img
            src={getAssetURL(visa?.gambar?.id)}
            alt="visa"
            style={{ borderRadius: "20px", margin: "2rem auto" }}
          />
        )}
        <Typography
          variant="body1"
          sx={{
            textAlign: "justify",
            fontSize: "18px",
            lineHeight: "44px",
            mt: 3,
            fontFamily: "Plus Jakarta Sans, sans-serif",
          }}>
          {parse(
            "<p>" +
              (languages === "ind"
                ? visa.isi_deskripsi_indonesia
                : visa.isi_deskripsi_english) +
              "</p>",
            {
              replace: replaceEmailWithLink,
            },
          )}
        </Typography>
        <div style={{ textAlign: "right" }}>
          <Button
            href="https://docs.google.com/document/d/1OPWvFQfq4nb9Se_RdFEJRw8-k_Vaz1mJ/export?format=doc"
            variant="contained"
            sx={style.button}>
            {languages === "ind"
              ? "Dapatkan Surat Undangan"
              : "Get Invitation Letter"}
          </Button>
        </div>
      </>
      {/* )} */}
    </>
  );
};

export default Visa;
