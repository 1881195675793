import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import FabComponent from "../footer/FabComponent";
import Footer from "../footer/FooterComponent";
import Sponsorship from "../footer/SponsorshipComponent";
import Header from "../header/HeaderComponent";
import Navbar from "../header/NavbarComponent";

const Layout = ({ children }) => {
  const { isListSponsorship } = useSelector((state) => state.sponsorship);

  return (
    <>
      <div width="100%" className="row align-items-center">
        <Header />
        <Navbar />
        <Container>
          {children}
          <FabComponent />
          {/* {window.location.href.includes("dev") ||
          window.location.href.includes("localhost") ? (
            isListSponsorship === false ? (
              <Sponsorship />
            ) : null
          ) : null} */}
          <Sponsorship />
        </Container>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
