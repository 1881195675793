import { createSlice } from '@reduxjs/toolkit';

const setWithExpiry = (key, value, ttl) => {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    if (itemStr === "ind" || itemStr === "eng") {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

const lang = getWithExpiry('lang') || 'eng';


const languagesSlice = createSlice({
    name: 'languages',
    initialState: {
        languages: lang,
    },
    reducers: {
        setLanguage: (state, action) => {
            state.languages = action.payload;
            setWithExpiry('lang', action.payload, 1000 * 60 * 60 * 24);
        }
    }
});

export const { setLanguage } = languagesSlice.actions;

export default languagesSlice.reducer;