import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Grid from "@mui/material/Unstable_Grid2";
import TabPanel from "./TabPanel";
import TabName from "./TabName";

const TabComponent = (props) => {
  const [isMobile, setIsMobile] = useState(false);
  const { tabs, labels } = props;

  const Style = {
    tabPanel: {
      marginTop: isMobile ? "50px" : "0px",
      marginBottom: isMobile ? "50px" : "0px",
    },
    grid: {
      margin: isMobile ? "40px" : "96px",
    }
  }

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }

  useEffect(() => {
    handleResize();
    document.addEventListener("resize", () => {
      handleResize();
    });

    return () => {
      document.removeEventListener("resize", () => {
        handleResize();
      });
    }
  }, []);

  return (
    <Grid container spacing={0} sx={{ m: 12 }} style={Style.grid}>
      <Grid xs={12} md={3}>
        <Tabs
          orientation="vertical"
          aria-label="Vertical tabs example"
          sx={{
            borderRadius: "20px",
            backgroundColor: "#f2f2f2",
            p: 2,
          }}>
          {/* labels = ['label 1', 'label 2'] */}
          {labels.map((label, i) => (
            <TabName label={label} key={i} index={i} />
          ))}
        </Tabs>
      </Grid>
      <Grid xs={12} md={9} style={Style.tabPanel}>
        {/* Menambahkan Content */}
        {tabs.map((tab, i) => (
          <TabPanel key={i} index={i}>
            {tab}
          </TabPanel>
        ))}
      </Grid>
    </Grid >
  );
};

TabComponent.propTypes = {
  tabs: PropTypes.any,
  index: PropTypes.any,
  labels: PropTypes.array.isRequired,
};

export default TabComponent;
