import { getOrganization } from '../reducers/organization_reducer';
import { createSlice } from '@reduxjs/toolkit';

const OrganizationSlicer = createSlice({
    name: 'organization',
    initialState: {
        organization: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(getOrganization.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getOrganization.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.organization = action.payload;
        })
        builder.addCase(getOrganization.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        })
    }
})

export  default OrganizationSlicer.reducer;