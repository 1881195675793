import React from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";

export default function ListOfTopics() {
  const { languages } = useSelector((state) => state.languages);
  const listOfTopics = {
    color: "#FDA81A",
    fontWeight: "800",
    fontSize: "2rem",
    marginBottom: "2rem",
    fontFamily: "Montserrat",
  };
  const titleStyle = {
    fontWeight: "600",
    fontSize: "1.5rem",
    marginBottom: "1rem",
    fontFamily: "Montserrat",
  };
  const contentStyle = {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "2",
    marginBottom: "2rem",
    fontFamily: "Plus Jakarta Sans",
    alignItems: "justify",
  };
  const listStyle = {
    fontWeight: "500",
    fontSize: "18px",
    marginBottom: "1.5rem",
    fontFamily: "Plus Jakarta Sans",
    alignItems: "justify",
  };
  const lists_eng = [
    "Breeding for efficient production",
    "Nutrition aspects and nutrient requirements",
    "Animal health for efficiency and product safety",
    "Post harvest and product processing technologies",
    "Socio-economics and marketing",
  ];
  const lists_ind = [
    "Pembiakan untuk produksi yang lebih efisien",
    "Aspek gizi dan kebutuhan gizi",
    "Kesehatan hewan untuk efisiensi dan keamanan produk",
    "Pasca panen dan teknologi pengolahan product",
    "Sosial-ekonomi dan pemasaran",
  ];
  function createList(list) {
    return (
      <div className="list-of-topics" style={listStyle}>
        <li style={{ padding: "1rem 0" }}>{list}</li>
        <hr />
      </div>
    );
  }
  return (
    <Container>
      <h1 style={listOfTopics}>
        {languages === "ind" ? "Daftar Topik" : "List of Topics"}
      </h1>
      <h1 style={titleStyle}>
        {languages === "ind"
          ? "Sesi berorientasi objek"
          : "Object-oriented Session"}
      </h1>
      <p style={contentStyle}>
        {languages === "ind"
          ? `Sebuah "sesi berorientasi objek" bermaksud untuk menargetkan tujuan terapan untuk rantai produksi unggas melalui kombinasi pendekatan disipliner yang saling melengkapi. Pekerjaan Anda harus memperhatikan objek, tetapi tidak perlu multidisiplin.`
          : `An “object-oriented session” intends to target an applied objective for
        the poultry production chain through the combination of complementary
        disciplinary approaches. Your work has to concern the object, but does
        not need to be multidisciplinary.`}
      </p>
      <ol>
        {languages === "ind"
          ? lists_ind.map(createList)
          : lists_eng.map(createList)}
      </ol>
    </Container>
  );
}
