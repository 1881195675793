import { createAsyncThunk } from "@reduxjs/toolkit";
import { directus, url } from "../../configs/public_url";

export const fetchWeatherInformation = createAsyncThunk(
  "weather_information/fetchWeatherInformation",
  async () => {
    const res = await directus.items("weather_information").readByQuery({
      fields: ["*.*"],
    });
    return res.data;
  }
);

export default fetchWeatherInformation;

export function getAssetURL(id) {
  return `${url}/assets/${id}`;
}
