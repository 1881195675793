import React from "react";
import Layout from "../../components/layout/Layout";
import TabComponent from "../../components/tab/TabComponent";
import OrganizationWPSA from "../../pages/organization/OrganizationWPSA";
import OrganizationMember from "../../pages/organization/OrganizationMember";
import { translation, organization_page } from "../../helpers/translation";
import { useSelector } from "react-redux";

const Organization = () => {
  const { languages } = useSelector((state) => state.languages);
  return (
    <Layout>
      <TabComponent
        labels={[
          translation(organization_page, languages, "wpsa_ind"),
          translation(organization_page, languages, "member_wwc"),
        ]}
        tabs={[<OrganizationWPSA />, <OrganizationMember />]}
      />
    </Layout>
  );
};

export default Organization;
