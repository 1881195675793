import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import announcement from "../../assets/images/announcement.png";
import Image from "react-bootstrap/Image";
import { Handshake } from "@mui/icons-material";

const ModalAnnouncement = ({ show, className, onClick }) => {
  const { languages } = useSelector((state) => state.languages);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const styles = {
    modalParent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: "0",
      zIndex: "9999",
      width: "100%",
      height: "100%",
      padding: "0 20px",
      backgroundColor: "rgba(0, 0, 0, 0.6)",
    },

    link: {
      color: "blue",
      textDecoration: "underline",
      "&:hover": { color: "orange" },
    },

    image: {
      maxWidth: "100%",
      display: "block",
      borderRadius: "10px",
      position: "relative",
    },

    buttonDiv: {
      position: "relative",
      top: isMobile ? "5%" : "20%",
      left: "50%",
      transform: "translate(-20%, -120%)",
      textAlign: "center",
    },
    closeDiv: {
      border: "1px solid",
      alignItems: "center",
    },
    closeButton: {
      fontSize: isMobile ? "20px" : "35px",
      color: "#fff",
      position: "absolute",
      top: isMobile ? "15px" : "30px",
      cursor: "pointer",
      zIndex: "1",
      right: isMobile ? "30px" : "70px",
      fontWeight: "200",
    },
    imageDiv: {
      position: "relative",
    },
  };

  const handleResize = () => {
    if (window.innerWidth < 650) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!show) return null;

  return (
    <div show={show} className={className} style={styles.modalParent}>
      <div style={{ marginLeft: isMobile ? "-0.2rem" : "0" }}>
        <div style={styles.imageDiv}>
          <Image src={announcement} style={styles.image} />
          <span style={styles.closeButton} onClick={onClick}>
            x
          </span>
        </div>

        {/* <div style={styles.buttonDiv}>
          <Button
            className="orange-button"
            style={{
              backgroundColor: "#FDA81A",
              borderColor: "#FDA81A",
              margin: isMobile ? "0.3rem 0" : "1rem 0",
              fontWeight: "bold",
              justifyContent: "right",
              alignItems: "right",
              color: "#fff",
              fontSize: isMobile ? "0.5rem" : "1.3rem",
              padding: "0.4rem 0",
              width: "30%",
              fontFamily: "Plus Jakarta Sans",
            }}>
            <a href="/announcement">Read More</a>
          </Button>
        </div> */}
      </div>
    </div>
  );
};
export default ModalAnnouncement;
