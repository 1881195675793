import React from "react";

const TextInput = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  style,
  errorMessage,
}) => {
  const labelStyle = {
    fontWeight: "bold",
    fontSize: "0.85rem",
    marginBottom: "0.5rem",
  };

  const requiredStyle = {
    color: "red",
  };

  const inputStyle = {
    width: "100%",
    height: type === "textArea" ? "5.5rem" : "3rem",
    alignItems: "start",
    padding: "1rem",
    paddingTop: type === "textArea" ? "0rem" : "1rem",
    alignItem: "start",
    border: "1px solid gray",
    borderRadius: "0.5rem",
    margin: "0.5rem 0",
    outline: "none",
    fontSize: "0.85rem",
  };

  const errorStyle = {
    fontSize: "0.60rem",
    color: "red",
  };
  return (
    <div style={style}>
      <label htmlFor={name} style={labelStyle}>
        <span style={requiredStyle}>*</span>
        {label}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={inputStyle}
      />
      <span style={errorStyle}>{errorMessage}</span>
    </div>
  );
};

export default TextInput;
