import { createSlice } from "@reduxjs/toolkit";
import { fetchScientificForum } from "../reducers/scientificForum_reducer";

const CulinaryBazaarSlicer = createSlice({
    name: 'scientificForum',
    initialState: {
        scientificForum: [],
        isLoading: false,
        isSuccess: false,
        isError: null,
    },
    extraReducers(builder) {
        builder.addCase(fetchScientificForum.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(fetchScientificForum.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.scientificForum = action.payload;
        })
        builder.addCase(fetchScientificForum.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = action.error.message;
        })
    }
})

export  default CulinaryBazaarSlicer.reducer;