import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/form/TextInputRegis";
import { registration_form, translation } from "../../helpers/translation";
import { fetchInputs } from "../../slices/formInputs_slicer";
const TextInputs2 = () => {
  const { languages } = useSelector((state) => state.languages);
  const [isMobile, setIsMobile] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    first_name: "",
    last_name: "",
    job_title: "",
    institution_affiliation: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    postal_code: "",
    country: "",
  });

  const dispatch = useDispatch();

  const Styles = {
    span2: {
      gridColumn: isMobile ? "span 6" : "span 2",
    },
    span3: {
      gridColumn: isMobile ? "span 6" : "span 3",
    },
    span6and1: {
      gridColumn: "span 6",
      gridRow: "4",
    },
  };

  const handleChanges = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  React.useEffect(() => {
    dispatch(fetchInputs(inputs));
  }, [dispatch, inputs]);
  return (
    <div
      style={{
        gridColumn: "span 6",
        display: "grid",
        gap: "1.2rem",
        gridTemplateColumns: "repeat(6, 1fr)",
      }}
    >
      <TextInput
        label={translation(registration_form, languages, "first_name")}
        type="text"
        name="first_name"
        value={inputs.first_name}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_first_name"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "last_name")}
        type="text"
        name="last_name"
        value={inputs.last_name}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_last_name"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "job_title")}
        type="text"
        name="job_title"
        value={inputs.job_title}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_job"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "institution")}
        type="text"
        name="institution_affiliation"
        value={inputs.institution_affiliation}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_institution"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "email")}
        type="email"
        name="email"
        value={inputs.email}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_email"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "phone_number")}
        type="text"
        name="phone_number"
        value={inputs.phone_number}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_phone"
        )}
        style={Styles.span3}
      />
      <TextInput
        label={translation(registration_form, languages, "address")}
        type="textArea"
        name="address"
        value={inputs.address}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_address"
        )}
        style={Styles.span6and1}
      />
      <TextInput
        label={translation(registration_form, languages, "city")}
        type="text"
        name="city"
        value={inputs.city}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_city"
        )}
        style={Styles.span2}
      />
      <TextInput
        label={translation(registration_form, languages, "postal_code")}
        type="text"
        name="postal_code"
        value={inputs.postal_code}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_postal"
        )}
        style={Styles.span2}
      />
      <TextInput
        label={translation(registration_form, languages, "country")}
        type="text"
        name="country"
        value={inputs.country}
        onChange={handleChanges}
        placeholder={translation(
          registration_form,
          languages,
          "placeholder_country"
        )}
        style={Styles.span2}
      />
    </div>
  );
};

export default TextInputs2;
